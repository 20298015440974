import {gql, useQuery} from "@apollo/client";
import {bookingBySeatAndDate} from "../graphql/queries";
import {
    onCreateBookingsWithoutDate,
    onCreateBookingWithoutDate,
    onDeleteBookingWithoutDate
} from "../graphql/subscriptions";
import {useEffect} from "react";
import {Booking} from "../API"

export function useBookingBySeatId(roomId: string, roomOrgUnitId: string, dateISO: string, seatId: string): Booking[] | [] {
    const date = new Date(dateISO)
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1)
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1)
    const {data, subscribeToMore, fetchMore, client} = useQuery(gql(bookingBySeatAndDate), {
        variables: {
            seatId: seatId,
            date: {between: [firstDayOfMonth, lastDayOfMonth]},
            filter: {
                roomId: {
                    eq: roomId
                },
                orgUnitId: {
                    eq: roomOrgUnitId
                },
            },
            nextToken: null,
        },
        skip: (roomId === "") || (seatId === ""),
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first', // Used for subsequent executions
    });

    function subscribeToMutations() {
        if (roomId === "")
            return;
        const unsubscribeOnCreateBookingWithoutDate = subscribeToMore({
            document: gql(onCreateBookingWithoutDate),
            variables: {
                roomId: roomId,
                orgUnitId: roomOrgUnitId,
                date: dateISO
            },
            updateQuery: (prev, {subscriptionData}) => {
                if (prev.bookingBySeatAndDate) {
                    if (!subscriptionData.data) return prev;
                    const newBooking = subscriptionData.data.onCreateBookingWithoutDate;
                    return (Object.assign({}, prev, {
                            bookingBySeatAndDate: {
                                items: [...prev.bookingBySeatAndDate.items, newBooking],
                                nextToken: null
                            }
                        })
                    )
                }
            },
            onError: (error: any) => console.error("onCreateBookingWithoutDateSubscription: " + JSON.stringify(error))
        });

        const unsubscribeOnCreateBookingsWithoutDate = subscribeToMore({
            document: gql(onCreateBookingsWithoutDate), // Hinweis: der Parameter bei onCreateBookings sollte eigentlich optional sein, er kann aber nicht optional verwendet werden (oder ich weiß nicht wie)
            variables: {
                roomId: roomId,
                orgUnitId: roomOrgUnitId
            },
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data) return prev;
                const newBookings = subscriptionData.data.onCreateBookingsWithoutDate.Items;
                return (Object.assign({}, prev, {
                        bookingBySeatAndDate: {
                            items: [...prev?.bookingBySeatAndDate?.items ?? [], ...newBookings],
                            nextToken: null
                        }
                    })
                )
            },
            onError: (error: any) => console.error("onCreateBookingsWithoutDateSubscription: " + JSON.stringify(error))
        });

        const unsubscribeOnDeleteBookingWithoutDate = subscribeToMore({
            document: gql(onDeleteBookingWithoutDate),
            variables: {
                roomId: roomId,
                orgUnitId: roomOrgUnitId,
                date: dateISO
            },
            updateQuery: (prev, {subscriptionData}) => {
                if (prev.bookingBySeatAndDate) {
                    if (!subscriptionData.data) return prev;
                    const deletedBookingId = subscriptionData.data.onDeleteBookingWithoutDate.bookingId;
                    const deletedBookingDate = subscriptionData.data.onDeleteBookingWithoutDate.date;
                    return Object.assign({}, prev, {
                        bookingBySeatAndDate: {
                            items: prev.bookingBySeatAndDate.items.filter((item: Booking) => (item.bookingId !== deletedBookingId || item.date !== deletedBookingDate)),
                            nextToken: null
                        }
                    })
                }
            },
            onError: (error: any) => console.error("onDeleteBookingWithoutDateSubscription: " + JSON.stringify(error))
        });

        function unsubscribe() {
            unsubscribeOnCreateBookingWithoutDate();
            unsubscribeOnCreateBookingsWithoutDate();
            unsubscribeOnDeleteBookingWithoutDate();
        }

        return () => unsubscribe()
    }

    useEffect(subscribeToMutations, [roomOrgUnitId, roomId, dateISO, seatId, subscribeToMore]);

    function clearCacheIfNoSeatIsSelected() {
        if (!seatId) {
            client.cache.evict({id: 'ROOT_QUERY', fieldName: 'bookingBySeatAndDate'})
        }
    }

    function fetchNextMonthInAdvanceAfterGettingCurrentMonth() {
        if (data) {// the if prevents querying next month before having the current month
            const lastDayOfNextMonth = new Date(lastDayOfMonth.getFullYear(), lastDayOfMonth.getMonth() + 1, 1)
            fetchMore({
                variables: {
                    date: {between: [lastDayOfMonth, lastDayOfNextMonth]}
                }
            }).catch((err) => console.log(err))
        }
    }

    fetchNextMonthInAdvanceAfterGettingCurrentMonth();
    clearCacheIfNoSeatIsSelected();

    return data?.bookingBySeatAndDate?.items.map((b: Booking) => {
        return {...b, __typename: "Booking"}
    }) ?? [];

}