/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPresignedRoomplanUrl = /* GraphQL */ `
  query GetPresignedRoomplanUrl(
    $writeAccess: Boolean!
    $roomId: String!
    $roomplanId: String!
  ) {
    getPresignedRoomplanUrl(
      writeAccess: $writeAccess
      roomId: $roomId
      roomplanId: $roomplanId
    )
  }
`;
export const getRoom = /* GraphQL */ `
  query GetRoom($roomId: ID!) {
    getRoom(roomId: $roomId) {
      roomId
      orgUnitId
      name
      nameLowerCased
      roomPlanExisting
      roomPlanId
      roomPlanScaleFactor
      seats {
        nextToken
      }
      isActive
      isTimeActive
      isBookingDisabled
      isDefault
      isPublic
      createdAt
      updatedAt
    }
  }
`;
export const listRooms = /* GraphQL */ `
  query ListRooms(
    $roomId: ID
    $filter: ModelRoomFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRooms(
      roomId: $roomId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        roomId
        orgUnitId
        name
        nameLowerCased
        roomPlanExisting
        roomPlanId
        roomPlanScaleFactor
        isActive
        isTimeActive
        isBookingDisabled
        isDefault
        isPublic
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBooking = /* GraphQL */ `
  query GetBooking($bookingId: ID!, $date: AWSDate!) {
    getBooking(bookingId: $bookingId, date: $date) {
      bookingId
      date
      seatId
      roomId
      room {
        roomId
        orgUnitId
        name
        nameLowerCased
        roomPlanExisting
        roomPlanId
        roomPlanScaleFactor
        isActive
        isTimeActive
        isBookingDisabled
        isDefault
        isPublic
        createdAt
        updatedAt
      }
      bookerId
      bookerName
      bookerGivenName
      bookerFamilyName
      orgUnitId
      bookingFor
      timeBegin
      timeEnd
      createdAt
      updatedAt
    }
  }
`;
export const listBookings = /* GraphQL */ `
  query ListBookings(
    $bookingId: ID
    $date: ModelStringKeyConditionInput
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBookings(
      bookingId: $bookingId
      date: $date
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        bookingId
        date
        seatId
        roomId
        bookerId
        bookerName
        bookerGivenName
        bookerFamilyName
        orgUnitId
        bookingFor
        timeBegin
        timeEnd
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMeetingRoomBooking = /* GraphQL */ `
  query GetMeetingRoomBooking($bookingId: ID!, $date: AWSDate!) {
    getMeetingRoomBooking(bookingId: $bookingId, date: $date) {
      bookingId
      date
      meetingRoomId
      roomId
      room {
        roomId
        orgUnitId
        name
        nameLowerCased
        roomPlanExisting
        roomPlanId
        roomPlanScaleFactor
        isActive
        isTimeActive
        isBookingDisabled
        isDefault
        isPublic
        createdAt
        updatedAt
      }
      bookerId
      bookerName
      bookerGivenName
      bookerFamilyName
      orgUnitId
      timeBegin
      timeEnd
      meetingName
      roomCapacity
      participantNumber
      meetingType
      createdAt
      updatedAt
    }
  }
`;
export const listMeetingRoomBookings = /* GraphQL */ `
  query ListMeetingRoomBookings(
    $bookingId: ID
    $date: ModelStringKeyConditionInput
    $filter: ModelMeetingRoomBookingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMeetingRoomBookings(
      bookingId: $bookingId
      date: $date
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        bookingId
        date
        meetingRoomId
        roomId
        bookerId
        bookerName
        bookerGivenName
        bookerFamilyName
        orgUnitId
        timeBegin
        timeEnd
        meetingName
        roomCapacity
        participantNumber
        meetingType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInventory = /* GraphQL */ `
  query GetInventory($inventoryId: ID!) {
    getInventory(inventoryId: $inventoryId) {
      inventoryId
      orgUnitId
      type
      name
      nameLowerCased
      createdAt
      updatedAt
    }
  }
`;
export const listInventories = /* GraphQL */ `
  query ListInventories(
    $inventoryId: ID
    $filter: ModelInventoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInventories(
      inventoryId: $inventoryId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        inventoryId
        orgUnitId
        type
        name
        nameLowerCased
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSeatConfig = /* GraphQL */ `
  query GetSeatConfig($roomId: ID!, $seatName: String!) {
    getSeatConfig(roomId: $roomId, seatName: $seatName) {
      seatName
      roomId
      seatType
      inventory
      isSeatHeightAdjustable
      owner
      isOwnerRegistered
      createdAt
      updatedAt
    }
  }
`;
export const listSeatConfigs = /* GraphQL */ `
  query ListSeatConfigs(
    $roomId: ID
    $seatName: ModelStringKeyConditionInput
    $filter: ModelSeatConfigFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSeatConfigs(
      roomId: $roomId
      seatName: $seatName
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        seatName
        roomId
        seatType
        inventory
        isSeatHeightAdjustable
        owner
        isOwnerRegistered
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBookingConfig = /* GraphQL */ `
  query GetBookingConfig($orgUnitId: ID!) {
    getBookingConfig(orgUnitId: $orgUnitId) {
      orgUnitId
      maxDaysInAdvance
      maxBookableDays
      createdAt
      updatedAt
    }
  }
`;
export const listBookingConfigs = /* GraphQL */ `
  query ListBookingConfigs(
    $orgUnitId: ID
    $filter: ModelBookingConfigFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBookingConfigs(
      orgUnitId: $orgUnitId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        orgUnitId
        maxDaysInAdvance
        maxBookableDays
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMailConfig = /* GraphQL */ `
  query GetMailConfig($userId: ID!) {
    getMailConfig(userId: $userId) {
      userId
      userMail
      isReceiveOwnBooking
      isReceiveForeignBooking
      createdAt
      updatedAt
    }
  }
`;
export const listMailConfigs = /* GraphQL */ `
  query ListMailConfigs(
    $userId: ID
    $filter: ModelMailConfigFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMailConfigs(
      userId: $userId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userId
        userMail
        isReceiveOwnBooking
        isReceiveForeignBooking
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAreBookingsTimeDataUpdated = /* GraphQL */ `
  query GetAreBookingsTimeDataUpdated($id: ID!) {
    getAreBookingsTimeDataUpdated(id: $id) {
      id
      didUpdate
      createdAt
      updatedAt
    }
  }
`;
export const listAreBookingsTimeDataUpdateds = /* GraphQL */ `
  query ListAreBookingsTimeDataUpdateds(
    $id: ID
    $filter: ModelAreBookingsTimeDataUpdatedFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAreBookingsTimeDataUpdateds(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        didUpdate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const roomByNameAndOrgUnitID = /* GraphQL */ `
  query RoomByNameAndOrgUnitID(
    $orgUnitId: ID!
    $nameLowerCased: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    roomByNameAndOrgUnitID(
      orgUnitId: $orgUnitId
      nameLowerCased: $nameLowerCased
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        roomId
        orgUnitId
        name
        nameLowerCased
        roomPlanExisting
        roomPlanId
        roomPlanScaleFactor
        isActive
        isTimeActive
        isBookingDisabled
        isDefault
        isPublic
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const bookingBySeatAndDate = /* GraphQL */ `
  query BookingBySeatAndDate(
    $seatId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingBySeatAndDate(
      seatId: $seatId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bookingId
        date
        seatId
        roomId
        bookerId
        bookerName
        bookerGivenName
        bookerFamilyName
        orgUnitId
        bookingFor
        timeBegin
        timeEnd
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const bookingByDateAndSeatId = /* GraphQL */ `
  query BookingByDateAndSeatId(
    $seatId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingByDateAndSeatId(
      seatId: $seatId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bookingId
        date
        seatId
        roomId
        bookerId
        bookerName
        bookerGivenName
        bookerFamilyName
        orgUnitId
        bookingFor
        timeBegin
        timeEnd
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const bookingByRoomAndDate = /* GraphQL */ `
  query BookingByRoomAndDate(
    $roomId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingByRoomAndDate(
      roomId: $roomId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bookingId
        date
        seatId
        roomId
        bookerId
        bookerName
        bookerGivenName
        bookerFamilyName
        orgUnitId
        bookingFor
        timeBegin
        timeEnd
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const bookingByRoom = /* GraphQL */ `
  query BookingByRoom(
    $roomId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingByRoom(
      roomId: $roomId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bookingId
        date
        seatId
        roomId
        bookerId
        bookerName
        bookerGivenName
        bookerFamilyName
        orgUnitId
        bookingFor
        timeBegin
        timeEnd
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const bookingByBookerAndDate = /* GraphQL */ `
  query BookingByBookerAndDate(
    $bookerId: String!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingByBookerAndDate(
      bookerId: $bookerId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bookingId
        date
        seatId
        roomId
        bookerId
        bookerName
        bookerGivenName
        bookerFamilyName
        orgUnitId
        bookingFor
        timeBegin
        timeEnd
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const bookingsByBooker = /* GraphQL */ `
  query BookingsByBooker(
    $bookerId: String!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingsByBooker(
      bookerId: $bookerId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bookingId
        date
        seatId
        roomId
        bookerId
        bookerName
        bookerGivenName
        bookerFamilyName
        orgUnitId
        bookingFor
        timeBegin
        timeEnd
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const bookingByDateAndOrgUnit = /* GraphQL */ `
  query BookingByDateAndOrgUnit(
    $orgUnitId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingByDateAndOrgUnit(
      orgUnitId: $orgUnitId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bookingId
        date
        seatId
        roomId
        bookerId
        bookerName
        bookerGivenName
        bookerFamilyName
        orgUnitId
        bookingFor
        timeBegin
        timeEnd
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const bookingByMeetingRoomIdAndDate = /* GraphQL */ `
  query BookingByMeetingRoomIdAndDate(
    $meetingRoomId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingRoomBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingByMeetingRoomIdAndDate(
      meetingRoomId: $meetingRoomId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bookingId
        date
        meetingRoomId
        roomId
        bookerId
        bookerName
        bookerGivenName
        bookerFamilyName
        orgUnitId
        timeBegin
        timeEnd
        meetingName
        roomCapacity
        participantNumber
        meetingType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const bookingByDateAndMeetingRoomId = /* GraphQL */ `
  query BookingByDateAndMeetingRoomId(
    $meetingRoomId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingRoomBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingByDateAndMeetingRoomId(
      meetingRoomId: $meetingRoomId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bookingId
        date
        meetingRoomId
        roomId
        bookerId
        bookerName
        bookerGivenName
        bookerFamilyName
        orgUnitId
        timeBegin
        timeEnd
        meetingName
        roomCapacity
        participantNumber
        meetingType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const bookingByRoomAndDate1 = /* GraphQL */ `
  query BookingByRoomAndDate1(
    $roomId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingRoomBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingByRoomAndDate1(
      roomId: $roomId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bookingId
        date
        meetingRoomId
        roomId
        bookerId
        bookerName
        bookerGivenName
        bookerFamilyName
        orgUnitId
        timeBegin
        timeEnd
        meetingName
        roomCapacity
        participantNumber
        meetingType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const bookingByRoom1 = /* GraphQL */ `
  query BookingByRoom1(
    $roomId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingRoomBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingByRoom1(
      roomId: $roomId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bookingId
        date
        meetingRoomId
        roomId
        bookerId
        bookerName
        bookerGivenName
        bookerFamilyName
        orgUnitId
        timeBegin
        timeEnd
        meetingName
        roomCapacity
        participantNumber
        meetingType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const bookingByBookerAndDate1 = /* GraphQL */ `
  query BookingByBookerAndDate1(
    $bookerId: String!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingRoomBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingByBookerAndDate1(
      bookerId: $bookerId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bookingId
        date
        meetingRoomId
        roomId
        bookerId
        bookerName
        bookerGivenName
        bookerFamilyName
        orgUnitId
        timeBegin
        timeEnd
        meetingName
        roomCapacity
        participantNumber
        meetingType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const bookingsByBooker1 = /* GraphQL */ `
  query BookingsByBooker1(
    $bookerId: String!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingRoomBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingsByBooker1(
      bookerId: $bookerId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bookingId
        date
        meetingRoomId
        roomId
        bookerId
        bookerName
        bookerGivenName
        bookerFamilyName
        orgUnitId
        timeBegin
        timeEnd
        meetingName
        roomCapacity
        participantNumber
        meetingType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const bookingByDateAndOrgUnit1 = /* GraphQL */ `
  query BookingByDateAndOrgUnit1(
    $orgUnitId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingRoomBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingByDateAndOrgUnit1(
      orgUnitId: $orgUnitId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bookingId
        date
        meetingRoomId
        roomId
        bookerId
        bookerName
        bookerGivenName
        bookerFamilyName
        orgUnitId
        timeBegin
        timeEnd
        meetingName
        roomCapacity
        participantNumber
        meetingType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const inventoryByLowerCasedName = /* GraphQL */ `
  query InventoryByLowerCasedName(
    $nameLowerCased: String!
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inventoryByLowerCasedName(
      nameLowerCased: $nameLowerCased
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        inventoryId
        orgUnitId
        type
        name
        nameLowerCased
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
