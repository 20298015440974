import React, {CSSProperties, useCallback, useEffect, useMemo, useState} from "react";
import {SortFilterTable, SortFilterTableColumn} from "@2gether/frontend-library";
import {Button, Dialog, DialogActions, DialogContentText, DialogTitle, makeStyles} from "@material-ui/core";
import {Booking, MeetingRoomBooking} from "../API";
import {getCognitoIdTokenFromLocalStorage} from "../Utils/Helpers";
import {DialogContent} from "@mui/material";
import {gql, useMutation, useQuery} from "@apollo/client";
import {
    customBookingsByBookerWithoutRoomObject,
    customMeetRoomBookingsByBookerWithoutRoomObject
} from "../graphqlCustom/queriesCustom";
import {
    deleteBookingNoRoomResponse as deleteBookingNoRoomResponseTemplate,
    deleteMeetRoomBookingNoRoomResponse as deleteMeetRoomBookingNoRoomResponseTemplate
} from "../graphqlCustom/mutationsCustom";
import {createNewTodayDateWithoutHours} from "../services/DateUtils";
import dayjs from "dayjs";
import {useMainApplicationContext} from "../hooks/useMainApplicationContext";
import {useErrorContext} from "../hooks/useErrorContext";
import {useTranslation} from "react-i18next";
import i18n from "i18next";


interface Props {
    showOwnBookingsManagerComponent: boolean
    setShowOwnBookingsManagerComponent: (value: boolean) => void
}

const OwnBookingsManagerComponent: React.FC<Props> = (props) => {
    const {
        showOwnBookingsManagerComponent,
        setShowOwnBookingsManagerComponent,
    } = props

    let {rooms} = useMainApplicationContext()
    const {reportError} = useErrorContext();

    const today: Date = createNewTodayDateWithoutHours()

    const [bookings, setBookings] = useState<(Booking | MeetingRoomBooking)[]>([])
    const [selected, setSelected] = useState<(Booking | MeetingRoomBooking)[]>([])
    const [showAlertDialog, setShowAlertDialog] = useState(false)

    const bookerId = getCognitoIdTokenFromLocalStorage();
    const [deleteBookingNoRoomResponseMutation] = useMutation(gql(deleteBookingNoRoomResponseTemplate))
    const [deleteMeetRoomBookingNoRoomResponseMutation] = useMutation(gql(deleteMeetRoomBookingNoRoomResponseTemplate))
    const {refetch: refetchBookingsByBooker} = useQuery(gql(customBookingsByBookerWithoutRoomObject), {
        variables: {
            bookerId: bookerId,
            date: {ge: today}
        }
    })

    const {refetch: refetchMeetRoomBookingsByBooker} = useQuery(gql(customMeetRoomBookingsByBookerWithoutRoomObject), {
        variables: {
            bookerId: bookerId,
            date: {ge: today}
        }
    })
    const cellStyle: CSSProperties = useMemo(() => {
        return {
            textAlign: "center",
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 8,
            paddingBottom: 8,
        }
    }, [])

    const {t} = useTranslation();
    const localisation = i18n.language
    const columns: SortFilterTableColumn<Booking | MeetingRoomBooking>[] = useMemo(() =>
            [
                {
                    key: "date",
                    label: t("date"),
                    render: (booking: Booking | MeetingRoomBooking) => new Date(booking.date).toLocaleDateString(localisation, {
                        weekday: 'short',
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric'
                    }),
                    getSortValue: (booking: Booking | MeetingRoomBooking) => booking.date,
                    getCellStyle: () => cellStyle,
                },
                {
                    key: "roomPlane",
                    label: t("roomplan"),
                    render: (booking: Booking | MeetingRoomBooking) => rooms.find((room) => room.roomId === booking.roomId)?.name,
                    getSortValue: (booking: Booking | MeetingRoomBooking) => rooms.find((room) => room.roomId === booking.roomId)?.name ?? "",
                    getCellStyle: () => cellStyle,
                },
                {
                    key: "seat",
                    label: t("seat"),
                    render: (booking: Booking | MeetingRoomBooking) => booking.__typename === "Booking" ? booking.seatId : booking.meetingRoomId,
                    getSortValue: (booking: Booking | MeetingRoomBooking) => booking.__typename === "Booking" ? booking.seatId : booking.meetingRoomId,
                    getCellStyle: () => cellStyle,
                },
            ],
        [cellStyle, rooms, t, localisation]
    )

    const columnsWithTime: SortFilterTableColumn<Booking | MeetingRoomBooking>[] = useMemo(() =>
            [
                {
                    key: "date",
                    label: t("date"),
                    render: (booking: Booking | MeetingRoomBooking) => new Date(booking.date).toLocaleDateString(localisation, {
                        weekday: 'short',
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric'
                    }),
                    getSortValue: (booking: Booking | MeetingRoomBooking) => booking.date,
                    getCellStyle: () => cellStyle,
                },
                {
                    key: "roomPlane",
                    label: t("roomplan"),
                    render: (booking: Booking | MeetingRoomBooking) => rooms.find((room) => room.roomId === booking.roomId)?.name,
                    getSortValue: (booking: Booking | MeetingRoomBooking) => rooms.find((room) => room.roomId === booking.roomId)?.name ?? "",
                    getCellStyle: () => cellStyle,
                },
                {
                    key: "seat",
                    label: t("seat"),
                    render: (booking: Booking | MeetingRoomBooking) => booking.__typename === "Booking" ? booking.seatId : booking.meetingRoomId,
                    getSortValue: (booking: Booking | MeetingRoomBooking) => booking.__typename === "Booking" ? booking.seatId : booking.meetingRoomId,
                    getCellStyle: () => cellStyle,
                },
                {
                    key: "time",
                    label: t("timeWindow"),
                    render: (booking: Booking | MeetingRoomBooking) => formatTimeWindow(booking.timeBegin, booking.timeEnd),
                    getSortValue: (booking: Booking | MeetingRoomBooking) => booking.timeBegin ? booking.timeBegin : "",
                    getCellStyle: () => cellStyle,
                },
            ],
        [cellStyle, rooms, t, localisation]
    )


    function formatTimeWindow(begin: string | null | undefined, end: string | null | undefined): string {
        const timeBegin = begin ? dayjs(begin).format("HH:mm") + "\u202F\u2013\u202F" : ""
        const timeEnd = end ? dayjs(end).format("HH:mm") : ""
        return timeBegin + timeEnd
    }

    const isSelected = useCallback((booking: Booking | MeetingRoomBooking) => {
        return selected.some((item) => (item.bookingId === booking.bookingId && item.date === booking.date))
    }, [selected])

    const select = useCallback((booking: Booking | MeetingRoomBooking) => {
        setSelected(prevState => prevState.some((item) => (item.bookingId === booking.bookingId && item.date === booking.date)) ?
            prevState.filter((item) => (item.bookingId !== booking.bookingId || item.date !== booking.date)) : [...prevState, booking])
    }, [])

    const useStyles = makeStyles({
        dialogPaper: {
            minHeight: '50vh',
        },
    })

    const classes = useStyles()

    const hasTimeBooking = useMemo(() => {
        return bookings.some((booking: Booking | MeetingRoomBooking) => {
            let roomOfTheBooking = rooms.find((room) => room.roomId === booking.roomId)
            return roomOfTheBooking?.isTimeActive
        })
    }, [bookings, rooms])


    useEffect(() => {
        updateMyBookedDays().then()
        updateMyMeetRoomBookedDays().then()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showOwnBookingsManagerComponent])

    async function updateMyBookedDays() {
        const x = bookings.filter(b => b.__typename === "MeetingRoomBooking")
        await refetchBookingsByBooker().then((bookingsByBookerData) => {
            setBookings([...x, ...bookingsByBookerData.data.bookingsByBooker.items.map((b: Booking) => {
                return {...b, __typename: "Booking"}
            })])
        }).catch((err) => reportError(err, "", "OwnBookingsManagerComponent updateMyBookedDays"))
    }

    async function updateMyMeetRoomBookedDays() {
        const x = bookings.filter(b => b.__typename === "Booking")
        await refetchMeetRoomBookingsByBooker().then((bookingsByBookerData) => {
            setBookings([...x, ...bookingsByBookerData.data.bookingsByBooker1.items.map((b: MeetingRoomBooking) => {
                return {...b, __typename: "MeetingRoomBooking"}
            })])
        }).catch((err) => reportError(err, "", "OwnBookingsManagerComponent updateMyMeetRoomBookedDays"))
    }

    function handleCloseBookingManagerDialog(): void {
        setShowOwnBookingsManagerComponent(false)
        setSelected([])
    }

    function handleCloseAlertDialog(): void {
        setShowAlertDialog(false)
    }

    function handleOpenAlertDialog(): void {
        setShowAlertDialog(true)
    }

    function handleDeleteSelectedBookings(): void {
        selected.forEach((booking) => {
            if (booking.__typename === "Booking") {
                deleteBooking(booking.bookingId, booking.date)
            } else if (booking.__typename === "MeetingRoomBooking") {
                deleteMeetRoomBooking(booking.bookingId, booking.date)
            }
        })
        handleCloseAlertDialog()
        setSelected([])
    }

    function deleteBooking(bookingId: string, dateISO: string): void {
        deleteBookingNoRoomResponseMutation({
            variables: {
                input: {
                    bookingId: bookingId,
                    date: dateISO
                }
            }
        }).then(() => {
            updateMyBookedDays().then()
        }).catch((err) => reportError(err, "", "OwnBookingsManagerComponent deleteBooking"))
    }

    function deleteMeetRoomBooking(bookingId: string, dateISO: string): void {
        deleteMeetRoomBookingNoRoomResponseMutation({
            variables: {
                input: {
                    bookingId: bookingId,
                    date: dateISO
                }
            }
        }).then(() => {
            updateMyMeetRoomBookedDays().then()
        }).catch((err) => reportError(err, "", "OwnBookingsManagerComponent deleteMeetRoomBooking"))
    }

    function renderOwnBookingsDialog() {
        return (
            <>
                <Dialog classes={{paper: classes.dialogPaper}}
                        open={showOwnBookingsManagerComponent}
                        fullWidth={true}
                        maxWidth={"md"}
                        data-testid={"own-bookings-dialog-test-id"}
                        onClose={handleCloseBookingManagerDialog}>
                    <DialogTitle>
                        {t("own_bookings_management_dialog_title")}
                    </DialogTitle>
                    <DialogContent
                        style={{overflowY: "hidden"}}>
                        {hasTimeBooking ?
                            <SortFilterTable
                                columns={columnsWithTime}
                                entries={bookings}
                                initialSorting={{column: "date", order: "asc"}}
                                tableHeight={500}
                                fullWidth={true}
                                isSelected={isSelected} onSelect={select}/> :
                            <SortFilterTable
                                columns={columns}
                                entries={bookings}
                                initialSorting={{column: "date", order: "asc"}}
                                tableHeight={500}
                                fullWidth={true}
                                isSelected={isSelected} onSelect={select}/>}
                    </DialogContent>

                    <DialogActions>
                        <Button
                            disabled={selected.length === 0}
                            color={"primary"}
                            variant={"contained"}
                            data-testid={"btn-delete"}
                            onClick={handleOpenAlertDialog}
                        >
                            {t("delete")}
                        </Button>
                        <Button
                            onClick={handleCloseBookingManagerDialog}
                            color={"primary"}
                            variant={"contained"}
                            data-testid={"btn-close"}
                        >
                            {t("confirm_dialog_cancel_button-text")}
                        </Button>

                    </DialogActions>
                </Dialog>
            </>
        )
    }

    function renderAlertDialog() {
        return (
            <>
                <Dialog
                    open={showAlertDialog}
                    onClose={handleCloseAlertDialog}
                    maxWidth={"xs"}
                    data-testid={"own-bookings-alert-dialog"}
                >
                    <DialogTitle>
                        {t("own_bookings_management_alert_dialog_title")}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t("own_bookings_management_alert_dialog_amount_bookings")}: {selected.length}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={selected.length === 0}
                            color={"primary"}
                            variant={"contained"}
                            data-testid={"alert-dialog-btn-delete"}
                            onClick={handleDeleteSelectedBookings}
                        >
                            {t("delete")}
                        </Button>
                        <Button
                            onClick={handleCloseAlertDialog}
                            color={"primary"}
                            variant={"contained"}
                            data-testid={"alert-dialog-btn-close"}
                        >
                            {t("confirm_dialog_cancel_button-text")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }


    return (
        <>
            {renderOwnBookingsDialog()}
            {renderAlertDialog()}
        </>
    )
}
export default OwnBookingsManagerComponent

