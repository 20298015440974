import React from "react";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent} from "@material-ui/core";
import {useTranslation} from "react-i18next";

interface Props {
    showUploadConfirmationDialog: boolean,
    seatsToDeleteArrayIds: string[],
    uploadRoomPlanAfterConfirmation: () => {}
    onUploadLoading: boolean
    onCloseUploadConfirmationDialog: () => void
}


const RoomManagerUploadConfirmationDialog: React.FC<Props> = (props) => {
    const {
        showUploadConfirmationDialog,
        seatsToDeleteArrayIds,
        uploadRoomPlanAfterConfirmation,
        onUploadLoading,
        onCloseUploadConfirmationDialog
    } = props

    const {t} = useTranslation();
    return (
        <Dialog open={showUploadConfirmationDialog}>
            <DialogContent>
                {t("rm_showUploadConfirmationDialog-text")}
                {seatsToDeleteArrayIds.map((seatId) => <p><strong>{seatId}</strong></p>)}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => uploadRoomPlanAfterConfirmation()}
                    color={"primary"}
                    variant={"contained"}
                    startIcon={onUploadLoading && <CircularProgress style={{color: "white"}} size={25}/>}
                >
                    {t("rm_showUploadConfirmationDialog_refresh_button-text")}
                </Button>
                <Button
                    onClick={onCloseUploadConfirmationDialog}
                    color={"primary"}
                    variant={"contained"}
                >
                    {t("rm_showUploadConfirmationDialog_cancel_button-text")}
                </Button>
            </DialogActions>
        </Dialog>
    )

}

export default RoomManagerUploadConfirmationDialog;