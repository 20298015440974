import {Auth} from "aws-amplify";
import {getOrgUnit} from "../hooks/useOwnOrgUnit";
import {IOrgUnit} from "../hooks/useOrgunit";

export interface User {
    ID: string
    name: string
    email: string
    givenName: string
    familyName: string
    orgUnits: IOrgUnit[]
    isAdmin: boolean
    isOrgUnitAdmin: boolean
}

export default class UserClient {

    public static async getPayLoad(): Promise<any> {
        return Auth.currentSession().then(session => session.getIdToken().payload);
    }

    public static async getCurrentUser(): Promise<User> {
        const idTokenPayload: any = await UserClient.getPayLoad();
        let orgUnits: IOrgUnit[] = [];
        await getOrgUnit().then((_orgUnits) => orgUnits = _orgUnits, (err) => console.error(err));
        let isSit2GetherAdmin = false;
        let isSit2GetherOrgUnitAdmin = false;
        (idTokenPayload["cognito:groups"] as String[])
            .forEach((role) => {
                if (role === "Sit2Gether-Admin")
                    isSit2GetherAdmin = true;
                else if (role === "Sit2Gether-Org-Einheiten-Admin")
                    isSit2GetherOrgUnitAdmin = true;
            })
        return {
            ID: idTokenPayload.sub,
            name: idTokenPayload.given_name + " " + idTokenPayload.family_name,
            email: idTokenPayload.email,
            givenName: idTokenPayload.given_name,
            familyName: idTokenPayload.family_name,
            orgUnits: orgUnits,
            isAdmin: isSit2GetherAdmin,
            isOrgUnitAdmin: isSit2GetherOrgUnitAdmin
        };
    }
}