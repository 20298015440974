import {KeyboardArrowDown} from "@mui/icons-material";
import colors from "../../styles/colors";
import {MeetingType} from "../../Utils/Enums";
import React from "react";
import {DropdownList} from "react-widgets";
import {makeStyles} from "@material-ui/core";
import uiElementMeasures from "../../styles/inputElementMeasures";
import borders from "../../styles/borders";
import {useTranslation} from "react-i18next";

interface Props {
    meetingTypeState: MeetingType,
    setMeetingTypeState: (state: MeetingType) => void
}

const MeetingRoomBookingDropdown: React.FC<Props> = (props) => {
    const {
        meetingTypeState,
        setMeetingTypeState
    } = props

    const meetingTypeOptions = [MeetingType.INTERNAL, MeetingType.EXTERNAL, MeetingType.VIP]

    const useStyles = makeStyles({
        leftSideChild: {
            marginBottom: uiElementMeasures.marginBetweenElementsInColumn,
            width: "auto",
            color: colors.iconAndTextGrey,
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderRadius: borders.mediumBorderRadius,
                },
            },
        }
    })

    const classes = useStyles()

    return <>
        <DropdownList
            data-testid={"meeting-type-dropdown"}
            data={meetingTypeOptions}
            value={meetingTypeState}
            selectIcon={<KeyboardArrowDown fontSize={"small"} style={{color: colors.iconAndTextGrey}}/>}
            className={classes.leftSideChild}
            dropUp
            filter={false}
            renderListItem={({item}) => <RenderListItem meetingType={item} role={"option"}/>}
            renderValue={({item}) => <RenderListItem meetingType={item} role={"value"}/>}
            onSelect={val => setMeetingTypeState(val)}
        />
    </>
}

export default MeetingRoomBookingDropdown

const RenderListItem = ({meetingType, role}: { meetingType: MeetingType, role: "option" | "value" }) => {
    const {t} = useTranslation();

    function getText() {
        switch (meetingType) {
            case MeetingType.INTERNAL:
                return t("multibookingdialog_meetingroom_booking_type_internal");
            case MeetingType.EXTERNAL:
                return t("multibookingdialog_meetingroom_booking_type_external");
            case MeetingType.VIP:
                return t("multibookingdialog_meetingroom_booking_type_vip");
        }
    }

    if (role === "option")
        return <span data-testid={"dropdown-option-" + meetingType}>{getText()}</span>;
    else
        return <span data-testid={"dropdown-value-" + meetingType}>{getText()}</span>;

}
