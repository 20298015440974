export const getRoomSeats = /* GraphQL */ `
    query getRoomSeats($roomId: ID!) {
        getRoom(roomId: $roomId) {
            seats {
                items {
                    roomId
                    inventory
                    seatName
                    seatType
                    isSeatHeightAdjustable
                    owner
                    isOwnerRegistered
                }
            }
        }
    }
`;

export const customBookingsByBookerWithRoomObject = /* GraphQL */ `
    query BookingsByBooker(
        $bookerId: String!
        $date: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelBookingFilterInput
        $limit: Int
        $nextToken: String
    ) {
        bookingsByBooker(
            bookerId: $bookerId
            date: $date
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                bookingId
                date
                seatId
                roomId
                # room {
                #     name
                # }
                bookerId
                bookerName
                bookerGivenName
                bookerFamilyName
                orgUnitId
                bookingFor
                timeBegin
                timeEnd
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
export const customBookingsByBookerWithoutRoomObject = /* GraphQL */ `
    query BookingsByBooker(
        $bookerId: String!
        $date: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelBookingFilterInput
        $limit: Int
        $nextToken: String
    ) {
        bookingsByBooker(
            bookerId: $bookerId
            date: $date
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                bookingId
                date
                seatId
                roomId
                bookerId
                bookerName
                bookerGivenName
                bookerFamilyName
                orgUnitId
                bookingFor
                timeBegin
                timeEnd
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const customMeetRoomBookingsByBookerWithoutRoomObject = /* GraphQL */ `
    query BookingsByBooker1(
        $bookerId: String!
        $date: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelMeetingRoomBookingFilterInput
        $limit: Int
        $nextToken: String
    ) {
        bookingsByBooker1(
            bookerId: $bookerId
            date: $date
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                bookingId
                date
                meetingRoomId
                roomId
                bookerId
                bookerName
                bookerGivenName
                bookerFamilyName
                orgUnitId
                timeBegin
                timeEnd
                meetingName
                roomCapacity
                participantNumber
                meetingType
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;