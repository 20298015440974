import {
    Box,
    FormControl,
    IconButton,
    InputLabel,
    makeStyles,
    NativeSelect,
    Paper,
    TextField,
    Tooltip
} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import React, {ChangeEvent, useEffect, useMemo, useRef, useState} from "react";
import {gql, useMutation, useQuery} from "@apollo/client";
import {
    createBookingConfig as createBookingConfigTemplate,
    createRoom as createRoomTemplate,
    updateRoom as updateRoomTemplate,
    updateSeatConfig,
} from "../graphql/mutations";
import {getBookingConfig, roomByNameAndOrgUnitID as roomByNameAndOrgUnitIdTemplate} from "../graphql/queries"
import {v4 as uuidv4} from 'uuid';
import materna_theme from "../styles/materna_theme";
import {Inventory, Room, SeatConfig} from "../API";
import {IOrgUnit} from "../hooks/useOrgunit";
import {useSeatConfigList} from "../hooks/useSeatConfigList";
import {useInventoryList} from "../hooks/useInventoryList";
import {useErrorContext} from "../hooks/useErrorContext";
import {useTranslation} from "react-i18next";


interface Props {
    setShowCreateRoom: (value: boolean) => void
    roomToEdit: Room | undefined;
    preselectedOrgUnitId: string | undefined;
    setIsShowAlerts: (a: any) => void
    orgUnitList: IOrgUnit[]
    handleUpdateOrgUnitIdForBookings: (roomId: string, newOrgUnitId: string) => void
    isAdmin: boolean
}

const CreateRoomComponent: React.FC<Props> = (props) => {
    const {
        setShowCreateRoom,
        roomToEdit,
        preselectedOrgUnitId,
        setIsShowAlerts,
        orgUnitList,
        handleUpdateOrgUnitIdForBookings,
        isAdmin
    } = props;

    const [roomName, setRoomName] = useState("")
    const [scaleFactorValue, setScaleFactorValue] = useState<number>(100)
    const [selectedOrgUnitId, setSelectedOrgUnitId] = useState<string>();
    const {reportError} = useErrorContext()

    const nameInputRef = useRef<HTMLInputElement>(null);
    const [updateSeatConfigMutation] = useMutation(gql(updateSeatConfig))

    const inventories: Inventory[] = useInventoryList()
    const seatConfigs = useSeatConfigList(props.roomToEdit?.roomId ?? "")

    const useStyles = makeStyles({
        btnEnabled: {
            color: "green"
        },
        btnDisabled: {
            color: "disabled"
        },
        txtRoomName: {
            [materna_theme.breakpoints.up('xs')]: {
                width: "40%",
            },
            [materna_theme.breakpoints.up('lg')]: {
                width: "40%",
            },
            [materna_theme.breakpoints.up('xl')]: {
                width: "65%",
            },
        },
        scaleFactorInput: {
            width: "6rem",
            marginLeft: "10px"
        }
    });
    const classes = useStyles();

    const [createRoomMutation] = useMutation(gql(createRoomTemplate))
    const [updateRoomMutation] = useMutation(gql(updateRoomTemplate))
    const [createBookingConfigMutation] = useMutation(gql(createBookingConfigTemplate))
    const {refetch} = useQuery(gql(roomByNameAndOrgUnitIdTemplate))
    const {data, refetch: refetchBookingConfig} = useQuery(gql(getBookingConfig), {
        variables: {
            orgUnitId: preselectedOrgUnitId
        }
    })

    useEffect(function refetchBookingConfigWhenOrgUnitIdChanges() {
        if (preselectedOrgUnitId)
            refetchBookingConfig()
                .then()
                .catch(error => console.error("CreateRoomComponent refetchBookingConfigWhenOrgUnitIdChanges", error))
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preselectedOrgUnitId])

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setRoomName(event.target.value)
    }
    const handleScaleFactor = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        if (!isNaN(+event.target.value) && (+event.target.value) <= 1000) {
            setScaleFactorValue(+event.target.value)
        }
    }

    const handleCreateRoom = () => {
        createRoomMutation({
            variables: {
                input: {
                    name: roomName.trim(),
                    roomId: uuidv4(),
                    nameLowerCased: roomName.trim().toLocaleLowerCase(),
                    orgUnitId: selectedOrgUnitId,
                    roomPlanScaleFactor: scaleFactorValue ? scaleFactorValue : 100,
                }
            }
        })
            .then(() => setShowCreateRoom(false))
            .catch((err) => reportError(err, "", "CreateRoomComponent handleCreateRoom"))
    }

    const {t} = useTranslation();

//ToDo: Move to Lambda?
    function checkInventory(orgUnitId: string, inv: string) {
        let oldInventory = inventories.find(_inv => _inv.inventoryId === inv)
        if (oldInventory !== undefined) {
            return inventories.find((inventory: Inventory) => {
                return inventory.orgUnitId === orgUnitId && inventory.name === oldInventory?.name
            })
        }
    }

//ToDo: Move to Lambda?
    function generateNewInventoryList(seatConfig: SeatConfig, newOrgUnitId: string, seatInventory: string[]) {
        if (seatConfig.inventory) {
            seatConfig.inventory.forEach(inv => {
                let maybeNewInventory = checkInventory(newOrgUnitId, inv)
                if (maybeNewInventory) {
                    seatInventory.push(maybeNewInventory.inventoryId)
                }
            })
        }
    }

//ToDo: Move to Lambda?
    function migrateSeatInventory(newOrgUnitId: string) {
        if (seatConfigs) {
            seatConfigs.forEach((seatConfig: SeatConfig) => {
                let seatInventory: string[] = []
                generateNewInventoryList(seatConfig, newOrgUnitId, seatInventory);
                updateSeatConfigMutation({
                    variables: {
                        input: {
                            seatName: seatConfig.seatName,
                            roomId: seatConfig.roomId,
                            isSeatHeightAdjustable: seatConfig.isSeatHeightAdjustable,
                            owner: seatConfig.owner,
                            isOwnerRegistered: seatConfig.isOwnerRegistered,
                            inventory: seatInventory
                        }
                    }
                }).catch((err) => reportError(err, "", "CreateRoomComponent migrateSeatInventory"))
            })
        }
    }

    const handleUpdateRoom = () => {
        const orgUnitIdChanged = selectedOrgUnitId !== roomToEdit!.orgUnitId
        if (orgUnitIdChanged && selectedOrgUnitId)
            migrateSeatInventory(selectedOrgUnitId)
//Update Seat Inventory
        updateRoomMutation({
            variables: {
                input: {
                    name: roomName.trim(),
                    roomId: roomToEdit!.roomId,
                    nameLowerCased: roomName.trim().toLocaleLowerCase(),
                    orgUnitId: selectedOrgUnitId,
                    roomPlanScaleFactor: scaleFactorValue ? scaleFactorValue : 100,
                    isDefault: orgUnitIdChanged ? false : roomToEdit!.isDefault,
                }
            }
        })
            .then(() => {
                if (orgUnitIdChanged)
                    handleUpdateOrgUnitIdForBookings(roomToEdit!.roomId, selectedOrgUnitId!)
            })
            .then(() => setShowCreateRoom(false))
            .catch((err) => reportError(err, "", "CreateRoomComponent handleUpdateRoom "))
    }

    const handleSave = async () => {
        const result = await refetch({
            orgUnitId: selectedOrgUnitId!,
            nameLowerCased: {eq: roomName!.trim().toLocaleLowerCase()}
        }).catch((err) => reportError(err, "", "CreateRoomComponent handleSave"))
        createBookingConfigOnSave()
        if (result && result.data && result.data.roomByNameAndOrgUnitID && result.data.roomByNameAndOrgUnitID.items.length > 0) {
            if (!!roomToEdit && result.data.roomByNameAndOrgUnitID.items[0].roomId === roomToEdit!.roomId) {
                handleUpdateRoom()
            } else {
                setIsShowAlerts(
                    {success: false, error: false, error_create_room: true})
                setTimeout(() => {
                    setIsShowAlerts({success: false, error: false, error_create_room: false})
                }, 4000)
            }
        } else {
            if (!!roomToEdit) {
                handleUpdateRoom()
            } else handleCreateRoom()
        }
    }

    const createBookingConfigOnSave = () => {
        const defaultConfigUnlimited = 0;
        if (!data.getBookingConfig) {
            createBookingConfigMutation({
                variables: {
                    input: {
                        orgUnitId: preselectedOrgUnitId,
                        maxDaysInAdvance: defaultConfigUnlimited,
                        maxBookableDays: defaultConfigUnlimited,
                    }
                }
            }).then().catch(reason => console.error(reason))
        }
    }

    const isRoomNameValid = useMemo(() => {
        return roomName.trim().length > 0;
    }, [roomName])

    useEffect(() => {
        if (roomToEdit !== undefined) {
            setRoomName(roomToEdit.name)
            if (roomToEdit.roomPlanScaleFactor) {
                setScaleFactorValue(roomToEdit.roomPlanScaleFactor)
            }
            if (roomToEdit.orgUnitId) {
                setSelectedOrgUnitId(roomToEdit.orgUnitId)
            }
        } else {
            setSelectedOrgUnitId(preselectedOrgUnitId)
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (nameInputRef?.current !== null) {
            nameInputRef.current.focus();
        }
    }, [])

    return (
        <Paper style={{
            marginTop: "0.625rem",
            padding: "0.5rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        }} data-testid={"createRoomComponent"}>
            <Box style={{
                paddingTop: "1rem",
                display: "flex",
                justifyContent: "flex-start",
                width: "74%"
            }}>
                <TextField
                    className={classes.txtRoomName}
                    style={{marginRight: "10px", width: "60%"}}
                    label={t('room-name')}
                    value={roomName}
                    onChange={handleInputChange}
                    inputRef={nameInputRef}
                    inputProps={{
                        "data-testid": "roomName-text"
                    }}/>
                {!!roomToEdit &&
                    <> {isAdmin && <FormControl style={{width: "39%"}}>
                        <InputLabel shrink htmlFor="org-unit">
                            {t("daily_bookings_table_orgunit_column")}
                        </InputLabel>
                        <NativeSelect
                            value={selectedOrgUnitId}
                            onChange={(e) => setSelectedOrgUnitId(e.target.value)}
                            data-testid={"select-org-unit-on-edit"}>

                            {orgUnitList?.map((org: IOrgUnit) => {
                                    return <option
                                        key={org.orgName}
                                        value={org.orgId}
                                        data-testid={"org-dropdown-on-edit-option-" + org.orgName}>
                                        {org.orgName}
                                    </option>
                                }
                            )}
                        </NativeSelect>
                    </FormControl>}
                        <TextField
                            className={classes.scaleFactorInput}
                            label={t("scaleFactor-column")}
                            value={scaleFactorValue}
                            onChange={handleScaleFactor}
                            inputProps={{"data-testid": "scale-factor-input"}}
                        />
                    </>}
            </Box>

            <Box style={{
                paddingTop: "1rem",
                display: "flex",
                justifyContent: "flex-end",
                width: "25%"
            }}>
                <Tooltip title={t('confirm_dialog_ok_button-text')}>
                    <span>
                    <IconButton onClick={handleSave} disabled={!isRoomNameValid} data-testid={"save-btn"}>
                    <SaveIcon className={roomName.trim().length > 0 ? classes.btnEnabled : classes.btnDisabled}
                              style={{cursor: "pointer"}} fontSize="large"/>
                    </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title={t('cancel')}>
                    <IconButton onClick={() => setShowCreateRoom(false)} data-testid={"cancel-btn"}>
                        <CancelIcon style={{cursor: "pointer"}} color="primary" fontSize="large"/>
                    </IconButton>
                </Tooltip>

            </Box>
        </Paper>
    )
}
export default CreateRoomComponent