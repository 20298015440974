import {gql, useQuery} from "@apollo/client";
import {bookingByRoomAndDate, bookingByRoomAndDate1} from "../graphql/queries";
import {
    onCreateBooking,
    onCreateBookings,
    onCreateMeetingRoomBooking, onCreateMeetingRoomBookings,
    onDeleteBooking, onDeleteMeetingRoomBooking,
    onUpdateBooking, onUpdateMeetingRoomBooking
} from "../graphql/subscriptions";
import {useEffect} from "react";
import {Booking, MeetingRoomBooking} from "../API"
import {useErrorContext} from "./useErrorContext";

export function useBookingList(roomId: string, roomOrgUnitId: string, dateISO?: string): (Booking | MeetingRoomBooking)[] | [] {
    const {reportError} = useErrorContext()
    const {data: data0, subscribeToMore: subscribeToMore0} = useQuery(gql(bookingByRoomAndDate), {
        variables: {
            roomId: roomId,
            date: {eq: dateISO}
        }, skip: (roomId === "")
    })

    const {data: data1, subscribeToMore: subscribeToMore1} = useQuery(gql(bookingByRoomAndDate1), {
        variables: {
            roomId: roomId,
            date: {eq: dateISO}
        }, skip: (roomId === "")
    });

    useEffect(() => {
        const unsubscribeOnCreateBooking = subscribeToMore0({
            document: gql(onCreateBooking),
            variables: {
                roomId: roomId,
                date: dateISO,
                orgUnitId: roomOrgUnitId
            },
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data) return prev;
                const newBooking = subscriptionData.data.onCreateBooking;
                return (Object.assign({}, prev, {
                        bookingByRoomAndDate: {
                            items: [
                                ...prev.bookingByRoomAndDate.items,
                                {...newBooking, __typename: "Booking"}
                            ],
                            nextToken: null,
                            __typename: "ModelBookingConnection"
                        }
                    })
                )
            },
            onError: (error: any) => reportError(error, "", "useBookingList onCreateBookingSubscription")
        });
        const unsubscribeOnCreateBookings = subscribeToMore0({
            document: gql(onCreateBookings),
            variables: {
                roomId: roomId,
                date: dateISO,
                orgUnitId: roomOrgUnitId
            },
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data) return prev;
                const newBookings = subscriptionData.data.onCreateBookings.Items;
                return (Object.assign({}, prev, {
                        bookingByRoomAndDate: {
                            items: [
                                ...prev.bookingByRoomAndDate.items,
                                ...newBookings.map((b: Booking) => ({...b, __typename: "Booking"}))
                            ],
                            nextToken: null,
                            __typename: "ModelBookingConnection"
                        }
                    })
                )
            },
            onError: (error: any) => reportError(error, "", "useBookingList onCreateBookingsSubscription")
        });
        const unsubscribeOnDeleteBooking = subscribeToMore0({
            document: gql(onDeleteBooking),
            variables: {
                roomId: roomId,
                date: dateISO,
                orgUnitId: roomOrgUnitId
            },
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data) return prev;
                const deletedBookingId = subscriptionData.data.onDeleteBooking.bookingId;
                const deletedBookingDate = subscriptionData.data.onDeleteBooking.date;
                return Object.assign({}, prev, {
                    bookingByRoomAndDate: {
                        items: prev.bookingByRoomAndDate.items.filter((item: Booking) => (item.bookingId !== deletedBookingId || item.date !== deletedBookingDate)),
                        nextToken: null,
                        __typename: "ModelBookingConnection"
                    }
                })
            },
            onError: (error: any) => reportError(error, "", "useBookingList onDeleteBookingSubscription")
        });
        const unsubscribeOnUpdateBooking = subscribeToMore0({
            document: gql(onUpdateBooking),
            variables: {
                roomId: roomId,
                date: dateISO,
                orgUnitId: roomOrgUnitId
            },
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data) return prev;
                const updatedBooking = subscriptionData.data.onUpdateBooking;
                return (Object.assign({}, prev, {
                        bookingByRoomAndDate: {
                            items: [
                                ...prev.bookingByRoomAndDate.items.filter((booking: Booking) => booking.bookingId !== updatedBooking.bookingId),
                                {...updatedBooking, __typename: "Booking"}
                            ],
                            nextToken: null,
                            __typename: "ModelBookingConnection"
                        }
                    })
                )
            },
            onError: (error: any) => reportError(error, "", "useBookingList onUpdateBookingSubscription")
        });

        const unsubscribeOnCreateBooking1 = subscribeToMore1({
            document: gql(onCreateMeetingRoomBooking),
            variables: {
                roomId: roomId,
                date: dateISO,
                orgUnitId: roomOrgUnitId
            },
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data) return prev;
                const newBooking = subscriptionData.data.onCreateMeetingRoomBooking;
                return (Object.assign({}, prev, {
                        bookingByRoomAndDate1: {
                            items: [
                                ...prev.bookingByRoomAndDate1.items,
                                {...newBooking, __typename: "MeetingRoomBooking"}
                            ],
                            nextToken: null,
                            __typename: "ModelMeetingRoomBookingConnection"
                        }
                    })
                )
            },
            onError: (error: any) => reportError(error, "", "useBookingList onCreateBookingSubscription")
        });
        const unsubscribeOnCreateBookings1 = subscribeToMore1({
            document: gql(onCreateMeetingRoomBookings),
            variables: {
                roomId: roomId,
                date: dateISO,
                orgUnitId: roomOrgUnitId
            },
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data) return prev;
                const newBookings = subscriptionData.data.onCreateMeetingRoomBookings.Items;
                return (Object.assign({}, prev, {
                        bookingByRoomAndDate1: {
                            items: [
                                ...prev.bookingByRoomAndDate1.items,
                                ...newBookings.map((b: MeetingRoomBooking) => ({...b, __typename: "MeetingRoomBooking"}))
                            ],
                            nextToken: null,
                            __typename: "ModelMeetingRoomBookingConnection"
                        }
                    })
                )
            },
            onError: (error: any) => reportError(error, "", "useBookingList onCreateBookingsSubscription")
        });
        const unsubscribeOnDeleteBooking1 = subscribeToMore1({
            document: gql(onDeleteMeetingRoomBooking),
            variables: {
                roomId: roomId,
                date: dateISO,
                orgUnitId: roomOrgUnitId
            },
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data) return prev;
                const deletedBookingId = subscriptionData.data.onDeleteMeetingRoomBooking.bookingId;
                const deletedBookingDate = subscriptionData.data.onDeleteMeetingRoomBooking.date;
                return Object.assign({}, prev, {
                    bookingByRoomAndDate1: {
                        items: prev.bookingByRoomAndDate1.items
                            .filter((item: MeetingRoomBooking) => (item.bookingId !== deletedBookingId || item.date !== deletedBookingDate)),
                        nextToken: null,
                        __typename: "ModelMeetingRoomBookingConnection"
                    }
                })
            },
            onError: (error: any) => reportError(error, "", "useBookingList onDeleteBookingSubscription")
        });
        const unsubscribeOnUpdateBooking1 = subscribeToMore1({
            document: gql(onUpdateMeetingRoomBooking),
            variables: {
                roomId: roomId,
                date: dateISO,
                orgUnitId: roomOrgUnitId
            },
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data) return prev;
                const updatedBooking = subscriptionData.data.onUpdateMeetingRoomBooking;
                return (Object.assign({}, prev, {
                        bookingByRoomAndDate1: {
                            items: [
                                ...prev.bookingByRoomAndDate1.items
                                    .filter((booking: MeetingRoomBooking) => booking.bookingId !== updatedBooking.bookingId),
                                {...updatedBooking, __typename: "MeetingRoomBooking"}
                            ],
                            nextToken: null,
                            __typename: "ModelMeetingRoomBookingConnection"
                        }
                    })
                )
            },
            onError: (error: any) => reportError(error, "", "useBookingList onUpdateBookingSubscription")
        });

        function unsubscribe() {
            unsubscribeOnCreateBooking();
            unsubscribeOnCreateBookings();
            unsubscribeOnDeleteBooking();
            unsubscribeOnUpdateBooking();
            unsubscribeOnCreateBooking1();
            unsubscribeOnCreateBookings1();
            unsubscribeOnDeleteBooking1();
            unsubscribeOnUpdateBooking1();
        }

        return () => unsubscribe();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roomOrgUnitId, roomId, dateISO]);

    const bookings = data0?.bookingByRoomAndDate?.items.map((b: Booking) => ({...b, __typename: "Booking"})) ?? []
    const meetRoomBookings = data1?.bookingByRoomAndDate1?.items.map((b: MeetingRoomBooking) => ({...b, __typename: "MeetingRoomBooking"})) ?? []

    return [...bookings, ...meetRoomBookings]

}