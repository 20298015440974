import HeaderComponent from "../components/HeaderComponent";
import React, {ReactElement, useCallback, useEffect, useMemo, useState} from "react";
import {useRoomList} from "../hooks/useRoomList";
import Auth from "@aws-amplify/auth";
import UserClient, {User} from "../services/UserClient";
import {useInventoryList} from "../hooks/useInventoryList";
import {Inventory, Room} from "../API";
import OwnBookingsManagerComponent from "../components/OwnBookingsManagerComponent";
import {useBookingByDateAndOrgUnit} from "../hooks/useBookingByDateAndOrgUnit";
import dayjs from "dayjs";
import DailyBookingsDisplay from "../components/DailyBookingsDisplay";
import {IOrgUnit, useOrgunit} from "../hooks/useOrgunit";
import MainApplicationContext from "../context/MainApplicationContext";
import ErrorContext from "../context/ErrorContext";
import RoomManagerComponent from "../components/RoomManager/RoomManagerComponent";
import InventoryManagerComponent from "../components/InventoryManagerComponent";
import {InvType} from "../Utils/Enums";
import BookingLimitationComponent from "../components/BookingLimitaitonComponent";
import SeatBookingComponent from "../components/SeatBookingComponent";
import {useMailConfigList} from "../hooks/useMailConfigList";
import NotificationsComponent from "../components/NotificationsComponent";
import WarningSnackbar from "../components/WarningSnackbarComponent";
import ErrorBoundary from "../components/ErrorBoundary";


export const emptyUser: User = {
    ID: "id",
    name: "username",
    email: "",
    givenName: "",
    familyName: "",
    orgUnits: [],
    isAdmin: false,
    isOrgUnitAdmin: false
};

const MainPage: React.FC = () => {
    const [showOwnBookingsManager, setShowOwnBookingsManager] = useState(false)
    const [showNotificationDialog, setShowNotificationDialog] = useState(false)
    const [showRoomManager, setShowRoomManager] = useState(false)
    const [showDockingstationManager, setShowDockingstationManager] = useState(false)
    const [showMonitorManager, setShowMonitorManager] = useState(false)
    const [currentUser, setUser] = useState<User>(emptyUser);
    const [showBookingLimitation, setShowBookingLimitation] = useState(false)
    const [selectedDate, setSelectedDate] = useState(normalizeDate(new Date()));
    const [selectedRoom, setSelectedRoom] = useState<Room | undefined>(undefined);
    const [errors, setErrors] = useState<{ error: Error, customMessage: string }[]>([]);
    const [selectedOrgUnit, setSelectedOrgUnit] = useState<IOrgUnit | undefined>(undefined);

    const rooms = useRoomList(currentUser);
    const orgUnitList = useOrgunit(currentUser,rooms).orgUnitList;

    const orgUnits = currentUser.orgUnits;
    const inventories = useInventoryList();
    const mailConfig = useMailConfigList();
    //region DailyBookingsDisplay
    const orgUnitIds = orgUnits.map(o => o.orgId);
    const subscribeRooms = rooms.filter(r => orgUnitIds.includes(r.orgUnitId!));
    let orgBookingData = useBookingByDateAndOrgUnit(currentUser.orgUnits, dayjs(selectedDate).format('YYYY-MM-DD'), subscribeRooms);
    let sit2getherDailyBookingsDisplay: ReactElement<any, any> | null = DailyBookingsDisplay({
        orgBookingData,
        rooms,
        orgUnits,
        selectedRoom,
        selectedDate
    });

    if (!Array.isArray(orgUnitIds) || orgUnitIds.length === 0) sit2getherDailyBookingsDisplay = null;

    //endregion

    function handleChangeSelectedRoom(room : Room | undefined){
        setSelectedRoom(room)
    }
    function normalizeDate(date: Date): Date {
        return new Date(date.setHours(0, 0, 0, 0));
    }

    const onSelectedDateChange = useCallback((newlySelectedDate: Date): void => {
        setSelectedDate(normalizeDate(newlySelectedDate));
    }, [])

    const reportError = useCallback((error: Error, message?: string, source?: string) => {
        console.error(source, error);
        setErrors([...errors, {error: error, customMessage: message ? message : ""}])
    }, [errors]);

    const clearErrors = useCallback(() => {
        setErrors([]);
    }, [setErrors]);


    const signOut = useCallback(async () => {
        window.location.href = process.env.REACT_APP_LANDING_PAGE!;
        await Auth.signOut();
    }, [])

    const customSignOut = useCallback(() => {
        signOut().then(/*Ignored*/);
    }, [signOut])

    const updateUserCredentials = () => {
        UserClient.getCurrentUser()
            .then(cUser => setUser(cUser))
            .catch(() => customSignOut())
    }

    useEffect(() => {
        updateUserCredentials()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const providerValues = useMemo(() => ({
        orgUnitList,
        selectedOrgUnit,
        setSelectedOrgUnit,
        currentUser,
        rooms,
        mailConfig,
    }), [currentUser, mailConfig, orgUnitList, rooms, selectedOrgUnit]);

    return (
        <div style={{height: "inherit", display: "flex", flexDirection: "column"}}>
            <ErrorContext.Provider value={{reportError}}>
                <WarningSnackbar errors={errors}
                                 backgroundColor={'red'}
                                 horizontalAlignment={'left'}
                                 verticalAlignment={'bottom'}
                                 clearErrors={clearErrors}/>
                <HeaderComponent
                    setShowOwnBookingsManager={setShowOwnBookingsManager}
                    setShowRoomManager={setShowRoomManager}
                    setShowDockingstationManager={setShowDockingstationManager}
                    setShowMonitorManager={setShowMonitorManager}
                    setShowBookingLimitationManager={setShowBookingLimitation}
                    setShowNotificationDialog={setShowNotificationDialog}
                    currentUser={currentUser}
                    signOut={signOut}
                    sit2getherDailyBookingsDisplay={sit2getherDailyBookingsDisplay}
                />
                <div style={{display: "flex", flexGrow: 1, overflow: "hidden"}}>
                    <MainApplicationContext.Provider value={providerValues}>
                        <RoomManagerComponent
                            showRoomManager={showRoomManager}
                            setShowRoomManager={setShowRoomManager}

                        />
                        <ErrorBoundary>
                            <InventoryManagerComponent showInventoryManager={showDockingstationManager}
                                                       setShowInventoryManager={setShowDockingstationManager}
                                                       inventories={inventories.filter((item: Inventory) => item.type === InvType.Dockingstation)}
                                                       type={InvType.Dockingstation}

                            />
                        </ErrorBoundary>
                        <ErrorBoundary>
                            <InventoryManagerComponent showInventoryManager={showMonitorManager}
                                                       setShowInventoryManager={setShowMonitorManager}
                                                       inventories={inventories.filter((item: Inventory) => item.type === InvType.Monitor)}
                                                       type={InvType.Monitor}

                            />
                        </ErrorBoundary>
                        <ErrorBoundary>
                            <BookingLimitationComponent showBookingLimitations={showBookingLimitation}
                                                        setShowBookingLimitations={setShowBookingLimitation}
                            />
                        </ErrorBoundary>
                        <ErrorBoundary>
                            <SeatBookingComponent rooms={rooms.filter((room) => room.roomPlanExisting)}
                                                  currentUser={currentUser}
                                                  selectedDate={selectedDate}
                                                  onSelectedDateChange={onSelectedDateChange}
                                                  selectedRoom={selectedRoom}
                                                  onSelectedRoomChange={handleChangeSelectedRoom}/>
                        </ErrorBoundary>
                        <ErrorBoundary>
                            <OwnBookingsManagerComponent
                                showOwnBookingsManagerComponent={showOwnBookingsManager}
                                setShowOwnBookingsManagerComponent={setShowOwnBookingsManager}
                            />
                        </ErrorBoundary>
                        <ErrorBoundary>
                            <NotificationsComponent
                                showNotificationDialog={showNotificationDialog}
                                setShowNotificationDialog={setShowNotificationDialog}
                            />
                        </ErrorBoundary>
                    </MainApplicationContext.Provider>
                </div>
            </ErrorContext.Provider>
        </div>
    )
}
export default MainPage