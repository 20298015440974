import {FormControl, InputLabel, NativeSelect} from "@material-ui/core";
import {IOrgUnit} from "../../hooks/useOrgunit";
import React from "react";
import {Room} from "../../API";
import {useMainApplicationContext} from "../../hooks/useMainApplicationContext";
import {useTranslation} from "react-i18next";

interface Props {
    rooms: [] | Room []
}

const RoomManagerAdminForm: React.FC<Props> = (props) => {
    let {
        orgUnitList,
        selectedOrgUnit,
        setSelectedOrgUnit,
    } = useMainApplicationContext()

    let {rooms} = props

    const {t} = useTranslation();
    return (
        <FormControl style={{width: "100%"}}>
            <InputLabel shrink htmlFor="org-unit">
                {t("daily_bookings_table_orgunit_column")}
            </InputLabel>
            <NativeSelect
                value={selectedOrgUnit?.orgId}
                onChange={(e) => {

                    let orgUnit = orgUnitList.find(o => o.orgId === e.target.value);
                    setSelectedOrgUnit({
                        orgId: orgUnit !== undefined ? orgUnit.orgId : "",
                        orgName: orgUnit !== undefined ? orgUnit.orgName : "",
                        deleted: false
                    })
                }}
                data-testid={"select-org-unit"}
            >
                {orgUnitList.map((org: IOrgUnit) => {
                    return <option
                        key={org.orgId}
                        value={org.orgId}
                        data-testid={"org-dropdown-option-" + org.orgName}>
                        {org.orgName}
                    </option>
                })}
                {rooms.map((r: Room) => r.orgUnitId).includes("keine") &&
                    <option
                        key={"keine"}
                        value={"keine"}
                        data-testid={"org-dropdown-option-keine"}>
                        {"ohne Org-Einheit"}
                    </option>
                }
            </NativeSelect>
        </FormControl>
    )
}

export default RoomManagerAdminForm