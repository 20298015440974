import {Paper} from "@mui/material";
import React, {CSSProperties, useEffect, useRef, useState} from "react";
import {useRoomPlanContext} from "../../hooks/useRoomPlanContext";
import {Table, TableBody, TableCell, TableRow} from "@material-ui/core";
import "../../styles/styleRoomPlan.css"


interface Props {
    mousePosX: number,
    mousePosY: number,
    offset: number,
    styleCell: CSSProperties
}

const RoomPlanHoverDialog: React.FC<Props> = (props) => {
    const {
        mousePosX,
        mousePosY,
        offset,
        styleCell
    } = props

    const {
        itemsToDisplay
    } = useRoomPlanContext()

    const [elementPositionX, setElementPositionX] = useState(mousePosX);
    const [elementPositionY, setElementPositionY] = useState(mousePosY);
    const elementRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (elementRef.current) {
            if (window.innerWidth - offset < (mousePosX + elementRef.current.offsetWidth)) {
                setElementPositionX(window.innerWidth - elementRef.current.offsetWidth - offset)
            }
            if (window.innerHeight - offset < (mousePosY + elementRef.current.offsetHeight)) {
                setElementPositionY(window.innerHeight - elementRef.current.offsetHeight - offset)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Paper ref={elementRef} elevation={3} sx={{
            overflowWrap: 'anywhere',
            position: "absolute",
            left: elementPositionX,
            top: elementPositionY,
            backgroundColor: "white",
            pointerEvents: "none",
            whiteSpace: "nowrap"
        }}>
            <Table stickyHeader>
                <TableBody data-testid={"inventory-list"}>
                    {itemsToDisplay.length > 0 && itemsToDisplay.map((itemToDisplay, index) =>
                        <TableRow key={index}>
                            <TableCell style={styleCell}>
                                {itemToDisplay.icon}
                            </TableCell>
                            <TableCell style={styleCell}>
                                {itemToDisplay.text}
                            </TableCell>
                            <TableCell style={styleCell}>
                                {itemToDisplay.textOptional}
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Paper>

    );
}

export default RoomPlanHoverDialog;