import React, {useCallback, useEffect, useState} from "react";

interface Props {
    isRoomDropdownFocussed: boolean
    isOrgUnitDropdownFocused: boolean
    zoomContext: any
    roomPlanContainerRef: any
}

const RoomPlanPanningComponent: React.FC<Props> = (props) => {

    interface PanTarget {
        positionX: number | undefined
        positionY: number | undefined
    }

    const roomPlanContainerRef = props.roomPlanContainerRef;
    const zoomContext = props.zoomContext;
    const [panTarget, setPanTarget] = useState<PanTarget>({positionX: undefined, positionY: undefined})

    const panStart = (event: any) => {
        let roomPlanContainerWidth = roomPlanContainerRef.current ? roomPlanContainerRef.current.offsetWidth : 0;
        let roomPlanContainerHeight = roomPlanContainerRef.current ? roomPlanContainerRef.current.offsetHeight : 0;

        const isNotAllowedToMove = event.repeat || props.isRoomDropdownFocussed || props.isOrgUnitDropdownFocused;
        if (isNotAllowedToMove)
            return

        switch (event.key) {
            case "ArrowUp":
                setPanTarget((current) => {
                    return {
                        positionX: (current.positionX !== undefined ? current.positionX : zoomContext.current.state.positionX),
                        positionY: zoomContext.current.instance.bounds.maxPositionY + roomPlanContainerHeight
                    }
                })
                break;
            case "ArrowDown":
                setPanTarget((current) => {
                    return {
                        positionX: (current.positionX !== undefined ? current.positionX : zoomContext.current.state.positionX),
                        positionY: zoomContext.current.instance.bounds.minPositionY - roomPlanContainerHeight
                    }
                })
                break;
            case "ArrowLeft":
                setPanTarget((current) => {
                    return {
                        positionX: zoomContext.current.instance.bounds.maxPositionX + roomPlanContainerWidth,
                        positionY: (current.positionY !== undefined ? current.positionY : zoomContext.current.state.positionY)
                    }
                })
                break;
            case "ArrowRight":
                setPanTarget((current) => {
                    return {
                        positionX: zoomContext.current.instance.bounds.minPositionX - roomPlanContainerWidth,
                        positionY: (current.positionY !== undefined ? current.positionY : zoomContext.current.state.positionY)
                    }
                })
                break;
        }
    }

    const panStop = (event: any) => {
        if (event.key === "ArrowUp" || event.key === "ArrowDown") {
            setPanTarget((current) => {
                return {positionX: current.positionX, positionY: zoomContext.current.state.positionY}
            })
        } else if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
            setPanTarget((current) => {
                return {positionX: zoomContext.current.state.positionX, positionY: current.positionY}
            })
        }
    }

    const calcPanningAnimationSpeed = useCallback((targetX: number, targetY: number) => {
        const deltaX = targetX - zoomContext.current.state.positionX
        const deltaY = targetY - zoomContext.current.state.positionY
        const distance = Math.hypot(deltaX, deltaY)
        return Math.max(1, distance)
    }, [zoomContext])

    useEffect(() => {
        if (panTarget.positionX !== undefined && panTarget.positionY !== undefined) {
            zoomContext.current?.setTransform(panTarget.positionX, panTarget.positionY, zoomContext.current.state.scale,
                calcPanningAnimationSpeed(panTarget.positionX, panTarget.positionY));
        }
    }, [calcPanningAnimationSpeed, panTarget, zoomContext])

    useEffect(() => {
        document.addEventListener('keydown', panStart)
        document.addEventListener('keyup', panStop)

        return function cleanup() {
            document.removeEventListener('keydown', panStart);
            document.removeEventListener('keyup', panStop);
        }
    })

    return null;
}

export default RoomPlanPanningComponent;