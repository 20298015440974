const locales_de = {
    "button_notifications-title": "Benachrichtigungen",
    "signout": "Abmelden",
    "notification-dialog-title": "Benachrichtigungseinstellungen",
    "notification-dialog-hint": "Wähle an, für welche Aktionen du E-Mail-Benachrichtigungen erhalten möchtest",
    "notification-option-1": "Eigene Platzbuchungen als Outlook-Termin erhalten",
    "notification-option-2": "Von mir erstellte Fremdbuchungen als Outlook-Termin erhalten",
    "room_management_dialog-title": "Raumpläne verwalten",
    "room_public-active": "öffentlich",
    "seat_management_dialog-title": "Plätze verwalten",
    "seat_booking_error_no_rooms_configured": "Es sind derzeit keine Räume konfiguriert. Bitte wende dich an deine Office-Managerin.",
    "org_unit_no_org_selected": "OrgEinheit auswählen",
    "room_plan_no_room_selected": "Raumplan auswählen",
    "room_plan_no_room_available": "Kein Raumplan vorhanden",
    "room_name-column": "Name",
    "room-name": "Raumname",
    "room_plan-column": "SVG",
    "scaleFactor-column": "Skalierung",
    "room_active-column": "aktiv",
    "room_default-column": "Default",
    "room_time-active": "nach Zeit",
    "button_upload-text": "SVG Hochladen",
    "button_svg_delete-text": "SVG Löschen",
    "button_create_room-text": "Anlegen",
    "button_close_room_management_dialog-text": "Schließen",
    "message_error_file_type": "Datei ungültig!",
    "message_success_uploaded_file": "Datei wurde hochgeladen!",
    "message_error_create_room": "Ein Raum mit dem gleichen Namen und selber Org-Einheit existiert schon im System",
    "my_bookings_mngmt_dialog-title": "Meine Buchungen",
    "docking_mngmnt_dialog-title": "Dockingstations verwalten",
    "monitor_mngmnt_dialog-title": "Monitore verwalten",
    "booking_limitation_dialog_title": "Vorlauf-Konfiguration",
    "seat_configuration_dialog-title": "Konfguriere Platz",
    "docking_station-column": "Dockingstations",
    "monitor-column": "Monitore",
    "height-adjustable-column": "höhenverstellbar",
    "height-adjustable-checkbox-label": "Tisch ist höhenverstellbar",
    "select_monitor": "Monitor auswählen",
    "select_docking_station": "Dockingstation auswählen",
    "inv_mngmnt_close_dialog_button-text": "Schließen",
    "inv_mngmnt_add_button-text": "Hinzufügen",
    "inv_mngmnt_edit_button-text": "Editieren",
    "inv_mngmnt_delete_button-text": "Löschen",
    "confirm_dialog_ok_button-text": "Bestätigen",
    "confirm_dialog_cancel_button-text": "Abbrechen",
    "seats": "Plätze",
    "seat_info_dockingstations_title": "Dockingstations",
    "seat_info_monitors_title": "Monitore",
    "button_save": "Speichern",
    "button_close": "Schließen",
    "seat_name-column": "Platzbezeichner",
    "configure_seat": "Platz konfigurieren",
    "inv_mngmnt_error_create_inv": "Ein Inventar mit diesem Namen existiert bereits im System",
    "roomplan_removeseat_or_book_for_other_day_title": "Dieser Platz gehört dir an diesem Tag bereits.",
    "roomplan_removemeetingroom_or_book_for_other_day_title": "Dieser Raum gehört dir an diesem Tag bereits.",
    "roomplan_removeseat_or_book_for_other_day_infotext": "Möchtest du deine Buchung löschen oder ihn für weitere Tage buchen?",
    "button_delete_booking": "Buchung löschen",
    "button_book_other_days": "Andere Tage buchen",
    "open_another_booking_dialog": "Andere Tagen/Zeiten buchen",
    "multibookingdialog_textfield_label_booking_for": "Buchung für",
    "multibookingdialog_dropdown_label_booking_for": "Buchung für",
    "multibookingdialog_legend_title": "Legende",
    "multibookingdialog_legend_own_booking": "Platz gebucht durch mich",
    "multibookingdialog_legend_no_booking": "Platz frei",
    "multibookingdialog_meetingroom_legend_own_booking": "Raum gebucht durch mich",
    "multibookingdialog_meetingroom_legend_no_booking": "Raum frei",
    "multibookingdialog_legend_booking_available": "Nur bestimmte Zeitfenster verfügbar",
    "multibookingdialog_legend_other_user": "Platz nicht verfügbar",
    "multibookingdialog_meetingroom_legend_other_user": "Raum nicht verfügbar",
    "multibookingdialog_legend_marked": "Zum Buchen markierte Tage",
    "multibookingdialog_title_normal": "Zu buchende Tage",
    "multibookingdialog_title_book_other_days": "Andere Tage wählen",
    "multibookingdialog_error_booking_not_complete": "Es konnten nicht alle ausgewählten Tage gebucht werden. Die Auswahl der Tage wurde entsprechend angepasst. Bitte erneut versuchen.",
    "multibookingdialog_error_seat_already_taken": "Der Platz ist an diesem Tag bereits belegt.",
    "multibookingdialog_meetingroom_error_seat_already_taken": "Der Raum ist in diesem Zeitfenster bereits belegt.",
    "multibookingdialog_error_max_booking_days_exceeded": "Vorausbuchung nur möglich bis zum ",
    "multibookingdialog_error_max_bookable_days_": "Maximale Anzahl buchbarer Tage erreicht, Platzbuchung an weiteren Tagen ist nicht mehr möglich.",
    "multibookingdialog_meetingroom_error_max_bookable_days_": "Maximale Anzahl buchbarer Tage erreicht, Raumbuchung an weiteren Tagen ist nicht mehr möglich.",
    "multibookingdialog_dropdown_booking_for_me": "Buchung für mich selbst",
    "multibookingdialog_dropdown_booking_for_others": "Fremdbuchung",
    "multibookingdialog_error_parallel_booking": "Ein anderer Nutzer hat den Platz gerade am %s gebucht. Der Tag ist daher nicht mehr buchbar.",
    "multibookingdialog_error_parallel_bookings": "Ein anderer Nutzer hat den Platz gerade an den Tagen %s gebucht. Die Tage sind daher nicht mehr buchbar.",
    "multibookingdialog_meetingroom_error_parallel_booking": "Ein anderer Nutzer hat den Raum gerade am %s gebucht. Der Tag ist daher nicht mehr buchbar.",
    "multibookingdialog_meetingroom_error_parallel_bookings": "Ein anderer Nutzer hat den Raum gerade an den Tagen %s gebucht. Die Tage sind daher nicht mehr buchbar.",
    "multibookingdialog_time_window_title": "Freie Zeitfenster",
    "multibookingdialog_meetingroom_settings_title": "Einstellungen",
    "multibookingdialog_meetingroom_settings_meeting_name": "Besprechungsraum",
    "multibookingdialog_meetingroom_settings_participant_number": "Teilnehmerzahl",
    "multibookingdialog_meetingroom_settings_participant_number_err_1": "Teilnehmeranzahl soll zwischen 1 und ",
    "multibookingdialog_meetingroom_settings_participant_number_err_2": " liegen",
    "multibookingdialog_time_window_no_common_time_slot": "Kein gemeinsames freies Zeitfenster an den ausgewählten Tage.",
    "multibookingdialog_error_time_slot_not_bookable": "Ausgewähltes Zeitfenster ist nicht buchbar.",
    "button_book_other_days_times": "Andere Tage/Zeiten buchen",
    "multibookingdialog_meetingroom_booking_type_internal": "Internal",
    "multibookingdialog_meetingroom_booking_type_external": "External",
    "multibookingdialog_meetingroom_booking_type_vip": "VIP",
    "book": "Buchen",
    "cancel": "Abbrechen",
    "owner-column": "Platzbesitzer",
    "select_user": "Nutzer auswählen",
    "no_owner": "Kein Besitzer",
    "no_user": "Kein Nutzer",
    "other_user": "Nicht-2Gether-Nutzer",
    "apply": "Übernehmen",
    "seat-adjustable-height-hover": "Höhenverstellbar",
    "expand-seat-filters": "Filter ausklappen",
    "collapse-seat-filters": "Filter einklappen",
    "delete_room_dialog_content": "Wirklich löschen?",
    "jsLocalDateProperty": "de-DE",
    "date": "Datum",
    "delete": "Löschen",
    "roomplan": "Raumplan",
    "seat": "Platz",
    "own_bookings_management_dialog_title": "Meine Buchungen",
    "own_bookings_management_alert_dialog_title": "Ausgewählte Buchungen wirklich löschen?",
    "own_bookings_management_alert_dialog_amount_bookings": "Anzahl ausgewählter Buchungen",
    "timeWindow": "Zeitfenster",
    /*RoomManager*/
    "rm_showUploadConfirmationDialog_cancel_button-text": "Abbrechen",
    "rm_showUploadConfirmationDialog-text": "Die folgenden Plätze sind nicht im neuen Raumplan vorhanden, Buchungen für diese Plätze gehen verloren: ",
    "rm_showUploadConfirmationDialog_refresh_button-text": "Aktualisieren",
    /*DailyBookingsDisplay*/
    "daily_bookings_table_bookingname_column": "Name",
    "daily_bookings_table_room_column": "Raumplan",
    "daily_bookings_table_seat_column": "Sitzplatz",
    "daily_bookings_table_orgunit_column": "Org-Einheit",
    "daily_bookings_empty_list_message": "Keine Buchungen am ausgewählten Tag vorhanden",
    "daily_bookings_count_display_text": "Buchungen: ",
    "booking-error_title": "Buchungsfehler",
    "default_error": "Bei deiner Anfrage ist etwas schiefgelaufen.",
    "booking_error": "Bei deiner Buchung ist etwas schiefgelaufen.",
    "error_collection": "Aufgetretene Fehler",
    "error_message": "Fehlernachricht",
    "custom_error_message": "Benutzerdefinierte Nachricht",
    "stack_trace": "Stack Trace",
    "max_bookable_days": "Max. Anzahl buchbarer Tage",
    "max_lead_time": "Max. zeitlicher Vorlauf in Tagen",
    "Booking_day": "Buchungen am",
    "minutes": "Minuten",
    "message_disable_time_bookings": "Es gibt Plätze mit mehreren Buchungen an einem Tag. In solchen Fällen wird die früheste Buchung zur Ganztagesbuchung und die weiteren Buchungen am selben Tag werden gelöscht. Ingesamt würden %number Buchungen gelöscht. Wirklich ändern?",
    "message_disable_time_booking": "Es gibt Plätze mit mehreren Buchungen an einem Tag. In solchen Fällen wird die früheste Buchung zur Ganztagesbuchung und die weiteren Buchungen am selben Tag werden gelöscht. Ingesamt wird eine Buchung gelöscht. Wirklich ändern?",
    'error-boundary-default-error': 'Etwas ist schief gelaufen.'

}
export default locales_de;