import {Button} from "@material-ui/core";
import {Booking, MeetingRoomBooking} from "../../../API";
import dayjs from "dayjs";
import DeleteIcon from "@material-ui/icons/Delete";
import React, {useEffect, useState} from "react";
import {MeetingRoomBookings} from "../../../types/MeetingRoomBookingType";
import {BookingType} from "../../../types/BookingType";
import {useTranslation} from "react-i18next";

interface Props {
    deletableBookings: (Booking | MeetingRoomBooking)[]
    handleDeleteBooking: (bookingId: string) => void
    bookingType: BookingType,
    meetingRoomBookingProps?: MeetingRoomBookings | null
}

const DeleteBookingButtons: React.FC<Props> = (props) => {
    const {
        deletableBookings,
        handleDeleteBooking
    } = props
    const [sortedBookings, setSortedBookings] = useState<(Booking | MeetingRoomBooking)[]>([])
    const {t} = useTranslation();

    useEffect(function sortBookings() {
        const tempBookingList = [...deletableBookings]
        tempBookingList.sort((a, b) => {
            return (a.timeBegin || "") < (b.timeBegin || "") ? -1 : 1
        })
        setSortedBookings(tempBookingList)
    }, [deletableBookings])

    function formatDate(date: string) {
        return dayjs(date).format("HH:mm")
    }

    function getButtonText(booking: Booking | MeetingRoomBooking) {
        if (booking.__typename === "MeetingRoomBooking") return getMeetingText(booking)
        else return getSeatText(booking)

        function getSeatText(booking: Booking) {
            if (booking.timeBegin) {
                return formatDate((booking.timeBegin || "")) + "\u202F\u2013\u202F" + (formatDate((booking.timeEnd ?? "")));
            } else {
                return t("button_delete_booking");
            }
        }

        function getMeetingText(booking: MeetingRoomBooking) {
            return booking.meetingName + " (" + formatDate((booking.timeBegin ?? "")) + "\u202F\u2013\u202F" + (formatDate((booking.timeEnd ?? ""))) + " )";
        }
    }


    return <>
        {
            sortedBookings.map((booking) =>
                <Button className={"SeatAlreadyBookedHintButton"}
                        key={booking.bookingId}
                        onClick={() => handleDeleteBooking(booking.bookingId)}
                        data-testid={"already-booked-delete-booking"}
                        color={"secondary"}
                        variant={"outlined"}
                        size="small"
                        endIcon={<DeleteIcon/>}
                >
                    {getButtonText(booking)}
                </Button>)

        }
    </>

}

export default DeleteBookingButtons