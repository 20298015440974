import React, {useEffect, useState} from "react";
import {Room} from "../API";
import {DropdownList} from "react-widgets";
import {Calendar} from "react-multi-date-picker"

import "react-widgets/styles.css";
import "react-calendar/dist/Calendar.css";
import gregorian_de from "../locales/calendar_de";
import {Button, ClickAwayListener, makeStyles} from "@material-ui/core";
import CollapsibleCalendarComponent from "./CollapsibleCalendarComponent";
import {ChevronLeft, ChevronRight, KeyboardArrowDown} from "@mui/icons-material";
import colors from "../styles/colors";
import shadows from "../styles/shadows";
import borders from "../styles/borders";
import uiElementMeasures from "../styles/inputElementMeasures";
import {Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import i18n from "../i18n";
import gregorian_en from "../locales/calender_en";
import {useMainApplicationContext} from "../hooks/useMainApplicationContext";
import {IOrgUnit} from "../hooks/useOrgunit";
import {useFilterContext} from "../hooks/useFilterContext";
import {emptyUser} from "../pages/MainPage";

interface Props {
    rooms: Room[]
    selectedDate: Date
    selectedRoom: Room | undefined
    setSelectedDate: (newDate: Date) => void
    onSelectedRoomChange: (newSelectedRoom: Room | undefined) => void
}

const RoomPickerComponent: React.FC<Props> = (props) => {
    const {
        rooms,
        selectedDate,
        selectedRoom,
        setSelectedDate,
        onSelectedRoomChange,
    } = props;

    const [showCalendar, setShowCalendar] = useState<boolean>(false);
    const [selectedOrgUnit,setSelectedOrgUnit] = useState<IOrgUnit | undefined>(undefined)
    const [accessibleOrgUnits,setAccessibleOrgUnits] = useState<IOrgUnit[]>([])
    const [initiallyLoaded, setInitiallyLoaded] = useState<boolean>(false)
    const today: Date = new Date()
    today.setHours(0, 0, 0, 0)
    const {
        orgUnitList,
        currentUser
    } = useMainApplicationContext();
    const {
        isRoomDropdownFocussed,
        setIsRoomDropdownFocussed,
        isOrgUnitDropdownFocused,
        setIsOrgUnitDropdownFocused
    } = useFilterContext();

    const useStyles = makeStyles({
        divCalendarContainer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginBottom: uiElementMeasures.marginBetweenElementsInColumn,
            boxShadow: shadows.innerInputElementShadow,
            border: borders.innerInputElementBorder,
            borderRadius: borders.mediumBorderRadius,
            minWidth: "40px",
            color: colors.iconAndTextGrey,
        },
        divCalendar: {
            fontSize: "12px",
            marginBottom: "5px"
        },
        calender: {
            "&.rmdp-wrapper": {
                width: "auto",
                display: "flex",
                justifyContent: "center",
                boxShadow: "0 0",
            },
        },
        changeDateButton: {
            minWidth: "40px",
            color: colors.iconAndTextGrey,
        },
        '@global': {
            ".rw-widget-input": {
                borderRadius: borders.mediumBorderRadius,
                boxShadow: shadows.innerInputElementShadow,
                borders: borders.innerInputElementBorder,
                minHeight: uiElementMeasures.inputElementsInRoomPickerMinHeight,
            }
        }
    });

    function hasRoom(orgUnit : IOrgUnit){
        return getAllRoomsForOrgUnit(orgUnit).length !== 0
    }

    function hasActiveRoom(orgUnit : IOrgUnit) : boolean {
        return getAllActiveRoomsForOrgUnit(orgUnit).length !== 0
    }

    function hasPublicRoom(orgUnit : IOrgUnit) : boolean {
        return getAllRoomsForOrgUnit(orgUnit).some(room => room.isPublic)
    }

    function getAllRoomsForOrgUnit(orgUnit : IOrgUnit){
        return rooms
            .filter(room => room.orgUnitId === orgUnit.orgId)
    }

    function getAllActiveRoomsForOrgUnit(orgUnit : IOrgUnit){
        return rooms
            .filter(room => room.orgUnitId === orgUnit.orgId)
            .filter(room => room.isActive)

    }

    function chooseDefaultOrgUnit() : void{

        //First consider the orgUnits that the user has assigned
        let usersAccessibleOrgUnits = accessibleOrgUnits
            .filter(orgUnit => currentUser.orgUnits.some(userOrgUnit => userOrgUnit.orgId === orgUnit.orgId))

        if(usersAccessibleOrgUnits.length !==0){
            setSelectedOrgUnit(usersAccessibleOrgUnits[0])
            return;
        }

        //If there is no such orgUnit, turn to the orgUnits that the user is not a part of
        let publicOrgUnits = accessibleOrgUnits
            .filter(orgUnit => !currentUser.orgUnits.some(userOrgUnit => userOrgUnit.orgId === orgUnit.orgId))
            .filter(orgUnit => hasPublicRoom(orgUnit))

        if(publicOrgUnits.length !== 0) {
            setSelectedOrgUnit(publicOrgUnits[0])
            return
        }


        setSelectedOrgUnit(undefined)

    }

    const classes = useStyles();

    const handleChange = (date: any) => {
        const newDate = new Date(date.toString())
        if (newDate) newDate.setHours(0, 0, 0, 0)
        setSelectedDate(newDate)
    }

    const isSameDay = (first: Date, second: Date) =>
        first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate();

    const filterAndSortRooms = () : Room[] =>{
        return rooms
            .filter(room => room.orgUnitId === selectedOrgUnit?.orgId)
            .sort((room1,room2) => room1.name.localeCompare(room2.name))
    }

    const filterAccessibleOrgUnits = ()  => {
        //Admins should be able to see even orgUnits with non-active rooms, regular users only active ones
        if(currentUser.isAdmin){
            setAccessibleOrgUnits(orgUnitList.filter(orgUnit => hasRoom(orgUnit)))
        }
        else {
            setAccessibleOrgUnits(orgUnitList.filter(orgUnit => hasActiveRoom(orgUnit)))
        }
        }


    function setDayStyle_Selected(calendarProps: { style: {}; disabled: boolean }) {
        calendarProps.style = {
            ...calendarProps.style,
            color: "white",
        }
    }

    function setDayStyle_Weekend(calendarProps: { style: {}; disabled: boolean }) {
        calendarProps.style = {
            ...calendarProps.style,
            color: "red"
        }
    }

    function createCalendarProps(dateJS: Date) {
        let calendarProps = {
            style: {
                borderRadius: "4px",
            },
            disabled: false
        }
        if (dateJS.getDay() === 0 || dateJS.getDay() === 6) {
            setDayStyle_Weekend(calendarProps);
        }
        if (isSameDay(selectedDate, dateJS)) {
            setDayStyle_Selected(calendarProps)
        }

        return calendarProps;
    }

    function handleCloseDropdowns() {
        setIsOrgUnitDropdownFocused(false)
        setIsRoomDropdownFocussed(false)
    }

    function handleOnSelectOfOrgUnitSelector(selection: string) {
        setIsOrgUnitDropdownFocused(false);
        setSelectedOrgUnit(accessibleOrgUnits.find(o => o.orgName === selection))
    }

    function handleOnSelectOfRoomSelector(selection: undefined | Room) {
        setIsRoomDropdownFocussed(false)
        onSelectedRoomChange(selection)
    }

    useEffect(() => {
        if(currentUser.ID !== emptyUser.ID) {
            setInitiallyLoaded(true)
        }
    }, [currentUser]);

    useEffect(() => {
        //Once the real user has been fetched, preselect an orgUnit
        chooseDefaultOrgUnit()
    }, [initiallyLoaded]);


    useEffect(() => {
        filterAccessibleOrgUnits()
    }, [orgUnitList,rooms]);

    useEffect(() => {
        //If the currently selected orgUnit no longer has an accessible room, reselect the default orgUnit
        if(!accessibleOrgUnits.some(orgUnit => orgUnit.orgId === selectedOrgUnit?.orgId)) {
            chooseDefaultOrgUnit()
        }
    }, [orgUnitList,rooms]);


    useEffect(() => {
        // If there is a default room configured, choose it, otherwise select the first one in the list
        let filteredRooms = filterAndSortRooms()
        let possibleDefaultRoom = filteredRooms.find(room => room.isDefault && room.isActive)
        handleOnSelectOfRoomSelector(possibleDefaultRoom ? possibleDefaultRoom : filteredRooms[0])
    }, [selectedOrgUnit,rooms.length]);

    const {t} = useTranslation();
    const localisation = i18n.language.substring(0,2) === 'de' ? gregorian_de : gregorian_en;
    return (
        <>
            <div className={classes.divCalendarContainer}>
                <CollapsibleCalendarComponent
                    showCalendar={showCalendar}
                    setShowCalendar={setShowCalendar}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                ></CollapsibleCalendarComponent>
                {showCalendar && (
                    <div className={classes.divCalendar}>
                        <Calendar
                            className={classes.calender}
                            value={selectedDate}
                            locale={localisation}
                            weekStartDayIndex={1}
                            onChange={handleChange}
                            mapDays={({date}) => {
                                const dateJS = new Date(date.toString())
                                return createCalendarProps(dateJS);
                            }}
                            renderButton={(direction: 'left' | 'right', handleClick: (() => void)) => CustomButton(direction, handleClick, classes.changeDateButton)}
                        />
                    </div>
                )}
            </div>
            <ClickAwayListener onClickAway={() => handleCloseDropdowns()}>
                <div>
                    <Box style={{marginBottom: uiElementMeasures.marginBetweenElementsInColumn}}>
                        <DropdownList
                            style={{textAlign: 'left'}}
                            selectIcon={<KeyboardArrowDown fontSize={"small"} style={{color: colors.iconAndTextGrey}}/>}
                            data-testid={"orgUnitSelector"}
                            open={isOrgUnitDropdownFocused}
                            data={accessibleOrgUnits.map((org: IOrgUnit) => org.orgName)}
                            dataKey="orgId"
                            textField="orgName"
                            placeholder={t('org_unit_no_org_selected')}
                            value={selectedOrgUnit}
                            onSelect={handleOnSelectOfOrgUnitSelector}
                            onClick={() => setIsOrgUnitDropdownFocused(prev => !prev)}
                            onMouseLeave={() => setIsOrgUnitDropdownFocused(false)}
                        />
                    </Box>
                    <Box>
                        <DropdownList
                            style={{textAlign: 'left'}}
                            selectIcon={<KeyboardArrowDown fontSize={"small"} style={{color: colors.iconAndTextGrey}}/>}
                            data-testid={"roomSelector"}
                            data={filterAndSortRooms()}
                            open={selectedRoom !== undefined && isRoomDropdownFocussed}
                            dataKey="roomId"
                            textField="name"
                            placeholder={t('room_plan_no_room_selected')}
                            value={selectedRoom ? selectedRoom.name :  t('room_plan_no_room_available')}
                            onSelect={handleOnSelectOfRoomSelector}
                            onClick={() => setIsRoomDropdownFocussed(prev => !prev)}
                            onMouseLeave={() => setIsRoomDropdownFocussed(false)}
                        />
                    </Box>
                </div>
            </ClickAwayListener>
        </>
    );
};
export default RoomPickerComponent;


function CustomButton(direction: 'left' | 'right', handleClick: (() => void), className: string) {
    return (
        <i onClick={handleClick} style={{
            padding: "0 10px",
            fontWeight: "bold",
            color: "blue"
        }}>
            <Button className={className} data-testid={"back-button"}>
                {direction === "right" ? <ChevronRight/> : <ChevronLeft/>}
            </Button>
        </i>
    )
}