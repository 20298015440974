import React, {CSSProperties, useCallback, useEffect, useMemo, useRef, useState} from "react";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Button from "@material-ui/core/Button"
import Collapse from "@material-ui/core/Collapse"
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import 'react-calendar/dist/Calendar.css';
import "../../styles/MultiBookingDialog.css";
import {DateObject} from "react-multi-date-picker";
import {getCognitoIdTokenFromLocalStorage} from "../../Utils/Helpers";
import {
    getAllTimeBookingsForCurrentSeatAndDate,
    getGreatestBookableDate,
    handleLimitOfBookings,
    isDateSelected
} from "../MultiBookingDialog/MultiBookingDialogHelper";
import {Booking, MeetingRoomBooking, Room} from "../../API";
import {BookingConfigDays} from "../../types/BookingConfigDaysTypes";
import {useBookingBySeatId} from "../../hooks/useBookingBySeatId";
import {gql, useQuery} from "@apollo/client";
import {bookingByBookerAndDate} from "../../graphql/queries";
import MultiBookingLegendComponent, {
    MultiBookingLegendComponentProps
} from "../MultiBookingDialog/MultiBookingLegendComponent";
import {defaultOrgUnitId} from "../../Utils/commons";
import MultiBookingCalendarComponent, {
    MultiBookingCalendarComponentProps
} from "../MultiBookingDialog/MultiBookingCalendarComponent";
import MultiBookingDialogTitle, {MultiBookingDialogTitleProps} from "../MultiBookingDialog/MultiBookingDialogTitle";
import MultiBookingDialogNameField, {
    MultiBookingDialogNameFieldProps
} from "../MultiBookingDialog/MultiBookingDialogNameField";
import {createNewTodayDateWithoutHours, isGreaterThanDate, isLowerThanDate, toDateISO} from "../../services/DateUtils";
import MultiBookingDialogBookingButton, {
    MultiBookingDialogBookingButtonProps
} from "../MultiBookingDialog/MultiBookingDialogBookingButton";
import {BookingFor, MeetingType} from "../../Utils/Enums";
import dayjs, {Dayjs} from "dayjs";
import duration from "dayjs/plugin/duration";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {renderTimeViewClock, TimePicker} from "@mui/x-date-pickers";
import TimeWindowTableComponent, {TimeWindowTableComponentProps} from "../MultiBookingDialog/TimeWindowTableComponent";
import customParseFormat from 'dayjs/plugin/customParseFormat'
import MultiBookingDialogContext from "../../context/MultiBookingDialogContext";
import MeetingRoomBookingSettings, {
    MeetingRoomBookingSettingsProps
} from "../MultiBookingDialogMeetingRoom/MeetingRoomBookingSettings";
import {useTranslation} from "react-i18next";
import {CustomTextFieldLeft, CustomTextFieldRight} from "./CustomTextField";
import {TimePickerProps} from "@mui/x-date-pickers/TimePicker/TimePicker.types";
import {BookingType} from "../../types/BookingType";
import {useBookingByMeetingRoomId} from "../../hooks/useBookingByMeetingRoomId";

dayjs.extend(customParseFormat)
dayjs.extend(duration);

export interface BookingDialogProps {
    bookingType: BookingType
    handleClose: () => void
    dateSelectedInCalendar: Date
    bookerName: string
    bookerGivenName: string
    bookerFamilyName: string
    seatId: string
    room: Room
    roomCap: number
    selectedSeatIsBookedOnSelectedDay: boolean
    selectedSeatIsBookedByMeOnSelectedDay?: boolean
    bookingConfig: BookingConfigDays
    bookingList: (Booking | MeetingRoomBooking)[] | [],
}

//TODO booking button for seat is disabled. maybe because meeting room settings are empty
export const BookingDialog = (bookingDialogProps: BookingDialogProps) => {
    const {
        bookingType,
        handleClose,
        dateSelectedInCalendar, //currently used date in calendar
        bookerName,
        bookerGivenName,
        bookerFamilyName,
        seatId,
        room,
        selectedSeatIsBookedOnSelectedDay,
        selectedSeatIsBookedByMeOnSelectedDay,
        bookingConfig,
        bookingList,
        roomCap,
    } = bookingDialogProps

    //TODO maybe use reducer
    //TODO: 2 groups of states. One group is for shared states and two for bookingType specific groups
    const mounted = useRef(false);
    const [bookable, setBookable] = useState(false);
    const [isSeatAlreadyTaken, setIsSeatAlreadyTaken] = useState(false);
    const [isTimeActiveInputBookable, setIsTimeActiveInputBookable] = useState(true);
    const [showBookingDidNotComplete, setShowBookingDidNotComplete] = useState(false);
    const [isBookingLimitInDaysReached, setIsBookingLimitInDaysReached] = useState(false)
    const [isParallelBookedByOtherUsers, setIsParallelBookedByOtherUsers] = useState(false);
    const [isGreaterThanGreatestBookableDate, setIsGreaterThanGreatestBookableDate] = useState(false);
    const [isMaxBookableDaysWithSelectionReached, setIsMaxBookableDaysWithSelectionReached] = useState(false)

    const [bookedDays, setBookedDays] = useState<Date[]>([])
    const [endTime, setEndTime] = useState<Dayjs | null>(getDefaultTimes()[1])
    const [beginTime, setBeginTime] = useState<Dayjs | null>(getDefaultTimes()[0])
    const [selectedDates, setSelectedDates] = useState<Date[]>([dateSelectedInCalendar])
    const [parallelBookedDatesByOtherUsers, setParallelBookedDatesByOtherUsers] = useState<Date[]>([]);
    const [greatestBookableDate, setGreatestBookableDate] = useState<Date>(getGreatestBookableDate(bookingConfig));
    const [currentMonth, setCurrentMonth] = useState(new Date(dateSelectedInCalendar.getFullYear(), dateSelectedInCalendar.getMonth(), 1).toString())

    const [meetingName, setMeetingName] = useState<string>("");
    const [bookingForInputUsername, setBookingForInputUsername] = useState<string>("")

    const [participantNumber, setParticipantNumber] = useState<number>(0);

    const [bookingForState] = useState(BookingFor.BOOKING_FOR_ME)
    const [meetingType, setMeetingType] = useState<MeetingType>(MeetingType.INTERNAL);

    const maxParticipantNumber = roomCap;
    const today: Date = createNewTodayDateWithoutHours()
    const isSeatDialog = bookingType === 'seat';
    const isMeetingDialog = bookingType === 'meetingRoom';

    const bookingByMeetingRoomId = useBookingByMeetingRoomId(
        room.roomId, room.orgUnitId ? room.orgUnitId : defaultOrgUnitId, currentMonth, seatId)
    const bookingBySeatId = useBookingBySeatId(
        room.roomId, room.orgUnitId ? room.orgUnitId : defaultOrgUnitId, currentMonth, seatId)
    const mergedBookingList: (Booking | MeetingRoomBooking)[] = useMemo(() => {
        return isSeatDialog ? bookingBySeatId : bookingByMeetingRoomId
    }, [isSeatDialog, bookingByMeetingRoomId, bookingBySeatId])



    const {refetch: refetchBookingByBookerAndDate} = useQuery(gql(bookingByBookerAndDate), {})
    const MultiBookingDialogProviderValues = useMemo(() => ({
        currentMonth,
        setCurrentMonth
    }), [currentMonth, setCurrentMonth]);


    function isBeforeOrSame(bookingEnd: Dayjs, beginTime: Dayjs | null) {
        return (bookingEnd.isBefore(beginTime) || bookingEnd.isSame(beginTime))
    }

    function isAfterOrSame(bookingBegin: Dayjs, endTime: Dayjs | null) {
        return (bookingBegin.isAfter(endTime) || bookingBegin.isSame(endTime))
    }

    function getDefaultTimes() {
        const defaultStart = dayjs("08:00", "HH:mm").millisecond(0);
        const defaultEnd = dayjs("17:00", "HH:mm").millisecond(0);

        const seatBookings = bookingList
            .filter(booking => booking.__typename === "Booking")
            .map(booking => booking as Booking)
            .filter(booking => booking.seatId === seatId)
        const doesBookingOverlapWithDefaultTimes = (booking: Booking) => {

            const timeBegin = dayjs(booking.timeBegin, "YYYY-MM-DDTHH:mm:ss.SSSZ").millisecond(0);
            const timeEnd = dayjs(booking.timeEnd, "YYYY-MM-DDTHH:mm:ss.SSSZ").millisecond(0);
            const dayDefaultStart = timeBegin.set('hour', 8);
            const dayDefaultEnd = timeEnd.set('hour', 17);

            return ((booking.timeBegin && booking.timeEnd) &&
                (
                    (isAfterOrSame(timeBegin, dayDefaultStart) && timeBegin.isBefore(dayDefaultEnd)) ||
                    (timeEnd.isAfter(dayDefaultStart) && isBeforeOrSame(timeEnd, dayDefaultEnd)) ||
                    (isAfterOrSame(timeEnd, dayDefaultEnd) && isBeforeOrSame(timeBegin, dayDefaultStart))
                ))
        }

        return seatBookings.some(doesBookingOverlapWithDefaultTimes) ? [null, null] : [defaultStart, defaultEnd];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }


    const [isInvalidBookingTime, setIsInvalidBookingTime] = useState(selectedSeatIsBookedOnSelectedDay);//ist uhrzeit gültig (ende nach Beginn)
    const handleStartTime = (newValue: Dayjs | null) => { // millisecond set to 0 because of possible bug in @mui/x-date-pickers
        (newValue != null) ? setBeginTime(newValue.millisecond(0)) : setBeginTime(newValue)
    }

    const handleEndTime = (newValue: Dayjs | null) => { // illisecond set to 0 because of possible bug in @mui/x-date-pickers
        (newValue != null) ? setEndTime(newValue.millisecond(0)) : setEndTime(newValue)
    }

    const isWithinSelectedTime = useCallback((booking: Booking | MeetingRoomBooking): boolean => {
        const bookingBegin: Dayjs = dayjs(booking.timeBegin)
        const bookingEnd: Dayjs = dayjs(booking.timeEnd)
        return !(isBeforeOrSame(bookingEnd, beginTime) || isAfterOrSame(bookingBegin, endTime))
    }, [beginTime, endTime])

    useEffect(function removeUnavailableButSelectedDates() {
        let arr = [...selectedDates];

        arr = arr.filter((_date: Date) => isAnyTimeslotBookable(_date) || isDateBookedByCurrentUser(_date))
        if (isMaxBookableDaysWithSelectionReached && !isDateBookedByCurrentUser(dateSelectedInCalendar))
            arr = arr.filter((_date: Date) => _date !== dateSelectedInCalendar)

        setSelectedDates(arr)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isBookingLimitInDaysReached,
        isGreaterThanGreatestBookableDate,
        isMaxBookableDaysWithSelectionReached,
        bookingConfig,
        parallelBookedDatesByOtherUsers
    ])

    useEffect(function setComponentMounted() {
        mounted.current = true; // Will set it to true on mount ...
        return () => {
            mounted.current = false;
        }; // ... and to false on unmount
    }, []);

    useEffect(function updateSelectedDatesIfDateIsStillBookable() {
        if (isAnyTimeslotBookable(dateSelectedInCalendar) || selectedSeatIsBookedByMeOnSelectedDay)
            setSelectedDates([dateSelectedInCalendar])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(function checkIsSeatAlreadyTakenOnComponentLoad() {
        setIsSeatAlreadyTaken(selectedSeatIsBookedOnSelectedDay && !selectedSeatIsBookedByMeOnSelectedDay)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(function updateMyBookedDaysOnLoad() {
        if (mounted.current)
            updateMyBookedDays().then((response) => {
                if (bookingConfig) {
                    handleLimitOfDaysInAdvance()
                    setIsMaxBookableDaysWithSelectionReached(handleLimitOfBookings(false, response, selectedDates, bookingConfig))
                    setIsBookingLimitInDaysReached(handleLimitOfBookings(true, response, selectedDates, bookingConfig))
                }
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(function removeBookedSeat() {
        if (bookingConfig) {
            handleLimitOfDaysInAdvance()
            setIsMaxBookableDaysWithSelectionReached(handleLimitOfBookings(false, bookedDays, selectedDates, bookingConfig))
            setIsBookingLimitInDaysReached(handleLimitOfBookings(true, bookedDays, selectedDates, bookingConfig))
        }
        setIsSeatAlreadyTaken(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateSelectedInCalendar, selectedDates])

    const updateMyBookedDays = useCallback(async () => {
        const bookerId = getCognitoIdTokenFromLocalStorage();
        const bookingsByBooker = await refetchBookingByBookerAndDate({
            bookerId: bookerId,
            date: {between: [toDateISO(today), toDateISO(greatestBookableDate)]}
        })
        let mapOfBookedDays = bookingsByBooker.data.bookingByBookerAndDate.items
            .map((item: any) => item.date)
            .filter((value: any, index: any, self: any) => self.indexOf(value) === index)
            .map((value: string) => new Date(value))

        setBookedDays([...mapOfBookedDays])

        return [...mapOfBookedDays]
    }, [greatestBookableDate, refetchBookingByBookerAndDate, today])

    const getBookingsForDate = useCallback((date: Date): (Booking | MeetingRoomBooking)[] => {
        return mergedBookingList.filter((booking: Booking | MeetingRoomBooking) => {
            return booking.date === toDateISO(date) && isWithinSelectedTime(booking)
        })
    }, [mergedBookingList, isWithinSelectedTime])

    const isDateBookedByCurrentUser = useCallback((date: Date): boolean => {
        const bookings = getBookingsForDate(date)
        if (!bookings || bookings.length === 0) return false
        return bookings.some((booking: Booking | MeetingRoomBooking) => booking.bookerId === getCognitoIdTokenFromLocalStorage());
    }, [getBookingsForDate])

    const isDateBookedByAnotherUser = useCallback((date: Date): boolean => {
        const bookings = getBookingsForDate(date)
        if (!bookings || bookings.length === 0) return false
        return bookings.some((booking: Booking | MeetingRoomBooking) => booking.bookerId !== getCognitoIdTokenFromLocalStorage())
    }, [getBookingsForDate])

    const isAnyTimeslotBookable = useCallback((d: Date): boolean => { //TODO: fix this to enable day. different
        // let _bookingList;   
        // if (d === dateSelectedInCalendar) {
        //     _bookingList = bookingList;           
        // }
        // else {
        //     _bookingList = bookingBySeatId.filter(booking => booking.date === toDateISO(d));     
        // }    

        // const isSameSeat = _bookingList.some(booking => booking.seatId === seatId)

        // if (_bookingList.length && isSameSeat)
        //     return false;
        // let availableMinutesInDay = dayjs.duration({ hours: 24 }).asMinutes() - 1;

        // const allTimeBookingsForCurrentSeat = getAllTimeBookingsForCurrentSeatAndDate(_bookingList, seatId, room, toDateISO(d));

        // allTimeBookingsForCurrentSeat.forEach((booking: Booking) => {
        //     const bookingDuration = dayjs(booking.timeEnd).diff(dayjs(booking.timeBegin), "minutes");
        //     availableMinutesInDay = availableMinutesInDay - bookingDuration;          
        // }
        // )

        let bookingListForDate = bookingList
            .filter(booking => booking.__typename === "Booking")
            .map(booking => booking as Booking)
            .filter(booking => booking.date === toDateISO(d) && booking.seatId === seatId);

        // if (bookingListForDate[0].date === d){

        // }

        let availableMinutesInDay = dayjs.duration({hours: 24}).asMinutes() - 1;

        bookingListForDate.forEach((booking: Booking | MeetingRoomBooking) => {
            const bookingDuration = dayjs(booking.timeEnd).diff(dayjs(booking.timeBegin), "minutes");
            availableMinutesInDay = availableMinutesInDay - bookingDuration;
        })

        return availableMinutesInDay !== 0;
    }, [bookingList, seatId]) //TODO here is the problem bookingbyseatid is updated too often

    useEffect(function bookingConfigHasChanged() {
        if (bookingConfig) {
            setGreatestBookableDate(getGreatestBookableDate(bookingConfig))
            handleLimitOfDaysInAdvance()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingConfig.maxDaysInAdvance, dateSelectedInCalendar])

    useEffect(function updateMyBookedDaysBySeatId() {
        if (bookingConfig && mounted.current) {
            updateMyBookedDays()
                .catch(error => {
                    console.error(error)
                })
            setIsMaxBookableDaysWithSelectionReached(handleLimitOfBookings(false, bookedDays, selectedDates, bookingConfig))
            setIsBookingLimitInDaysReached(handleLimitOfBookings(true, bookedDays, selectedDates, bookingConfig))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingConfig.maxBookableDays, mergedBookingList.length, selectedDates])

    useEffect(function showBookingDidNotCompleteAlert() {
        setTimeout(() => {
            if (mounted.current)
                setShowBookingDidNotComplete(false);
        }, 7000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showBookingDidNotComplete]);

    useEffect(() => {
        async function updateSeatAlreadyTakenAndParallelBookDates() {

            setIsSeatAlreadyTaken(selectedDates.some(value => getBookingsForDate(value) && !isDateBookedByCurrentUser(value) && selectedSeatIsBookedOnSelectedDay))
            setParallelBookedDatesByOtherUsers(selectedDates.filter((date) => isDateBookedByAnotherUser(date)))
        }

        if (isSeatDialog) {
            updateSeatAlreadyTakenAndParallelBookDates().then(() => {
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSeatDialog, mergedBookingList.length]) // different

    useEffect(function updateIsParallelBookedByOtherUsers() {
        setIsParallelBookedByOtherUsers(parallelBookedDatesByOtherUsers.length > 0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parallelBookedDatesByOtherUsers])

    function getErrorMsgSeatParallelBooked(): string {
        let errorMsg = ""
        let dates = parallelBookedDatesByOtherUsers.map(date => date.toLocaleDateString(t("jsLocalDateProperty"), {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        })).join(", ")
        if (parallelBookedDatesByOtherUsers.length > 1) {
            errorMsg += (t("multibookingdialog_meetingroom_error_parallel_bookings")).replace("%s", dates)
        } else {
            errorMsg += (t("multibookingdialog_meetingroom_error_parallel_booking")).replace("%s", dates)
        }
        return errorMsg
    } // different

    function getAlertContent() {
        if (showBookingDidNotComplete) {
            return t("multibookingdialog_error_booking_not_complete")
        } else if (isGreaterThanGreatestBookableDate) {
            return t("multibookingdialog_error_max_booking_days_exceeded")
                + greatestBookableDate.toLocaleDateString('de-DE', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                }) + "."
        } else if (isMaxBookableDaysWithSelectionReached) {
            return t("multibookingdialog_meetingroom_error_max_bookable_days_")
        } else if (selectedSeatIsBookedOnSelectedDay) {
            return t("multibookingdialog_error_time_slot_not_bookable")
        } else if (isSeatAlreadyTaken) {
            return t("multibookingdialog_meetingroom_error_seat_already_taken")
        } else if (!isTimeActiveInputBookable) {
            return t("multibookingdialog_error_time_slot_not_bookable")
        } else if (isParallelBookedByOtherUsers) {
            return getErrorMsgSeatParallelBooked()
        }
    }

    function isDateBookableForMe(date: Date): boolean {
        if (isLowerThanDate(date, today)) {
            return false;
        }
        if (isGreaterThanDate(date, greatestBookableDate)) {
            return false;
        }
        if (isBookingLimitInDaysReached) {
            return isThereAnotherBookingOnThatDayByMe(date)
        }
        if (isMaxBookableDaysWithSelectionReached) {
            if (isDateSelected(date, selectedDates)) {
                return !inputConflictsWithBookingsOnDay(date);
            } else {
                const dateIsBooked = bookedDays.map(d => toDateISO(d)).some((_dateStr) => _dateStr === toDateISO(date))
                return dateIsBooked && isAnyTimeslotBookable(date);
            }
        }
        if (!isAnyTimeslotBookable(date))
            return false;

        return !inputConflictsWithBookingsOnDay(date);
    }

    function isThereAnotherBookingOnThatDayByMe(date: Date): boolean {
        return bookedDays.map(d => toDateISO(d)).indexOf(toDateISO(date)) !== -1;
    }

    function handleClickOnTimeWindow(start: Dayjs, end: Dayjs) {
        setBeginTime(start);
        setEndTime(end);
    }

    function handleOnCalendarChange(dates: any) {
        setSelectedDates(dates.map((d: DateObject) => new Date(d.toString())))
    }

    function handleLimitOfDaysInAdvance(): void {
        if (bookingConfig.maxDaysInAdvance === 0) {
            setIsGreaterThanGreatestBookableDate(false)
        } else if (isGreaterThanDate(dateSelectedInCalendar, greatestBookableDate) && selectedDates.length === 0) {
            setIsGreaterThanGreatestBookableDate(true)
        } else if (!selectedDates?.length) {
            setIsGreaterThanGreatestBookableDate(false)
        } else {
            const sortedDays = [...selectedDates].sort((a, b) => {
                return a.getTime() - b.getTime();
            });
            const currentLargestDate = sortedDays[sortedDays.length - 1];
            if (currentLargestDate.getTime() - 86400000 >= today.getTime() + (bookingConfig.maxDaysInAdvance * 86400000)) {
                setIsGreaterThanGreatestBookableDate(true);
            } else {
                setIsGreaterThanGreatestBookableDate(false);
            }
        }
    } // different

    function bookingTimeIntervalIsValid(newBookingBeginTime: string, newBookingEndTime: string, oldBookingBeginTime: string, oldBookingEndTime: string): boolean {
        if (newBookingBeginTime >= newBookingEndTime) {
            return false;
        }
        if (oldBookingBeginTime === newBookingBeginTime) {
            return false;
        }
        if (newBookingBeginTime >= oldBookingEndTime) {
            return true;
        }
        return oldBookingBeginTime >= newBookingEndTime;
    }

    const inputConflictsWithBookingsOnDay = useCallback((date: Date): boolean => {
        const allTimeBookingsForCurrentSeat = getAllTimeBookingsForCurrentSeatAndDate(mergedBookingList, seatId, room, toDateISO(date));
        return allTimeBookingsForCurrentSeat.some((booking: Booking | MeetingRoomBooking) =>
            !bookingTimeIntervalIsValid((beginTime?.format("HH:mm") ?? ""), (endTime?.format("HH:mm") ?? ""), dayjs(booking.timeBegin).format("HH:mm"), dayjs(booking.timeEnd).format("HH:mm")))
    }, [beginTime, mergedBookingList, endTime, room, seatId])

    const isBookingTimeFormatValid = useCallback(() => {
        return beginTime?.isValid() && endTime?.isValid() && endTime?.isAfter(beginTime);
    }, [beginTime, endTime])

    useEffect(() => {
        const areRequirementsNotFullfilled = !meetingType || !meetingName || !participantNumber || meetingType.length === 0 || meetingName.length === 0 || participantNumber === 0 || participantNumber < 0 || participantNumber > maxParticipantNumber!;
        if (areRequirementsNotFullfilled && isMeetingDialog) {
            setBookable(false)
            return;
        }
        setBookable(true)
    }, [isMeetingDialog, meetingType, meetingName, participantNumber, maxParticipantNumber]) // different


    // different
    useEffect(() => {
        const bookingTimeFormatValid = isBookingTimeFormatValid();

        if (selectedDates.length === 0) {
            setIsInvalidBookingTime(false);
            setIsTimeActiveInputBookable(true);
            return;
        }
        if (selectedDates.length > 0) {
            setIsParallelBookedByOtherUsers(false)
        }

        if (isMeetingDialog || room.isTimeActive) {
            if (!bookingTimeFormatValid) {
                setIsInvalidBookingTime(true);
                setIsTimeActiveInputBookable(!beginTime || !endTime);
                return;
            }
            const inputConflictsWithAnyBookingsOnSelectedDates
                = selectedDates.some((_date: Date) => inputConflictsWithBookingsOnDay(_date))
            if (inputConflictsWithAnyBookingsOnSelectedDates) {
                setIsInvalidBookingTime(true);
                setIsTimeActiveInputBookable(false);
            } else {
                setIsInvalidBookingTime(false);
                setIsTimeActiveInputBookable(true);
            }
        }
    }, [beginTime, endTime, selectedDates, bookingBySeatId, dateSelectedInCalendar, room, bookingList, seatId, isAnyTimeslotBookable, inputConflictsWithBookingsOnDay, isBookingTimeFormatValid, isMeetingDialog])

    const onChangeMeetingName = (value: string) => {
        setMeetingName(value)
    }
    const onChangeMeetingType = (value: MeetingType) => {
        setMeetingType(value)
    }
    const onChangeNumberOfParticipants = (value: number) => {
        setParticipantNumber(value)
    }

    const {t} = useTranslation();
    const collapseIn = showBookingDidNotComplete ||
        (isSeatAlreadyTaken) ||
        isGreaterThanGreatestBookableDate ||
        isMaxBookableDaysWithSelectionReached ||
        (isParallelBookedByOtherUsers) ||
        (!isTimeActiveInputBookable);
    const columnStyle: CSSProperties = {display: "flex", flexDirection: "column", justifyContent: "flex-start"};
    const columnStyleFixedWidth: CSSProperties = {display: "flex", flexDirection: "column", maxWidth: 300};


    interface ChildrenProps {
        multiBookingDialogTitleProps: MultiBookingDialogTitleProps,
        multiBookingDialogBookingButtonProps: MultiBookingDialogBookingButtonProps,
        multiBookingLegendComponentProps: MultiBookingLegendComponentProps,
        multiBookingCalenderComponentProps: MultiBookingCalendarComponentProps,
        meetingRoomBookingSettingsProps: MeetingRoomBookingSettingsProps,
        timeWindowTableComponentProps: TimeWindowTableComponentProps,
        multiBookingDialogNameFieldProps: MultiBookingDialogNameFieldProps,
        leftTimePickerProps: TimePickerProps<Dayjs>,
        rightTimePickerProps: TimePickerProps<Dayjs>
    }

    const cProps: ChildrenProps = {
        multiBookingDialogTitleProps: {
            dateSelectedInCalendar: dateSelectedInCalendar,
            greatestBookableDate: greatestBookableDate,
            isGreaterThanGreatestBookableDate: isGreaterThanGreatestBookableDate,
            selectedSeatIsBookedOnSelectedDay: selectedSeatIsBookedOnSelectedDay,
        },
        multiBookingDialogBookingButtonProps: {
            bookingType: bookingType,
            meetingRoomProps: {
                meetingName: meetingName,
                meetingType: meetingType,
                numberOfParticipants: participantNumber,
                maxParticipantNumber: maxParticipantNumber
            },
            shouldDisabled: !bookable,
            handleClose: handleClose,
            bookingConfig: bookingConfig,
            bookerName: bookerName,
            bookerGivenName: bookerGivenName,
            bookerFamilyName: bookerFamilyName,
            seatId: seatId,
            room: room,
            selectedDates: selectedDates,
            setSelectedDates: setSelectedDates,
            isDateBookableForMe: isDateBookableForMe,
            bookedDays: bookedDays,
            bookingForState: bookingForState,
            bookingForInputUsername: bookingForInputUsername,
            setShowBookingDidNotComplete: setShowBookingDidNotComplete,
            beginTime: (beginTime?.format("HH:mm") ?? ""),
            endTime: (endTime?.format("HH:mm") ?? ""),
            setIsInvalidBookingTime: setIsInvalidBookingTime,
            isInvalidBookingTime: isInvalidBookingTime
        },
        multiBookingLegendComponentProps: {
            bookingType: bookingType,
            isTimeActive: true,
            isBookingTimeFormatValid: isBookingTimeFormatValid,
        },
        multiBookingCalenderComponentProps: {
            bookingType: bookingType,
            today: today,
            selectedDates: selectedDates,
            onCalendarSelectionChange: handleOnCalendarChange,
            bookings: bookingBySeatId,
            greatestBookableDate: greatestBookableDate,
            isBookingLimitReached: isMaxBookableDaysWithSelectionReached,
            isTimeActive: true,
            isDateBookableForMe: isDateBookableForMe,
            isAnyTimeslotBookable: isAnyTimeslotBookable,
            isBookingTimeFormatValid: isBookingTimeFormatValid,
        },
        meetingRoomBookingSettingsProps: {
            maxParticipants: maxParticipantNumber,
            onChangeMeetingName: onChangeMeetingName,
            onChangeMeetingType: onChangeMeetingType,
            onChangeNumberOfParticipants: onChangeNumberOfParticipants,
        },
        timeWindowTableComponentProps: {
            handleClickOnTimeWindow: handleClickOnTimeWindow,
            bookings: bookingBySeatId,
            selectedDates: selectedDates,
        },
        multiBookingDialogNameFieldProps: {
            bookingForInputUsername: bookingForInputUsername,
            setBookingForInputUsername: setBookingForInputUsername,
        },
        leftTimePickerProps: {
            viewRenderers: {hours: renderTimeViewClock, minutes: renderTimeViewClock},
            value: beginTime,
            onChange: (newValue: Dayjs | null) => handleStartTime(newValue),
            slots: {textField: CustomTextFieldLeft}
        },
        rightTimePickerProps: {
            viewRenderers: {hours: renderTimeViewClock, minutes: renderTimeViewClock},
            value: endTime,
            onChange: (newValue: Dayjs | null) => handleEndTime(newValue),
            slots: {textField: CustomTextFieldRight},
        }

    }

    function getSettings() {
        return (
            <>
                {isMeetingDialog && <>
                    <div className={"verticalLine"}/>
                    <div style={columnStyle}>
                        <MeetingRoomBookingSettings {...cProps.meetingRoomBookingSettingsProps}/>
                    </div>
                </>}
            </>
        )
    }

    function getTimeInputs() {
        return room.isTimeActive
            ? (
                <div className={"leftSideChild"} style={{display: "flex"}}>
                    <TimePicker {...cProps.leftTimePickerProps}/>
                    <TimePicker {...cProps.rightTimePickerProps}/>
                </div>
            )
            : (
                <></>
            )
    }

    return (
        <div className={"dialogContentDiv"} data-testid={"multi-booking-dialog"}>
            <MultiBookingDialogContext.Provider value={MultiBookingDialogProviderValues}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"de"}>
                    <Collapse data-testid={"alerts"} in={collapseIn}>
                        <Alert severity="error" className={"alertStyle"}>
                            {showBookingDidNotComplete &&
                                <AlertTitle className={"alertTitleStyle"}>{t("booking-error_title")}</AlertTitle>}
                            {getAlertContent()}
                        </Alert>
                    </Collapse>

                    <DialogContent>
                        <MultiBookingDialogTitle {...cProps.multiBookingDialogTitleProps}/>
                        <div data-testid={"multibooking-calendar-and-legend"} className={"calendarAndLegendDiv"}>
                            <div style={columnStyle}>
                                <MultiBookingLegendComponent{...cProps.multiBookingLegendComponentProps}/>
                            </div>
                            <div className={"verticalLine"}/>
                            <div style={columnStyleFixedWidth}>
                                <MultiBookingCalendarComponent{...cProps.multiBookingCalenderComponentProps}/>

                                {getTimeInputs()}

                                {(bookingForState === BookingFor.BOOKING_FOR_OTHERS) &&
                                    <MultiBookingDialogNameField {...cProps.multiBookingDialogNameFieldProps}/>}
                            </div>

                            {getSettings()}

                            <div className={"verticalLine"}/>
                            <div style={columnStyle}>
                                <TimeWindowTableComponent {...cProps.timeWindowTableComponentProps}/>
                            </div>
                        </div>
                    </DialogContent>

                    <DialogActions className={"dialogActions"}>
                        <MultiBookingDialogBookingButton{...cProps.multiBookingDialogBookingButtonProps}/>
                        <Button data-testid={"close-btn"} variant={"contained"} color={"primary"}
                                onClick={() => handleClose()}>
                            {t('button_close')}
                        </Button>
                    </DialogActions>
                </LocalizationProvider>
            </MultiBookingDialogContext.Provider>
        </div>
    )
}