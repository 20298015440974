import {TimeWindow} from "../types/TimeWindowType";
import {Booking, MeetingRoomBooking} from "../API";
import {RoomAndIconBookingColor, RoomBookingColor, RoomIconBookingColor} from "../Utils/Enums";
import {useEffect, useState} from "react";
import dayjs from "dayjs";

type ColorTypes = {
    roomAndIcon: RoomAndIconBookingColor,
    room: RoomBookingColor,
    icon: RoomIconBookingColor,
}

export default function useRoomColor(roomBookingTimeWindow: TimeWindow[], currentUserID: string, bookings: (Booking | MeetingRoomBooking)[] | null | undefined, isTimeBookingActive?: boolean): ColorTypes {
    const [roomColor, setRoomColor] = useState<RoomBookingColor>(RoomBookingColor.GREEN)
    const [roomIconColor, setRoomIconColor] = useState<RoomIconBookingColor>(RoomIconBookingColor.GREEN)
    const [roomAndIconColor, setRoomAndIconColor] = useState<RoomAndIconBookingColor>(RoomAndIconBookingColor.GREEN)

    useEffect(function updateRoomColor() {
        function hasThreeHourWindow(): boolean {
            const windowDuration = dayjs.duration({"hours": 3})
            return roomBookingTimeWindow.some((timeWindow) => {
                return timeWindow.diff >= windowDuration.asMinutes();
            })
        }

        function hasBookingByMe(bookings: (Booking | MeetingRoomBooking)[]): boolean {
            return bookings.some((booking: Booking | MeetingRoomBooking) => {
                return booking.bookerId === currentUserID
            })
        }

        function handleTimeBookingActiveColors() {
            if (!bookings || bookings.length === 0) {
                setRoomColor(RoomBookingColor.GREEN);
                setRoomIconColor(RoomIconBookingColor.GREEN);
                setRoomAndIconColor(RoomAndIconBookingColor.GREEN);
            } else if (bookings.length > 0 && hasBookingByMe(bookings)) {
                setRoomColor(RoomBookingColor.BLUE);
                setRoomIconColor(RoomIconBookingColor.BLUE);
                setRoomAndIconColor(RoomAndIconBookingColor.BLUE);
            } else if (hasThreeHourWindow()) {
                setRoomColor(RoomBookingColor.YELLOW);
                setRoomIconColor(RoomIconBookingColor.YELLOW);
                setRoomAndIconColor(RoomAndIconBookingColor.YELLOW);
            } else if (roomBookingTimeWindow.length > 0) {
                setRoomColor(RoomBookingColor.ORANGE);
                setRoomIconColor(RoomIconBookingColor.ORANGE);
                setRoomAndIconColor(RoomAndIconBookingColor.ORANGE);
            } else {
                setRoomColor(RoomBookingColor.RED);
                setRoomIconColor(RoomIconBookingColor.RED);
                setRoomAndIconColor(RoomAndIconBookingColor.RED);
            }
        }

        function handleBookingColors() {
            if (!bookings || bookings.length === 0) {
                setRoomColor(RoomBookingColor.GREEN);
                setRoomIconColor(RoomIconBookingColor.GREEN);
                setRoomAndIconColor(RoomAndIconBookingColor.GREEN);
            } else if (bookings.length === 1 && hasBookingByMe(bookings)) {
                setRoomColor(RoomBookingColor.BLUE);
                setRoomIconColor(RoomIconBookingColor.BLUE);
                setRoomAndIconColor(RoomAndIconBookingColor.BLUE);
            } else {
                setRoomColor(RoomBookingColor.RED);
                setRoomIconColor(RoomIconBookingColor.RED);
                setRoomAndIconColor(RoomAndIconBookingColor.RED);
            }
        }

        isTimeBookingActive ? handleTimeBookingActiveColors() : handleBookingColors()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUserID, isTimeBookingActive, bookings?.length, roomBookingTimeWindow])

    return {
        roomAndIcon: roomAndIconColor,
        room: roomColor,
        icon: roomIconColor
    }
}