import React, {useState} from "react";
import {
    Box,
    Button,
    Checkbox,
    Chip,
    ClickAwayListener,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme
} from "@material-ui/core";
import {Inventory, Room, SeatConfig} from "../API";
import SeatConfigurationComponent from "./SeatConfigurationComponent";
import {useSeatConfigList} from "../hooks/useSeatConfigList";
import {InvType} from "../Utils/Enums";
import {useInventoryList} from "../hooks/useInventoryList";
import {CognitoUser} from "../hooks/useCognitoUserList";
import {useTranslation} from "react-i18next";


interface Props {
    room: Room
    showSeatManager: boolean
    setShowSeatManager: (value: boolean) => void
    cognitoUserList: CognitoUser[]
}

function renderCheckbox(isChecked: boolean | undefined) {
    return <Checkbox checked={isChecked} disabled/>
}

const useStyles = makeStyles<Theme>(() => createStyles({
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
}));

const SeatManagerComponent: React.FC<Props> = (props) => {
    const classes = useStyles()

    let {showSeatManager, setShowSeatManager, room, cognitoUserList} = props
    const [selectedSeat, setSelectedSeat] = useState<SeatConfig>()
    const [showSeatConfig, setShowSeatConfig] = useState(false)

    const seats = useSeatConfigList(room.roomId)
    const inventoryItems = useInventoryList()

    const handleSelectSeat = (index: number) => {
        if (seats && !showSeatConfig) {
            setSelectedSeat(seats[index]!)
        }
    }

    const inventoryFromSelected = (selectedSeat: SeatConfig, invType: InvType) => {
        if (selectedSeat?.inventory && inventoryItems) {
            return selectedSeat.inventory
                .map((itemId: string) => inventoryItems.find((invItem: Inventory) => invItem?.inventoryId === itemId))
                .filter((invItem: Inventory) => invItem?.type === invType &&
                    inventoryItems.find((item: Inventory) => item.nameLowerCased === invItem.nameLowerCased && room.orgUnitId === item.orgUnitId))
        } else {
            return []
        }
    }

    const handleCloseSeatManager = () => {
        setShowSeatManager(false)
        setShowSeatConfig(false)
    }

    const {t} = useTranslation();
    return (
        <>
            <Dialog  fullWidth={true} maxWidth={"lg"} style={{width: "100%", flexGrow: 1}} open={showSeatManager} data-testid={"seatManager"}>
                <DialogTitle>{t("seat_management_dialog-title")}</DialogTitle>
                <ClickAwayListener onClickAway={() => {
                    if (!showSeatConfig) {
                        setSelectedSeat(undefined)
                    }
                }}>
                    <DialogContent>

                        {showSeatConfig ?
                            <SeatConfigurationComponent
                                inventoryItems={inventoryItems}
                                seat={selectedSeat!}
                                setShowSeatConfiguration={setShowSeatConfig}
                                cognitoUserList={cognitoUserList}
                                setSelectedSeatConfiguration={setSelectedSeat}
                            /> : <></>}
                        <Box>
                            <TableContainer style={{maxHeight: "400px", marginTop: "1rem", tableLayout: "fixed"}}>
                                <Table stickyHeader style={{marginTop: "0"}}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                data-testid={"seat-name-column"}
                                                style={{wordBreak: "break-word"}}
                                                >
                                                {t("seat_name-column")}</TableCell>
                                            <TableCell
                                                data-testid={"docking-station-column"}
                                                style={{wordBreak: "break-word"}}
                                            >
                                                {t("docking_station-column")}
                                            </TableCell>
                                            <TableCell
                                                data-testid={"monitor-column"}
                                                style={{wordBreak: "break-word"}}
                                            >
                                                {t("monitor-column")}
                                            </TableCell>
                                            <TableCell
                                                data-testid={"owner-column"}
                                                style={{wordBreak: "break-word"}}
                                            >
                                                {t("owner-column")}
                                            </TableCell>
                                            <TableCell
                                                data-testid={"height-adjustable-column"}
                                                style={{wordBreak: "break-word"}}
                                            >
                                                {t("height-adjustable-column")}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody data-testid={"room-list"}>
                                        {room.roomId &&
                                            seats.map((seat: SeatConfig | null, index: number) => (
                                                <TableRow data-testid={`seat-${index}`}
                                                          onClick={() => handleSelectSeat(index)}
                                                          key={index}
                                                          style={{cursor: "pointer"}}
                                                          selected={seats[index] === selectedSeat}>
                                                    <TableCell>
                                                        {seat!.seatName}
                                                    </TableCell>
                                                    <TableCell
                                                        style={{maxWidth: "200px"}}
                                                    >
                                                        {/*unique key is important inventoryId is not unique*/}
                                                        {inventoryFromSelected(seats[index], InvType.Dockingstation).map((inventoryItem: Inventory, index: number) => (
                                                            <Chip key={index}
                                                                  label={inventoryItem.name}
                                                                  className={classes.chip}/>
                                                        ))}
                                                    </TableCell>
                                                    <TableCell
                                                        style={{maxWidth: "200px"}}
                                                    >
                                                        {/*unique key is important inventoryId is not unique*/}
                                                        {inventoryFromSelected(seats[index], InvType.Monitor).map((inventoryItem: Inventory, index: number) => (
                                                            <Chip key={index}
                                                                  label={inventoryItem.name}
                                                                  className={classes.chip}/>
                                                        ))}
                                                    </TableCell>
                                                    <TableCell
                                                        style={seat!.isOwnerRegistered ? {color: "green"} : {color: "red"}}>
                                                        {seat!.owner}
                                                    </TableCell>
                                                    <TableCell>
                                                        {!!seat!.isSeatHeightAdjustable ? renderCheckbox(true) : renderCheckbox(false)}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <DialogActions style={{display: "flex", justifyContent: "center"}}>
                                <Button
                                    onClick={() =>
                                        setShowSeatConfig(true)}
                                    color={"primary"}
                                    variant={"contained"}
                                    data-testid={"show-seat-config-btn"}
                                    disabled={!selectedSeat || showSeatConfig}
                                >
                                    {t('configure_seat')}
                                </Button>
                                <Button
                                    onClick={() => handleCloseSeatManager()}
                                    color={"primary"}
                                    variant={"contained"}
                                    data-testid={"close-btn"}
                                >
                                    {t('button_close')}
                                </Button>
                            </DialogActions>
                        </Box>
                    </DialogContent>
                </ClickAwayListener>
            </Dialog>


        </>
    )
}

export default SeatManagerComponent