import {useUpdateBookingInfos} from "../../hooks/useUpdateBookingInfos"
import {useGetFreeTimeWindows} from "../../hooks/useGetFreeTimeWindows"
import {useRoomPlanContext} from "../../hooks/useRoomPlanContext"
import useRoomColor from "../../hooks/useRoomColor";
import {MeetingRoomBookings} from "../../types/MeetingRoomBookingType";
import React, {useEffect, useState} from "react";
import {Booking, MeetingRoomBooking} from "../../API";
import MeetingRoomIcon from "./meetingRoomRenderComponents/MeetingRoomIcon";
import MeetingRoomGround from "./meetingRoomRenderComponents/MeetingRoomGround";

export interface MeetingRoomInterface {
    meetingRoomID: string
    roomCap: number
    meetingRoomGroupPosition: Position
    meetingRoomPosition: Position
    meetingRoomDimension: Dimensions
    meetingRoomShape: string
    meetingRoomIconPosition: Position
    meetingRoomIconDimension: Dimensions
}

interface Props {
    onClick: (bookings: MeetingRoomBookings) => void
    meetingRoomBookings: MeetingRoomBookings
    isTimeBookingActive: boolean
}

type Position = {
    x: number,
    y: number,
    transform: string | undefined
}

type Dimensions = {
    width: number,
    height: number
}

const MeetingRoomComponent = (props: Props) => {
    const {meetingRoomBookings} = props
    const meetingRoomGroupPosition = meetingRoomBookings.meetingRoom.meetingRoomGroupPosition;
    const meetingRoomPosition = meetingRoomBookings.meetingRoom.meetingRoomPosition;
    const bookingInfos = useUpdateBookingInfos(meetingRoomBookings.bookings, props.isTimeBookingActive);
    const seatBookingTimeWindow = useGetFreeTimeWindows(bookingInfos, props.isTimeBookingActive, "08:00", "18:00");
    const {setBookingsToDisplay, currentUserID} = useRoomPlanContext()
    const roomBookingState = useRoomColor(seatBookingTimeWindow, currentUserID, meetingRoomBookings.bookings, props.isTimeBookingActive);
    const [isMouseOverIcon, setIsMouseOverIcon] = useState(false);

    useEffect(function showMouseOver() {
        let mouseOverBookings: (Booking | MeetingRoomBooking)[] = [];
        if (isMouseOverIcon) {
            mouseOverBookings = props.meetingRoomBookings.bookings;
        }

        mouseOverBookings.sort((a, b) => {
            return (a.timeBegin || "") < (b.timeBegin || "") ? -1 : 1
        })

        setBookingsToDisplay(mouseOverBookings)
    }, [isMouseOverIcon])

    return (
        <g className="meeting-room" id={props.meetingRoomBookings.meetingRoom.meetingRoomID}
           transform={meetingRoomGroupPosition.transform}
           onClick={() => {
               props.onClick(meetingRoomBookings);
               setIsMouseOverIcon(false);
           }}>
            <svg x={meetingRoomGroupPosition.x} y={meetingRoomGroupPosition.y}
                 className={roomBookingState.roomAndIcon}>
                <g className={"meeting-room-elements"}>
                    <svg x={meetingRoomPosition.x} y={meetingRoomPosition.y}
                         className={roomBookingState.room}>
                        <MeetingRoomGround elementPosition={meetingRoomBookings.meetingRoom.meetingRoomPosition}
                                           elementShape={meetingRoomBookings.meetingRoom.meetingRoomShape}
                                           isFiltered={false}/>
                    </svg>
                    <svg x={meetingRoomPosition.x} y={meetingRoomPosition.y}
                         className={roomBookingState.icon}>
                        <MeetingRoomIcon elementPosition={meetingRoomBookings.meetingRoom.meetingRoomIconPosition}
                                         elementDimensions={meetingRoomBookings.meetingRoom.meetingRoomIconDimension}
                                         isFiltered={false}
                                         setMouseOver={setIsMouseOverIcon}/>
                    </svg>
                </g>
            </svg>
        </g>)
};

export default MeetingRoomComponent;