import {gql, useQuery} from "@apollo/client";
import {bookingByDateAndOrgUnit} from "../graphql/queries";
import {useEffect, useState} from "react";
import {Booking, Room} from "../API";
import {onCreateBookings, onDeleteBookingWithoutRoom} from "../graphql/subscriptions";
import {IOrgUnit} from "./useOrgunit";
import {useErrorContext} from "./useErrorContext";



export function useBookingByDateAndOrgUnit(orgUnits: IOrgUnit[], date: string, rooms: Room[]): [Booking] | [] | Booking[] {
    const hasOrgUnitId = !(!orgUnits || orgUnits.length === 0 || orgUnits[0].orgId === "");
    const {reportError} = useErrorContext()
    let [previous, setPrevious] = useState<[Booking] | Booking[]>([]);
    let [current, setCurrent] = useState<[Booking] | Booking[]>([]);
    let [loadDone, setLoadDone] = useState(false);

    const {loading, subscribeToMore, refetch, error} = useQuery(gql(bookingByDateAndOrgUnit), {
        variables: {
            orgUnitId: "",
            date: {
                eq: date

            }
        },
        skip: !hasOrgUnitId
    });

    if (error) {
        reportError(error, "", "useBookingByDateAndOrgUnit useQuery(gql(bookingByDateAndOrgUnit)")
    }


    useEffect(() => {
        setLoadDone(false);
        setCurrent([])
        orgUnits.forEach(orgUnit => {
            let orgUnitId = orgUnit.orgId
            const vars = {
                orgUnitId: orgUnitId,
                date: {
                    eq: date
                }
            }
            refetch(vars)
                .then(result => {
                    const bookings = result.data.bookingByDateAndOrgUnit.items
                    setCurrent(dat => [...dat, ...bookings])
                })
                .catch(err => reportError(err, "", "useBookingByDateAndOrgUnit useEffect:refetch"))
        });
        setPrevious(current);
        setLoadDone(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgUnits, date])

    useEffect(() => {
        let unsubscriptionsList: (() => void)[] = [];
        let delayTimeoutId: number | undefined = window.setTimeout(() => {
            orgUnits.forEach(orgUnit => {
                let orgUnitId = orgUnit.orgId
                let unsubscribeDeleteBookingWithoutRoom: () => void = subscribeToMore({
                    document: gql(onDeleteBookingWithoutRoom),
                    variables: {
                        date: date,
                        orgUnitId: orgUnitId
                    },
                    updateQuery: (prev, {subscriptionData}) => {
                        if (!subscriptionData.data) return prev;
                        const deletedBookingId = subscriptionData.data.onDeleteBookingWithoutRoom.bookingId;
                        const deletedBookingDate = subscriptionData.data.onDeleteBookingWithoutRoom.date;
                        const updatedItems = current.filter((item: Booking) => (item.bookingId !== deletedBookingId || item.date !== deletedBookingDate))
                        return setCurrent(updatedItems)
                    },
                    onError: (subscriptionError: any) => console.error("useBookingByDateAndOrgUnit onDeleteBookingWithoutRoomSubscription " +
                        orgUnit.orgName + " " + JSON.stringify(subscriptionError))
                });
                unsubscriptionsList.push(unsubscribeDeleteBookingWithoutRoom);
            });


            rooms.forEach(room => {
                    if (!room) return;
                    let unsubscribeCreateBookings: () => void = subscribeToMore({
                        document: gql(onCreateBookings),
                        variables: {
                            date: date,
                            orgUnitId: room.orgUnitId,
                            roomId: room.roomId
                        },
                        updateQuery: (prev, {subscriptionData}) => {
                            if (!subscriptionData.data) return prev;
                            const newBookings = subscriptionData.data.onCreateBookings.Items.filter((item: Booking) => (item.date === date));

                            return setCurrent(dat => [...dat, ...newBookings])
                        },
                        onError: (subscriptionError: any) => console.error(" useBookingByDateAndOrgUnit onCreateBookingsWithoutRoomSubscription " +
                            room.orgUnitId + room.name + " " + JSON.stringify(subscriptionError))
                    });
                    unsubscriptionsList.push(unsubscribeCreateBookings);
                }
            );
            delayTimeoutId = undefined;
        }, 1000);

        return () => {
            if (delayTimeoutId)
                window.clearTimeout(delayTimeoutId);
            else {
                unsubscriptionsList.forEach(unsubscribeMethod => unsubscribeMethod());
                unsubscriptionsList = [];
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date, orgUnits, rooms])

    if (loading)
        return previous;
    else if (loadDone && !loading) {
        return current;
    } else
        return previous;
}

