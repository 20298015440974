import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@material-ui/core";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Divider from '@material-ui/core/Divider';
import React, {ChangeEvent, useCallback, useEffect, useState} from "react";
import CreateRoomComponent from "../CreateRoomComponent";
import {Booking, Room} from "../../API";
import {getRelativeComplement, isSvgFileValid} from "../../Utils/Helpers";
import MuiAlert, {AlertProps} from '@material-ui/lab/Alert';
import {
    createAreBookingsTimeDataUpdated,
    createSeatConfig,
    deleteBooking as deleteBookingTemplate,
    deleteRoom as deleteRoomTemplate,
    deleteSeatConfig,
    publishRoomAccessChange as publishRoomAccessChangeTemplate, updateAreBookingsTimeDataUpdated,
    updateBooking as updateBookingTemplate,
    updateRoom as updateRoomTemplate
} from "../../graphql/mutations";
import {gql, useMutation, useQuery} from "@apollo/client";
import {
    bookingByRoom,
    getAreBookingsTimeDataUpdated,
    getPresignedRoomplanUrl,
    listBookings
} from "../../graphql/queries";
import {useRoomPlanFromS3} from "../../hooks/useRoomPlanFromS3";
import SeatManagerComponent from "../SeatManagerComponent";
import {useCognitoUserList} from "../../hooks/useCognitoUserList";
import RoomManagerTableComponent from "./RoomManagerTableComponent";
import RoomManagerUploadConfirmationDialog from "./RoomManagerUploadConfirmationDialog";
import RoomManagerAdminForm from "./RoomManagerAdminForm";
import RoomManagerUploadSvgButton from "./RoomManagerUploadSvgButton";
import {useMainApplicationContext} from "../../hooks/useMainApplicationContext";
import {useErrorContext} from "../../hooks/useErrorContext";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {useTranslation} from "react-i18next";


dayjs.extend(utc)

interface Props {
    showRoomManager: boolean
    setShowRoomManager: (value: boolean) => void

}

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const initialAlertStates = {success: false, error: false, error_create_room: false}

const RoomManagerComponent: React.FC<Props> = (props) => {
    const {showRoomManager, setShowRoomManager} = props

    const {
        orgUnitList,
        selectedOrgUnit,
        setSelectedOrgUnit,
        currentUser,
        rooms,
    } = useMainApplicationContext()
    const {reportError} = useErrorContext();
    const cognitoUserList = useCognitoUserList(currentUser)

    const [roomPlanSvgFile, setRoomPlanSvgFile] = useState<File>()
    const [roomToBeUpdatedAccordingToTimeFeature, setRoomToBeUpdatedAccordingToTimeFeature] = useState<Room>();
    const [multipleBookingsPerSeat, setMultipleBookingsPerSeat] = useState<Booking[]>([])
    const [selectedRoom, setSelectedRoom] = useState<Room | undefined>(undefined);
    const currentRoomPlan = useRoomPlanFromS3(selectedRoom)

    const [showCreateRoom, setShowCreateRoom] = useState(false)
    const [isShowAlerts, setIsShowAlerts] = useState(initialAlertStates);
    const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false);
    const [onDeleteLoading, setOnDeleteLoading] = useState(false)
    const [onUploadLoading, setOnUploadLoading] = useState(false)
    const [showSeatManagerDialog, setShowSeatManagerDialog] = useState(false)
    const [showUploadConfirmationDialog, setShowUploadConfirmationDialog] = useState(false)
    const [disableRoomManagerInteraction, setDisableRoomManagerInteraction] = useState(false)
    const [isLoadingBookingsToDelete, setIsLoadingBookingsToDelete] = useState<boolean>(false)
    const [isShowingDisableTimeBookingConfirmationDialog, setIsShowingDisableTimeBookingConfirmationDialog] = useState(false);
    const [didBookingsTimeDataUpdate, setDidBookingsTimeDataUpdate] = useState<boolean>(false)

    const [seatsToDeleteArrayIds, setSeatsToDeleteArrayIds] = useState<string[]>([]);
    const [seatsNewArrayIds, setSeatsNewArrayIds] = useState<string[]>([]);

    const [updateRoomMutation] = useMutation(gql(updateRoomTemplate))
    const [deleteRoomMutation] = useMutation(gql(deleteRoomTemplate))
    const [deleteBookingMutation] = useMutation(gql(deleteBookingTemplate))
    const [updateBookingMutation] = useMutation(gql(updateBookingTemplate))
    const [publishRoomAccessChange] = useMutation(gql(publishRoomAccessChangeTemplate))
    const [createSeatConfigMutation] = useMutation(gql(createSeatConfig))
    const [deleteSeatConfigMutation] = useMutation(gql(deleteSeatConfig))
    const [createAreBookingsTimeDataUpdatedMutation] = useMutation(gql(createAreBookingsTimeDataUpdated))
    const [updateAreBookingsTimeDataUpdatedMutation] = useMutation(gql(updateAreBookingsTimeDataUpdated))

    const {refetch: getDidBookingsTimeDataUpdate} = useQuery(gql(getAreBookingsTimeDataUpdated))
    const {refetch} = useQuery(gql(bookingByRoom), {
        skip: !selectedRoom
    })
    const {refetch: refetchPreSignedUrl} = useQuery(gql(getPresignedRoomplanUrl));
    const {refetch: getBookingsWhenTimeIsActive} = useQuery(gql(bookingByRoom));//TODO: useBookingList
    const {refetch: getBookingsOfRoomPlan} = useQuery(gql(bookingByRoom), {
        skip: !roomToBeUpdatedAccordingToTimeFeature
    })
    const {refetch: getAllBookings} = useQuery(gql(listBookings));


    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(function updateRoomManagerDisabler() {
        setDisableRoomManagerInteraction(orgUnitList.length === 0);
    }, [orgUnitList]);
    useEffect(() => {
        if (showRoomManager) {
            const sortedOwnOrgUnits = currentUser.orgUnits.filter(org => !org.deleted)
            setSelectedOrgUnit(currentUser.orgUnits.length > 0 ? sortedOwnOrgUnits[0] : orgUnitList[0])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showRoomManager]);
    useEffect(function initialiseDidBookingsTimeDataUpdate() {
        getDidBookingsTimeDataUpdate({id: "0"})
            .then((response) => saveValueAndCreateIfNecessary(response.data.getAreBookingsTimeDataUpdated?.didUpdate))
            .catch(error => console.error("RoomManagerComponent fetchDidBookingsTimeDataUpdate", error));

        function saveValueAndCreateIfNecessary(answer: boolean | null | undefined) {
            setDidBookingsTimeDataUpdate(answer as boolean);

            const noAnswer = (answer === null) || (answer === undefined);
            if (noAnswer) createAreBookingsTimeDataUpdatedMutation({
                variables: {
                    input: {
                        id: "0",
                        didUpdate: false

                    }
                }
            })
                .catch(error => console.error("RoomManagerComponent saveValueAndCreateIfNecessary", error))
        }
    }, [createAreBookingsTimeDataUpdatedMutation, getDidBookingsTimeDataUpdate])

    const deleteStartIcon = onDeleteLoading ? <CircularProgress style={{color: "white"}} size={25}/> : <DeleteIcon/>
    const uploadStartIcon = onUploadLoading ? <CircularProgress style={{color: "white"}} size={25}/> :
        <CloudUploadIcon/>


    const getSeatsFromPlan = (fileAsString: string): string[] => {
        if (fileAsString === "") {
            return []
        }
        const roomPlanParser = new DOMParser();
        const roomPlanDoc = roomPlanParser.parseFromString(fileAsString, "image/svg+xml");
        const seatGroup = roomPlanDoc.getElementById("seats")
        if (seatGroup === null) {
            throw new Error("No group with id seats")
        }

        return getSeatIds(seatGroup)

        function getSeatIds(seatGroup: HTMLElement): string[] {
            let seats: string[] = []
            const groupElements = Array.from(seatGroup.getElementsByTagName("g"));
            groupElements.forEach((element: SVGGElement) => {
                let id = element.getAttribute("id");
                if (id !== null && id !== "") {
                    seats.push(id)
                }
            })
            return seats
        }

    }

    const updateRoomPlan = (roomPlanId: string | null | undefined, roomPlanExisting: boolean | null) => {
        updateRoomMutation({
            variables: {
                input: {
                    roomId: selectedRoom!.roomId,
                    roomPlanExisting: roomPlanExisting,
                    roomPlanId: roomPlanId,
                    roomPlanScaleFactor: roomPlanExisting ? selectedRoom!.roomPlanScaleFactor : null
                }
            }
        }).then(() => setSelectedRoom(undefined)
        ).then(() => setOnDeleteLoading(false)
        ).then(() => setOnUploadLoading(false)
        ).catch((err) => reportError(err, "", "RoomManagerComponent updateRoomPlan"))
    };

    const deleteBookingByBookingIdAndDate = (booking: Booking) => {
        deleteBookingMutation({
            variables: {
                input: {
                    bookingId: booking.bookingId,
                    date: booking.date
                }
            }
        }).catch((err) => reportError(err, "", "RoomManagerComponent deleteBookingByBookingIdAndDate"))
    }

    const deleteAllBookingsForRoom = async (roomId: string) => {
        let bookingsForRoom: Booking[] = [];
        const result = await refetch({roomId: roomId});
        if (result && result.data && result.data.bookingByRoom && result.data.bookingByRoom.items.length > 0) {
            bookingsForRoom = result.data.bookingByRoom.items;
            bookingsForRoom.forEach((booking) => deleteBookingByBookingIdAndDate(booking))
        }
    }

    const deleteSeatConfigBySeatId = (seatId: string) => {
        deleteSeatConfigMutation({
            variables: {
                input: {
                    roomId: selectedRoom!.roomId,
                    seatName: seatId
                }
            }
        }).catch((err) => reportError(err, "", "RoomManagerComponent deleteSeatConfigBySeatId"))
    }

    const onCloseUploadConfirmationDialog = () => {
        setShowUploadConfirmationDialog(false)
        setSeatsToDeleteArrayIds([])
    }

    const handleUpdateOrgUnitIdForBookings = (roomId: string, newOrgUnitId: string) => {
        refetch({roomId: roomId})
            .then((result) => result?.data?.bookingByRoom?.items?.forEach((booking: Booking) => updateBookingMutation({
                variables: {
                    input: {
                        bookingId: booking.bookingId,
                        date: booking.date,
                        orgUnitId: newOrgUnitId
                    }
                }
            })))
            .then(() => publishRoomAccessChange({variables: {roomId: roomId}}))
            .catch((err) => reportError(err, "", "RoomManagerComponent handleUpdateOrgUnitIdForBookings"))
    }

    const handleDeleteRoom = () => {
        deleteRoomMutation({
            variables: {
                input: {
                    roomId: selectedRoom!.roomId
                }
            }
        })
            .then(() => deleteAllBookingsForRoom(selectedRoom!.roomId))
            .then(() => setIsShowDeleteDialog(false))
            .then(() => setSelectedRoom(undefined))
            .catch(err => reportError(err, "", "RoomManagerComponent handleDeleteRoom"))
    }

    const handleUploadRoomPlan = async (event: ChangeEvent<HTMLInputElement>) => {
        let fileString = ""
        if (event.target.files) {
            fileString = await event.target.files[0].text();
        }

        if (event.target.files && isSvgFileValid(event.target.files[0].name, fileString)) {
            setRoomPlanSvgFile(event.target.files[0])
            const currentRoomPlanSeats = getSeatsFromPlan(currentRoomPlan)
            const newRoomPlanSeats = getSeatsFromPlan(fileString);
            const seatsToDelete: string[] = getRelativeComplement(currentRoomPlanSeats, newRoomPlanSeats);
            const seatsNew: string[] = getRelativeComplement(newRoomPlanSeats, currentRoomPlanSeats);

            setSeatsNewArrayIds([...seatsNew]);
            if (seatsToDelete.length > 0) {
                setSeatsToDeleteArrayIds(seatsToDelete);
                setShowUploadConfirmationDialog(true);
            } else {
                await uploadRoomPlanAfterConfirmation(event.target.files[0], seatsNew);
            }
        } else {
            setIsShowAlerts({...initialAlertStates, error: true});
            setTimeout(() => {
                setIsShowAlerts(initialAlertStates)
            }, 4000)
        }
    };

    const uploadRoomPlanAfterConfirmation = async (file?: File, newSeats?: string[]) => {
        const roomPlanId = roomPlanIdGenerator();

        setOnUploadLoading(true)
        await cleanupSeatsAndBookingsForUpdate().then(async () => {
            createNewSeatConfig(newSeats ? newSeats : seatsNewArrayIds);

            const preSignedUrlResponse = await refetchPreSignedUrl({
                writeAccess: true,
                roomId: (selectedRoom ? selectedRoom.roomId : ""),
                roomplanId: roomPlanId
            }).catch((err) => reportError(err, "", "RoomManagerComponent uploadRoomPlanAfterConfirmation"))

            if (!preSignedUrlResponse || !preSignedUrlResponse.data || !preSignedUrlResponse.data.getPresignedRoomplanUrl) {
                return
            }

            const fileToUpload = file ? file : roomPlanSvgFile;
            const preSignedUrl = preSignedUrlResponse.data.getPresignedRoomplanUrl
            await fetch(preSignedUrl, {method: 'PUT', body: fileToUpload})
                .then(response => {
                    validateFileUploadResponseAndUpdateRoomPlan(response, roomPlanId);
                    setShowUploadConfirmationDialog(false)
                    setSelectedRoom(undefined)
                })
                .catch((err) => reportError(err, "", "RoomManagerComponent uploadRoomPlanAfterConfirmation"))
        });

        function createNewSeatConfig(seatsNew: string[]) {
            seatsNew.forEach((el) => {
                createSeatConfigMutation({
                    variables: {
                        input: {
                            roomId: selectedRoom!.roomId,
                            seatName: el,
                            seatType: "",
                            inventory: [],
                            owner: "",
                        }
                    }
                }).catch((err) => reportError(err, "", "RoomManagerComponent createNewSeatConfig"))
            })
        }

        function roomPlanIdGenerator() {
            return Math.random().toString(36).substring(2, 10);
        }

        async function cleanupSeatsAndBookingsForUpdate() {
            let bookingsForRoom: Booking[] = [];
            const result = await refetch({roomId: selectedRoom!.roomId})
                .catch((err) => reportError(err, "", "RoomManagerComponent cleanupSeatsAndBookingsForUpdate"));

            if (result && result.data && result.data.bookingByRoom && result?.data?.bookingByRoom?.items?.length > 0) {
                bookingsForRoom = result.data.bookingByRoom.items;
            }
            deleteSeatsFromDatabase(bookingsForRoom);

            function deleteSeatsFromDatabase(bookingsForRoom: Booking[]) {
                seatsToDeleteArrayIds.forEach((seatId) => {
                    deleteBookingsForSeat(bookingsForRoom, seatId);
                    deleteSeatConfigBySeatId(seatId);
                });

                function deleteBookingsForSeat(bookingsForRoom: Booking[], seatId: string) {
                    bookingsForRoom.forEach((booking) => {
                        if (booking.seatId === seatId) {
                            deleteBookingByBookingIdAndDate(booking)
                        }
                    })
                }
            }
        }

        function validateFileUploadResponseAndUpdateRoomPlan(response: Response, roomPlanId: string) {
            if (response.status === 200) {
                updateRoomPlan(roomPlanId, true)
                setIsShowAlerts({...initialAlertStates, success: true})
                setTimeout(() => {
                    setIsShowAlerts(initialAlertStates)
                }, 4000)
            } else {
                console.error("roomplan " + roomPlanId + " for room " + selectedRoom!.roomId + " not uploaded")
                reportError(new Error(), "", "RoomManagerComponent validateFileUploadResponseAndUpdateRoomPlan")
            }
        }

    }

    const updateTimeDataTo00h00_23h59 = useCallback((bookings: Booking[]) => {
        bookings.forEach((booking: Booking) => {
            updateBookingMutation({
                variables: {
                    input: {
                        bookingId: booking.bookingId,
                        date: booking.date,
                        timeBegin: dayjs(booking.date).hour(0).minute(0).utc().toISOString(),
                        timeEnd: dayjs(booking.date).hour(23).minute(59).utc().toISOString()
                    },
                }
            })
                .catch(e => reportError(e, "", "RoomManagerComponent updateTimeDataTo00h00_23h59"))

        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDisableTimeBooking = useCallback((room: Room, timeActive: boolean) => {
        updateRoomMutation({
            variables: {
                input: {
                    roomId: room?.roomId,
                    isTimeActive: false
                }
            }
        })
            .then(() => publishRoomAccessChange({variables: {roomId: room?.roomId}}).then())
            .then(() => setIsShowingDisableTimeBookingConfirmationDialog(false))
            .catch((err) => reportError(err, "", "RoomManagerComponent handleDisableTimeBooking"))

        if (timeActive) handleChangeOfExistingTimeBookings(room.roomId)

        function handleChangeOfExistingTimeBookings(roomId: string) {
            getBookingsWhenTimeIsActive({roomId: roomId})
                .then((res: any) => updateTimeDataTo00h00_23h59(res.data.bookingByRoom.items!))
                .then(() => publishRoomAccessChange({variables: {roomId: roomId}}))
                .catch((err) => reportError(err, "", "RoomManagerComponent"))
        }

    }, [updateRoomMutation, publishRoomAccessChange, reportError, getBookingsWhenTimeIsActive, updateTimeDataTo00h00_23h59])

    const fillMultipleBookingsPerSeatList = useCallback((roomId: string) => {
        let listOfMultipleBookingsOnSeat: Booking[] = [];

        getBookingsOfRoomPlan({roomId: roomId})
            .then((result) => collectMultipleBookingsOnSeat(result.data.bookingByRoom.items))
            .catch((err) => reportError(err, "", "RoomManagerComponent fillMultipleBookingsPerSeatList"))
            .finally(handleFinally)

        function collectMultipleBookingsOnSeat(bookings: Booking[]) {
            let uniqueCombination: string[] = []
            let sortedBookings = makeArraySortedByBeginTime(bookings)

            sortedBookings.forEach((booking: Booking) => {

                const bookingCode = getBookingCode(booking);
                const isAnotherBookingExistsOnSeatOnTheSameDate = uniqueCombination.includes(bookingCode);

                (isAnotherBookingExistsOnSeatOnTheSameDate) ? listOfMultipleBookingsOnSeat.push(booking) : uniqueCombination.push(bookingCode)

            })

            function getBookingCode(booking: Booking): string {
                return booking.roomId + "_" + booking.seatId + "_" + booking.date
            }

            function makeArraySortedByBeginTime(unsortedBookingList: Booking[]): Booking[] {
                const arrayToSort = [...unsortedBookingList]; // can't sort the param directly because it's a readonly value
                return arrayToSort.sort((leftHand, rightHand) => {
                    const leftHandValue: number = leftHand.timeBegin == null ? Number.MAX_SAFE_INTEGER : Date.parse(leftHand.timeBegin)
                    const rightHandValue: number = rightHand.timeBegin == null ? Number.MAX_SAFE_INTEGER : Date.parse(rightHand.timeBegin)
                    return leftHandValue - rightHandValue
                })
            }
        }

        function handleFinally() {
            if (!isLoadingBookingsToDelete) return;

            const isThereMultipleBookingOnSeats = listOfMultipleBookingsOnSeat.length !== 0;
            if (isThereMultipleBookingOnSeats) {
                setIsShowingDisableTimeBookingConfirmationDialog(true)
            } else {
                handleDisableTimeBooking(roomToBeUpdatedAccordingToTimeFeature as Room, true)
                disableRoomWhileTransitioning(roomToBeUpdatedAccordingToTimeFeature as Room, false)
            }
            setIsLoadingBookingsToDelete(false)
            setMultipleBookingsPerSeat(listOfMultipleBookingsOnSeat)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getBookingsOfRoomPlan, handleDisableTimeBooking, isLoadingBookingsToDelete, roomToBeUpdatedAccordingToTimeFeature, updateTimeDataTo00h00_23h59])

    useEffect(function fetchMultipleBookingsPerSeat() {
        if (isLoadingBookingsToDelete) fillMultipleBookingsPerSeatList(roomToBeUpdatedAccordingToTimeFeature?.roomId ?? "");
    }, [fillMultipleBookingsPerSeatList, isLoadingBookingsToDelete, roomToBeUpdatedAccordingToTimeFeature]);

    function getTimeBookingDialogText(): string {
        let bookingsCount = multipleBookingsPerSeat.length;
        let errorMsg = "";
        if (bookingsCount === 1) {
            errorMsg += (t("message_disable_time_booking"))
        } else {
            errorMsg += (t("message_disable_time_bookings")).replace("%number", String(bookingsCount))
        }
        return errorMsg
    }

    const handleIsTimeBox = (room: Room, didTimeBecomeActive: boolean) => {
        setRoomToBeUpdatedAccordingToTimeFeature(room)

        if (didTimeBecomeActive) {
            updateRoomMutation({
                variables: {
                    input: {
                        roomId: room.roomId,
                        isTimeActive: true
                    }
                }
            })
                .then(() => publishRoomAccessChange({variables: {roomId: room.roomId}}))
                .then(() => handlePostTransitionToTimeActive())
                .catch((err) => reportError(err, "", "RoomManagerComponent, handleIsTimeBox"));
        } else {
            // this will fire a useEffect (fetchMultipleBookingsPerSeat) to check if there are multiple bookings on a seats
            // and this might lead to showing a confirmation dialog through (isShowingDisableTimeBookingConfirmationDialog)
            // since we needed a comment to explain the process, then it needs to be refactored
            setIsLoadingBookingsToDelete(true)
        }

        if (!room.isTimeActive) disableRoomWhileTransitioning(room, false)

        function handlePostTransitionToTimeActive() {
            if (didBookingsTimeDataUpdate) return;
            else updateTimeDataOfAllExistingBookings() //this methode should only be run once ever. It helps when first deploying
            // the feature booking with time to fill the undefined values of beginTime and endTime in the booking table

            function updateTimeDataOfAllExistingBookings() {
                getAllBookings()
                    .then((res) => updateTimeDataTo00h00_23h59(res.data.listBookings.items))
                    .then(() => updateDidBookingsTimeDataUpdate())
                    .catch((err: any) => console.error("RoomManagerComponent handlePostTransitionToTimeActive " + err))

                function updateDidBookingsTimeDataUpdate() {
                    updateAreBookingsTimeDataUpdatedMutation({
                        variables: {
                            input: {
                                id: "0",
                                didUpdate: true
                            }
                        }
                    })
                        .then(() => setDidBookingsTimeDataUpdate(true))
                        .catch(error => console.error("RoomManagerComponent updateDidBookingsTimeDataUpdate " + error))
                }
            }
        }
    }

    const disableRoomWhileTransitioning = (room: Room, disableBooking: boolean) => {
        updateRoomMutation({
            variables: {
                input: {
                    roomId: room.roomId,
                    isBookingDisabled: disableBooking
                }
            }
        })
            .then(() => publishRoomAccessChange({variables: {roomId: room.roomId}}))
            .catch((err) => reportError(err, "", "RoomManagerComponent, disableRoomWhileTransitioning"))
    }

    const handleOkButtonOfTimeBookingDialog = () => {
        handeDeleteMultipleBookingsPerSeat();
        setTimeout(() => handleDisableTimeBooking(roomToBeUpdatedAccordingToTimeFeature as Room, true), 500)
        disableRoomWhileTransitioning(roomToBeUpdatedAccordingToTimeFeature as Room, false)

        function handeDeleteMultipleBookingsPerSeat() {
            multipleBookingsPerSeat.forEach((booking) => {
                deleteBookingMutation({
                    variables: {
                        input: {
                            bookingId: booking?.bookingId,
                            date: booking?.date
                        }
                    }
                })
                    .then(() => removeDoubledBookingFromList(booking.bookingId))
                    .catch((err) => reportError(err, "", "RoomManagerComponent handeDeleteMultipleBookingsPerSeat"))
            })

            function removeDoubledBookingFromList(bookingId: string) {
                setMultipleBookingsPerSeat((prev) => prev.filter(booking => booking.bookingId !== bookingId));
            }
        }
    }

    const handleCancelButtonOfTimeBookingDialog = () => {
        setIsShowingDisableTimeBookingConfirmationDialog(false)
        disableRoomWhileTransitioning(roomToBeUpdatedAccordingToTimeFeature as Room, false)
    }

    const handleDeleteRoomPlan = async () => {
        setOnDeleteLoading(true);
        await deleteAllBookingsForRoom(selectedRoom!.roomId)
        const currentRoomPlanSeats = getSeatsFromPlan(currentRoomPlan)
        currentRoomPlanSeats.forEach((seatId) => {
            deleteSeatConfigBySeatId(seatId);
        })
        updateRoomPlan(selectedRoom!.roomPlanId, false)
    };

    const handleCreateRoom = () => {
        setSelectedRoom(undefined)
        setShowCreateRoom(true)
    }
    const {t} = useTranslation();
    return (
        <Dialog fullWidth={true} maxWidth={"lg"} style={{width: "100%", flexGrow: 1}} open={showRoomManager}
                data-testid={"roomManager"}>
            {isShowAlerts.error && <Alert severity="error">{t('message_error_file_type')}</Alert>}
            {isShowAlerts.success && <Alert severity="success">{t('message_success_uploaded_file')}</Alert>}
            {isShowAlerts.error_create_room && <Alert severity="error">{t('message_error_create_room')} </Alert>}
            <DialogTitle>{t("room_management_dialog-title")}</DialogTitle>
            <ClickAwayListener onClickAway={() => {
                if (!showSeatManagerDialog) {
                    setSelectedRoom(undefined)
                }
            }}>
                <Box>
                    {!disableRoomManagerInteraction &&
                        <DialogContent>
                            {currentUser.isAdmin || currentUser.isOrgUnitAdmin ?
                                <RoomManagerAdminForm rooms={rooms}/>
                                :
                                <div data-testid={"org-unit-label"}>{t("daily_bookings_table_orgunit_column")}
                                    <div
                                        data-testid={"org-unit-label-content"}>{orgUnitList[0] && orgUnitList[0].orgName}</div>
                                </div>
                            }

                            {showCreateRoom &&
                                <CreateRoomComponent
                                    setIsShowAlerts={setIsShowAlerts}
                                    roomToEdit={selectedRoom}
                                    preselectedOrgUnitId={selectedOrgUnit?.orgId}
                                    setShowCreateRoom={setShowCreateRoom}
                                    orgUnitList={orgUnitList}
                                    handleUpdateOrgUnitIdForBookings={handleUpdateOrgUnitIdForBookings}
                                    isAdmin={currentUser.isAdmin || currentUser.isOrgUnitAdmin}
                                />}
                            {isShowDeleteDialog && <Dialog open={isShowDeleteDialog}>
                                <DialogContent>
                                    {t("delete_room_dialog_content")}
                                    <DialogActions>
                                        <Button variant={"contained"} color={"primary"} component={"span"}
                                                onClick={handleDeleteRoom}>
                                            {t("confirm_dialog_ok_button-text")}
                                        </Button>
                                        <Button variant={"contained"} color={"primary"} component={"span"}
                                                onClick={() => setIsShowDeleteDialog(false)}>
                                            {t("confirm_dialog_cancel_button-text")}
                                        </Button>
                                    </DialogActions>
                                </DialogContent>
                            </Dialog>}

                            {isShowingDisableTimeBookingConfirmationDialog &&
                                <Dialog open={isShowingDisableTimeBookingConfirmationDialog}>
                                    <DialogContent data-testid={"dialogContent-changeIsTimed-test"}>
                                        <DialogContentText data-testid={"dialogContentText"}>
                                            {getTimeBookingDialogText()}
                                        </DialogContentText>
                                        <DialogActions>
                                            <Button variant={"contained"} color={"primary"} component={"span"}
                                                    onClick={handleOkButtonOfTimeBookingDialog}>
                                                {t("confirm_dialog_ok_button-text")}
                                            </Button>
                                            <Button variant={"contained"} color={"primary"} component={"span"}
                                                    onClick={handleCancelButtonOfTimeBookingDialog}>
                                                {t("confirm_dialog_cancel_button-text")}
                                            </Button>
                                        </DialogActions>
                                    </DialogContent>
                                </Dialog>}

                            {selectedRoom &&
                                <SeatManagerComponent room={selectedRoom}
                                                      showSeatManager={showSeatManagerDialog}
                                                      setShowSeatManager={setShowSeatManagerDialog}
                                                      cognitoUserList={cognitoUserList.userList}
                                />
                            }
                            <RoomManagerTableComponent
                                rooms={rooms}
                                selectedOrgUnitId={selectedOrgUnit?.orgId}
                                selectedRoom={selectedRoom}
                                setSelectedRoom={setSelectedRoom}
                                setShowSeatConfig={setShowSeatManagerDialog}
                                handleIsTimeBox={handleIsTimeBox}
                            />

                        </DialogContent>
                    }
                    <Divider/>
                    <DialogActions style={{display: "flex", justifyContent: "center", alignItems: "stretch"}}>
                        <RoomManagerUploadSvgButton handleUploadRoomPlan={handleUploadRoomPlan}
                                                    selectedRoom={selectedRoom} uploadStartIcon={uploadStartIcon}/>
                        <Button variant={"contained"} color={"primary"} startIcon={deleteStartIcon}
                                disabled={!selectedRoom?.roomPlanExisting}
                                onClick={handleDeleteRoomPlan}
                                data-testid={"btn-delete"}
                        >
                            {t("button_svg_delete-text")}
                        </Button>
                        <Button variant={"contained"} color={"primary"} startIcon={<Edit/>}
                                disabled={!selectedRoom}
                                onClick={() => setShowCreateRoom(true)}
                                data-testid={"btn-edit-room"}
                        >
                            {t("inv_mngmnt_edit_button-text")}
                        </Button>
                        <Button variant={"contained"} color={"primary"} startIcon={<DeleteForeverIcon/>}
                                disabled={!selectedRoom}
                                onClick={() => setIsShowDeleteDialog(true)}
                                data-testid={"btn-delete-room"}
                        >
                            {t("delete")}
                        </Button>
                    </DialogActions>
                    <Divider/>

                    <DialogActions>
                        <Button
                            onClick={handleCreateRoom}
                            color={"primary"}
                            variant={"contained"}
                            data-testid={"create-btn"}
                            disabled={disableRoomManagerInteraction}
                        >
                            {t("button_create_room-text")}
                        </Button>
                        <Button
                            onClick={() => setShowRoomManager(false)}
                            color={"primary"}
                            variant={"contained"}
                            data-testid={"close-btn"}
                        >
                            {t("button_close_room_management_dialog-text")}
                        </Button>
                    </DialogActions>
                </Box>
            </ClickAwayListener>
            <RoomManagerUploadConfirmationDialog
                showUploadConfirmationDialog={showUploadConfirmationDialog}
                onCloseUploadConfirmationDialog={onCloseUploadConfirmationDialog}
                onUploadLoading={onUploadLoading}
                seatsToDeleteArrayIds={seatsToDeleteArrayIds}
                uploadRoomPlanAfterConfirmation={uploadRoomPlanAfterConfirmation}
            ></RoomManagerUploadConfirmationDialog>
        </Dialog>
    )
}
export default RoomManagerComponent;

