import {Booking, MeetingRoomBooking, Room} from "../../API";
import React, {CSSProperties, useCallback, useMemo, useRef, useState} from "react";
import "../../styles/styleRoomPlan.css"
import {useBookingList} from "../../hooks/useBookingList";
import {Dialog} from "@mui/material";
import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";
import ZoomControlComponent from "./ZoomControlComponent";
import {User} from "../../services/UserClient";
import {useBookingLimitation} from "../../hooks/useBookingLimitation";
import RoomPlanPanningComponent from "./RoomPlanPanningComponent";
import TwoGetherSVGLoader from "../svgComponents/TwoGetherSVGLoader";
import {useRoomPlanFromS3} from "../../hooks/useRoomPlanFromS3";
import {useOutsideDivClick} from "../../hooks/useOutsideDivClick";
import RoomPlanContext from "../../context/RoomPlanContext";
import {itemToDisplay} from "../../types/RoomPlanContextTypes";
import RoomPlanHoverDialog from "./RoomPlanHoverDialog";
import {SeatBookings} from "../../types/SeatBookinType";
import {useFilterContext} from "../../hooks/useFilterContext";
import ItemClickedDialog from "./ItemClickedDialogComponents/ItemClickedDialog";
import {MeetingRoomBookings} from "../../types/MeetingRoomBookingType";
import BookingMeetingRoomList from "../svgComponents/meetingRoomRenderComponents/BookingRoomMeetingList";
import {BookingType} from "../../types/BookingType";
import {BookingDialog, BookingDialogProps} from "../BookingDialog/BookingDialog";


interface Props {
    room: Room
    date: Date
    currentUser: User
}


export function highlightSeats(element: Element, predicate: boolean) {
    predicate ?
        element.setAttribute("class", "highlighted") :
        element.setAttribute("class", "")
}

const RoomPlanComponent = (props: Props) => {
    const {
        room,
        date,
        currentUser,
    } = props

    const dateISO = date.toLocaleDateString('sv', {timeZone: 'Europe/Berlin'}).substring(0, 10)

    const [selectedSeat, setSelectedSeat] = useState<string>("");
    const [currentRoomCap, setCurrentRoomCap] = useState<number>(0);

    // TODO: Maybe possible to get rid of these states
    const [clickedOnBookedSeat, setClickedOnBookedSeat] = useState<boolean>(false);
    const [selectedSeatIsBookedByMe, setSelectedSeatIsBookedByMe] = useState<boolean>(false);
    const [, setShowAlreadyBookedHint] = useState(false);
    const [showMultiBookingDialog, setShowMultiBookingDialog] = useState(false);
    const [showMultiBookingMeetingRoomDialog, setShowMultiBookingMeetingRoomDialog] = useState(false);
    const [showItemClickedDialog, setShowItemClickedDialog] = useState(false);
    const [deletableBookings, setDeletableBookings] = useState<(Booking | MeetingRoomBooking)[]>([])
    const [itemsToDisplay, setItemsToDisplay] = useState<itemToDisplay[]>([])
    const [bookingsToDisplay, setBookingsToDisplay] = useState<(Booking | MeetingRoomBooking)[]>([])
    const [currentMeetingRoomBookings, setCurrentMeetingRoomBookings] = useState<MeetingRoomBookings | null>(null);
    const [isDeletingMeetingRoomBookings, setIsDeletingMeetingRoomBookings] = useState<boolean>(false);

    const bookingConfig = useBookingLimitation(room.orgUnitId ? room.orgUnitId : "");
    const zoomContext = useRef<any>(null);
    const roomPlanContainerRef = useRef<any>(null);
    const mouse = useRef({x: 0, y: 0});

    const roomPlan = useRoomPlanFromS3(room)
    const bookingList = useBookingList((room ? room.roomId : ""), ((room?.orgUnitId) ? room.orgUnitId : ""), dateISO)

    const initialScale = (room?.roomPlanScaleFactor) ? room.roomPlanScaleFactor / 100 : 1;
    let minScale = Math.min(initialScale, 0.5);
    const maxScale = 10;

    const {isRoomDropdownFocussed, isOrgUnitDropdownFocused} = useFilterContext();

    const offsetPopUpDialog: number = 10;

    const handleMouseMove = (e: React.MouseEvent) => {
        mouse.current = {
            ...mouse.current,
            x: e.pageX,
            y: e.pageY + 12
        }
    }

    const handleClickOnSeat = useCallback((selectedSeatBookings: Booking[]) => {
        let _deletableBookings: Booking[] = [];
        if (selectedSeatBookings.length) {
            setClickedOnBookedSeat(true) //TODO Is it possible to get rid of these states? Look into Multibooking Component
            if (currentUser.isAdmin)
                _deletableBookings = selectedSeatBookings
            else {
                let myBookingsAtSelectedSeat = selectedSeatBookings.filter(booking => booking.bookerId === currentUser.ID)
                if (myBookingsAtSelectedSeat) {
                    setSelectedSeatIsBookedByMe(true) //TODO Is it possible to get rid of these states? Look into Multibooking Component
                    _deletableBookings = myBookingsAtSelectedSeat
                }
            }
            if (_deletableBookings.length > 0) {
                setDeletableBookings(_deletableBookings);
                setShowItemClickedDialog(true);
            } else {
                setShowMultiBookingDialog(true)
            }
        } else {
            setShowMultiBookingDialog(true)
        }
    }, [currentUser.ID, currentUser.isAdmin])

    const handleClickOnMeetingRoom = useCallback((selectedMeetingRoomBookings: MeetingRoomBooking[]) => {
        let _deletableBookings: MeetingRoomBooking[] = [];
        if (selectedMeetingRoomBookings.length) {
            setClickedOnBookedSeat(true) //TODO Is it possible to get rid of these states? Look into Multibooking Component
            if (currentUser.isAdmin || currentUser.isOrgUnitAdmin)
                _deletableBookings = selectedMeetingRoomBookings
            else {
                let myBookingsAtSelectedSeat = selectedMeetingRoomBookings.filter(booking => booking.bookerId === currentUser.ID)
                if (myBookingsAtSelectedSeat) {
                    setSelectedSeatIsBookedByMe(true) //TODO Is it possible to get rid of these states? Look into Multibooking Component
                    _deletableBookings = myBookingsAtSelectedSeat
                }
            }
            if (_deletableBookings.length > 0) {
                setDeletableBookings(_deletableBookings);
                setIsDeletingMeetingRoomBookings(true);
                setShowItemClickedDialog(true);
            } else {
                setShowMultiBookingMeetingRoomDialog(true)
            }
        } else {
            setShowMultiBookingMeetingRoomDialog(true)
        }
    }, [currentUser.ID, currentUser.isAdmin, currentUser.isOrgUnitAdmin])

    const onSeatClick = useCallback((selectedSeatBookings: SeatBookings) => {
        setSelectedSeat(selectedSeatBookings.seat.seatID)
        handleClickOnSeat(selectedSeatBookings.bookings)
    }, [handleClickOnSeat])

    // MeetingRooms are still treated as Seats
    //TODO ask why roomCap was nullable
    const onMeetingRoomClick = useCallback((selectedMeetingRoomBookings: MeetingRoomBookings) => {
        setSelectedSeat(selectedMeetingRoomBookings.meetingRoom.meetingRoomID)
        setCurrentRoomCap(selectedMeetingRoomBookings.meetingRoom.roomCap)
        setCurrentMeetingRoomBookings(selectedMeetingRoomBookings)
        handleClickOnMeetingRoom(selectedMeetingRoomBookings.bookings)
    }, [handleClickOnMeetingRoom])

    const closeDeleteBookingDialog = () => {
        setShowItemClickedDialog(false)
        setSelectedSeat("")
        setSelectedSeatIsBookedByMe(false)
        setClickedOnBookedSeat(false)
    }

    const outsideClickRef = useOutsideDivClick(function handleOutsideClick() {
        setShowAlreadyBookedHint(false)
        setShowItemClickedDialog(false)
        setIsDeletingMeetingRoomBookings(false)
        setCurrentMeetingRoomBookings(null)
        setCurrentRoomCap(0)
        setDeletableBookings([])
        setSelectedSeat("")
    })

    const styleHoverTableCell: CSSProperties = {
        textAlign: 'left',
        padding: "4px",
        borderBottom: "none",
        backgroundColor: "transparent"
    };

    const renderRoomPlanHoverDialog = useMemo(() => {
        if (itemsToDisplay.length > 0) {
            return (
                <RoomPlanHoverDialog mousePosX={mouse.current.x} mousePosY={mouse.current.y}
                                     offset={offsetPopUpDialog} styleCell={styleHoverTableCell}/>
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsToDisplay])

    const renderMeetingRoomBookingListHoverDialog = useMemo(() => {
        if (bookingsToDisplay.length > 0) {
            return (
                <BookingMeetingRoomList mousePosX={mouse.current.x} mousePosY={mouse.current.y}
                                        offset={offsetPopUpDialog} currentUserID={currentUser.ID}
                                        styleCell={styleHoverTableCell}/>
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingsToDisplay])

    const renderSVG = useMemo(() => {
        return (
            <TwoGetherSVGLoader onSeatClick={onSeatClick}
                                onMeetingRoomClick={onMeetingRoomClick}
                                bookingList={bookingList}
                                roomId={room.roomId}
                                RoomPlan={roomPlan}
                                isTimeBookingActive={!!room.isTimeActive}
            />
        )
    }, [onSeatClick, onMeetingRoomClick, bookingList, room.roomId, room.isTimeActive, roomPlan])

    const renderRoomPlan = useMemo(() => {
        return (
            <div>
                {room &&
                    <div style={{
                        overflowY: "hidden",
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <TransformWrapper
                            key={room.roomId}
                            ref={zoomContext}
                            initialScale={initialScale}
                            minScale={minScale}
                            maxScale={maxScale}
                            doubleClick={{disabled: true}}
                            panning={{disabled: false, velocityDisabled: true}}
                            centerOnInit={true}
                            limitToBounds={false}
                            centerZoomedOut={false}
                        >
                            {({zoomIn, zoomOut, centerView}: any) => (
                                <React.Fragment>
                                    <ZoomControlComponent
                                        zoomIn={zoomIn}
                                        zoomOut={zoomOut}
                                        reset={() => {
                                            centerView(initialScale);
                                        }
                                        }
                                        zoomInDisabled={zoomContext?.current?.state.scale >= maxScale}
                                        zoomOutDisabled={zoomContext.current === null || (zoomContext.current.state && zoomContext.current.state.scale <= minScale)}
                                    />
                                    <div className={"divTransform"} onMouseMove={handleMouseMove}>
                                        <TransformComponent>
                                            <div className={"divSVG"} ref={roomPlanContainerRef}>
                                                {renderSVG}
                                            </div>
                                        </TransformComponent>
                                    </div>
                                </React.Fragment>
                            )}
                        </TransformWrapper>
                    </div>
                }
            </div>
        )
    }, [initialScale, minScale, renderSVG, room])

    const paperProps = useMemo(() => {
        return {style: {display: "inline-table"}}
    }, [])

    const bookingDialogSeat = useMemo(() => {
        function handleOnClose() {
            setShowMultiBookingDialog(false);
            setSelectedSeat("");
            setClickedOnBookedSeat(false);
        }

        const bookingDialogProps: BookingDialogProps = {
            bookingType: "seat",
            handleClose: () => handleOnClose(),
            dateSelectedInCalendar: date,
            bookerName: currentUser.name,
            bookerGivenName: currentUser.givenName,
            bookerFamilyName: currentUser.familyName,
            room: room,
            seatId: selectedSeat,
            selectedSeatIsBookedOnSelectedDay: clickedOnBookedSeat,
            selectedSeatIsBookedByMeOnSelectedDay: selectedSeatIsBookedByMe,
            bookingConfig: bookingConfig,
            bookingList: bookingList,
            roomCap: 0
        }

        return (
            <Dialog PaperProps={paperProps} open={showMultiBookingDialog} onClose={handleOnClose}>
                <BookingDialog {...bookingDialogProps}/>
            </Dialog>
        )
    }, [date, currentUser.name, currentUser.givenName, currentUser.familyName, room, selectedSeat, clickedOnBookedSeat, selectedSeatIsBookedByMe, bookingConfig, bookingList, paperProps, showMultiBookingDialog])

    const renderItemClickedDialog = useMemo(() => {

        const bookingType: BookingType = isDeletingMeetingRoomBookings ? "meetingRoom" : "seat";
        const bookOtherDateTime = () => {
            setShowItemClickedDialog(false);
            if (!isDeletingMeetingRoomBookings) {
                setShowMultiBookingDialog(true);
                return;
            }
            setShowMultiBookingMeetingRoomDialog(true);
            setIsDeletingMeetingRoomBookings(false);
        }

        return <>
            {showItemClickedDialog &&
                <div ref={outsideClickRef}>
                    <ItemClickedDialog
                        bookingType={bookingType}
                        position={{left: mouse.current.x, top: mouse.current.y}}
                        offset={offsetPopUpDialog}
                        isTimeActive={room.isTimeActive!}
                        deletableBookings={deletableBookings}
                        handleBookOtherDateTime={bookOtherDateTime}
                        dateISO={dateISO}
                        closeDeleteBookingDialog={closeDeleteBookingDialog}
                        meetingRoomBookingProps={currentMeetingRoomBookings}
                    />
                </div>
            }
        </>

    }, [isDeletingMeetingRoomBookings, showItemClickedDialog, outsideClickRef, room.isTimeActive, deletableBookings, dateISO, currentMeetingRoomBookings])

    const providerValues = useMemo(() => ({
        currentUserID: props.currentUser.ID,
        itemsToDisplay,
        setItemsToDisplay,
        bookingsToDisplay,
        setBookingsToDisplay
    }), [bookingsToDisplay, itemsToDisplay, props.currentUser.ID]);

    const bookingDialogMeetingRoom = useMemo(() => {

        const handleCloseBookingDialog = () => {
            setShowMultiBookingMeetingRoomDialog(false);
            setSelectedSeat("");
            setClickedOnBookedSeat(false);
            setCurrentMeetingRoomBookings(null);
        }

        const bookingDialogProps: BookingDialogProps = {
            bookingType: "meetingRoom",
            handleClose: () => handleCloseBookingDialog(),
            dateSelectedInCalendar: date,
            bookerName: currentUser.name,
            bookerGivenName: currentUser.givenName,
            bookerFamilyName: currentUser.familyName,
            room: room,
            seatId: selectedSeat,
            selectedSeatIsBookedOnSelectedDay: clickedOnBookedSeat,
            selectedSeatIsBookedByMeOnSelectedDay: selectedSeatIsBookedByMe,
            bookingConfig: bookingConfig,
            bookingList: bookingList,
            roomCap: currentRoomCap,
        }

        return (
            <Dialog PaperProps={paperProps} open={showMultiBookingMeetingRoomDialog}
                    onClose={handleCloseBookingDialog}>
                <BookingDialog {...bookingDialogProps}/>
            </Dialog>
        )
    }, [bookingConfig, bookingList, clickedOnBookedSeat, currentRoomCap, currentUser.familyName, currentUser.givenName, currentUser.name, date, paperProps, room, selectedSeat, selectedSeatIsBookedByMe, showMultiBookingMeetingRoomDialog])

    const renderRoomPlanComponent = useMemo(() => {
        return (
            <RoomPlanContext.Provider value={providerValues}>
                {renderRoomPlan}
                {renderItemClickedDialog}
                {bookingDialogSeat}
                {bookingDialogMeetingRoom}
                <RoomPlanPanningComponent isRoomDropdownFocussed={isRoomDropdownFocussed}
                                          isOrgUnitDropdownFocused={isOrgUnitDropdownFocused} zoomContext={zoomContext}
                                          roomPlanContainerRef={roomPlanContainerRef}/>
                {renderRoomPlanHoverDialog}
                {renderMeetingRoomBookingListHoverDialog}
            </RoomPlanContext.Provider>
        )
    }, [providerValues, renderRoomPlan, bookingDialogSeat, renderItemClickedDialog, bookingDialogMeetingRoom, isRoomDropdownFocussed, isOrgUnitDropdownFocused, renderRoomPlanHoverDialog, renderMeetingRoomBookingListHoverDialog])

    return (<>{renderRoomPlanComponent}</>)
}
export default RoomPlanComponent