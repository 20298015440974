export const deleteBookingNoRoomResponse = /* GraphQL */ `
  mutation DeleteBooking(
    $input: DeleteBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    deleteBooking(input: $input, condition: $condition) {
      bookingId
      date
      seatId
      roomId
      bookerId
      bookerName
      bookerGivenName
      bookerFamilyName
      orgUnitId
      bookingFor
      timeBegin
      timeEnd
      createdAt
      updatedAt
    }
  }
`;

export const deleteMeetRoomBookingNoRoomResponse = /* GraphQL */ `
  mutation DeleteMeetingRoomBooking(
    $input: DeleteMeetingRoomBookingInput!
    $condition: ModelMeetingRoomBookingConditionInput
  ) {
    deleteMeetingRoomBooking(input: $input, condition: $condition) {
      bookingId
      date
      meetingRoomId
      roomId
      bookerId
      bookerName
      bookerGivenName
      bookerFamilyName
      orgUnitId
      timeBegin
      timeEnd
      meetingName
      roomCapacity
      participantNumber
      meetingType
      createdAt
      updatedAt
    }
  }
`;
