import React, {Dispatch, SetStateAction} from 'react';
import GroupWorkIcon from '@mui/icons-material/GroupWork';

interface Coordinates {
    x: number,
    y: number,
}

type Dimensions = {
    width: number,
    height: number
}

interface Props {
    elementPosition: Coordinates
    elementDimensions: Dimensions
    isFiltered: boolean | undefined
    setMouseOver: Dispatch<SetStateAction<boolean>>
}

function MeetingRoomIcon(props: Props) {
    const onMouseEnter = () => {
        props.setMouseOver(true)
    }
    const onMouseLeave = () => {
        props.setMouseOver(false)
    }

    return (
        <g transform={`translate(${props.elementPosition.x}, ${props.elementPosition.y})`}
           className={props.isFiltered ? "highlighted" : "meetingRoomIcon"}
           onMouseOver={onMouseEnter} onMouseOut={onMouseLeave}>
            <GroupWorkIcon stroke="black" width={props.elementDimensions.width} height={props.elementDimensions.height}
                           visibility={"visible"}/>
        </g>
    );
}

export default MeetingRoomIcon;