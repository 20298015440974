/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const publishRoomAccessChange = /* GraphQL */ `
  mutation PublishRoomAccessChange($roomId: ID!) {
    publishRoomAccessChange(roomId: $roomId)
  }
`;
export const createBookings = /* GraphQL */ `
  mutation CreateBookings($input: [CreateBookingInput]) {
    createBookings(input: $input) {
      Items {
        bookingId
        date
        seatId
        roomId
        bookerId
        bookerName
        bookerGivenName
        bookerFamilyName
        orgUnitId
        bookingFor
        timeBegin
        timeEnd
        createdAt
        updatedAt
      }
      dates
      roomId
      orgUnitId
    }
  }
`;
export const createMeetingRoomBookings = /* GraphQL */ `
  mutation CreateMeetingRoomBookings($input: [CreateMeetingRoomBookingInput]) {
    createMeetingRoomBookings(input: $input) {
      Items {
        bookingId
        date
        meetingRoomId
        roomId
        bookerId
        bookerName
        bookerGivenName
        bookerFamilyName
        orgUnitId
        timeBegin
        timeEnd
        meetingName
        roomCapacity
        participantNumber
        meetingType
        createdAt
        updatedAt
      }
      dates
      roomId
      orgUnitId
    }
  }
`;
export const createRoom = /* GraphQL */ `
  mutation CreateRoom(
    $input: CreateRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    createRoom(input: $input, condition: $condition) {
      roomId
      orgUnitId
      name
      nameLowerCased
      roomPlanExisting
      roomPlanId
      roomPlanScaleFactor
      seats {
        nextToken
      }
      isActive
      isTimeActive
      isBookingDisabled
      isDefault
      isPublic
      createdAt
      updatedAt
    }
  }
`;
export const updateRoom = /* GraphQL */ `
  mutation UpdateRoom(
    $input: UpdateRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    updateRoom(input: $input, condition: $condition) {
      roomId
      orgUnitId
      name
      nameLowerCased
      roomPlanExisting
      roomPlanId
      roomPlanScaleFactor
      seats {
        nextToken
      }
      isActive
      isTimeActive
      isBookingDisabled
      isDefault
      isPublic
      createdAt
      updatedAt
    }
  }
`;
export const deleteRoom = /* GraphQL */ `
  mutation DeleteRoom(
    $input: DeleteRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    deleteRoom(input: $input, condition: $condition) {
      roomId
      orgUnitId
      name
      nameLowerCased
      roomPlanExisting
      roomPlanId
      roomPlanScaleFactor
      seats {
        nextToken
      }
      isActive
      isTimeActive
      isBookingDisabled
      isDefault
      isPublic
      createdAt
      updatedAt
    }
  }
`;
export const createBooking = /* GraphQL */ `
  mutation CreateBooking(
    $input: CreateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    createBooking(input: $input, condition: $condition) {
      bookingId
      date
      seatId
      roomId
      room {
        roomId
        orgUnitId
        name
        nameLowerCased
        roomPlanExisting
        roomPlanId
        roomPlanScaleFactor
        isActive
        isTimeActive
        isBookingDisabled
        isDefault
        isPublic
        createdAt
        updatedAt
      }
      bookerId
      bookerName
      bookerGivenName
      bookerFamilyName
      orgUnitId
      bookingFor
      timeBegin
      timeEnd
      createdAt
      updatedAt
    }
  }
`;
export const updateBooking = /* GraphQL */ `
  mutation UpdateBooking(
    $input: UpdateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    updateBooking(input: $input, condition: $condition) {
      bookingId
      date
      seatId
      roomId
      room {
        roomId
        orgUnitId
        name
        nameLowerCased
        roomPlanExisting
        roomPlanId
        roomPlanScaleFactor
        isActive
        isTimeActive
        isBookingDisabled
        isDefault
        isPublic
        createdAt
        updatedAt
      }
      bookerId
      bookerName
      bookerGivenName
      bookerFamilyName
      orgUnitId
      bookingFor
      timeBegin
      timeEnd
      createdAt
      updatedAt
    }
  }
`;
export const deleteBooking = /* GraphQL */ `
  mutation DeleteBooking(
    $input: DeleteBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    deleteBooking(input: $input, condition: $condition) {
      bookingId
      date
      seatId
      roomId
      room {
        roomId
        orgUnitId
        name
        nameLowerCased
        roomPlanExisting
        roomPlanId
        roomPlanScaleFactor
        isActive
        isTimeActive
        isBookingDisabled
        isDefault
        isPublic
        createdAt
        updatedAt
      }
      bookerId
      bookerName
      bookerGivenName
      bookerFamilyName
      orgUnitId
      bookingFor
      timeBegin
      timeEnd
      createdAt
      updatedAt
    }
  }
`;
export const createMeetingRoomBooking = /* GraphQL */ `
  mutation CreateMeetingRoomBooking(
    $input: CreateMeetingRoomBookingInput!
    $condition: ModelMeetingRoomBookingConditionInput
  ) {
    createMeetingRoomBooking(input: $input, condition: $condition) {
      bookingId
      date
      meetingRoomId
      roomId
      room {
        roomId
        orgUnitId
        name
        nameLowerCased
        roomPlanExisting
        roomPlanId
        roomPlanScaleFactor
        isActive
        isTimeActive
        isBookingDisabled
        isDefault
        isPublic
        createdAt
        updatedAt
      }
      bookerId
      bookerName
      bookerGivenName
      bookerFamilyName
      orgUnitId
      timeBegin
      timeEnd
      meetingName
      roomCapacity
      participantNumber
      meetingType
      createdAt
      updatedAt
    }
  }
`;
export const updateMeetingRoomBooking = /* GraphQL */ `
  mutation UpdateMeetingRoomBooking(
    $input: UpdateMeetingRoomBookingInput!
    $condition: ModelMeetingRoomBookingConditionInput
  ) {
    updateMeetingRoomBooking(input: $input, condition: $condition) {
      bookingId
      date
      meetingRoomId
      roomId
      room {
        roomId
        orgUnitId
        name
        nameLowerCased
        roomPlanExisting
        roomPlanId
        roomPlanScaleFactor
        isActive
        isTimeActive
        isBookingDisabled
        isDefault
        isPublic
        createdAt
        updatedAt
      }
      bookerId
      bookerName
      bookerGivenName
      bookerFamilyName
      orgUnitId
      timeBegin
      timeEnd
      meetingName
      roomCapacity
      participantNumber
      meetingType
      createdAt
      updatedAt
    }
  }
`;
export const deleteMeetingRoomBooking = /* GraphQL */ `
  mutation DeleteMeetingRoomBooking(
    $input: DeleteMeetingRoomBookingInput!
    $condition: ModelMeetingRoomBookingConditionInput
  ) {
    deleteMeetingRoomBooking(input: $input, condition: $condition) {
      bookingId
      date
      meetingRoomId
      roomId
      room {
        roomId
        orgUnitId
        name
        nameLowerCased
        roomPlanExisting
        roomPlanId
        roomPlanScaleFactor
        isActive
        isTimeActive
        isBookingDisabled
        isDefault
        isPublic
        createdAt
        updatedAt
      }
      bookerId
      bookerName
      bookerGivenName
      bookerFamilyName
      orgUnitId
      timeBegin
      timeEnd
      meetingName
      roomCapacity
      participantNumber
      meetingType
      createdAt
      updatedAt
    }
  }
`;
export const createInventory = /* GraphQL */ `
  mutation CreateInventory(
    $input: CreateInventoryInput!
    $condition: ModelInventoryConditionInput
  ) {
    createInventory(input: $input, condition: $condition) {
      inventoryId
      orgUnitId
      type
      name
      nameLowerCased
      createdAt
      updatedAt
    }
  }
`;
export const updateInventory = /* GraphQL */ `
  mutation UpdateInventory(
    $input: UpdateInventoryInput!
    $condition: ModelInventoryConditionInput
  ) {
    updateInventory(input: $input, condition: $condition) {
      inventoryId
      orgUnitId
      type
      name
      nameLowerCased
      createdAt
      updatedAt
    }
  }
`;
export const deleteInventory = /* GraphQL */ `
  mutation DeleteInventory(
    $input: DeleteInventoryInput!
    $condition: ModelInventoryConditionInput
  ) {
    deleteInventory(input: $input, condition: $condition) {
      inventoryId
      orgUnitId
      type
      name
      nameLowerCased
      createdAt
      updatedAt
    }
  }
`;
export const createSeatConfig = /* GraphQL */ `
  mutation CreateSeatConfig(
    $input: CreateSeatConfigInput!
    $condition: ModelSeatConfigConditionInput
  ) {
    createSeatConfig(input: $input, condition: $condition) {
      seatName
      roomId
      seatType
      inventory
      isSeatHeightAdjustable
      owner
      isOwnerRegistered
      createdAt
      updatedAt
    }
  }
`;
export const updateSeatConfig = /* GraphQL */ `
  mutation UpdateSeatConfig(
    $input: UpdateSeatConfigInput!
    $condition: ModelSeatConfigConditionInput
  ) {
    updateSeatConfig(input: $input, condition: $condition) {
      seatName
      roomId
      seatType
      inventory
      isSeatHeightAdjustable
      owner
      isOwnerRegistered
      createdAt
      updatedAt
    }
  }
`;
export const deleteSeatConfig = /* GraphQL */ `
  mutation DeleteSeatConfig(
    $input: DeleteSeatConfigInput!
    $condition: ModelSeatConfigConditionInput
  ) {
    deleteSeatConfig(input: $input, condition: $condition) {
      seatName
      roomId
      seatType
      inventory
      isSeatHeightAdjustable
      owner
      isOwnerRegistered
      createdAt
      updatedAt
    }
  }
`;
export const createBookingConfig = /* GraphQL */ `
  mutation CreateBookingConfig(
    $input: CreateBookingConfigInput!
    $condition: ModelBookingConfigConditionInput
  ) {
    createBookingConfig(input: $input, condition: $condition) {
      orgUnitId
      maxDaysInAdvance
      maxBookableDays
      createdAt
      updatedAt
    }
  }
`;
export const updateBookingConfig = /* GraphQL */ `
  mutation UpdateBookingConfig(
    $input: UpdateBookingConfigInput!
    $condition: ModelBookingConfigConditionInput
  ) {
    updateBookingConfig(input: $input, condition: $condition) {
      orgUnitId
      maxDaysInAdvance
      maxBookableDays
      createdAt
      updatedAt
    }
  }
`;
export const deleteBookingConfig = /* GraphQL */ `
  mutation DeleteBookingConfig(
    $input: DeleteBookingConfigInput!
    $condition: ModelBookingConfigConditionInput
  ) {
    deleteBookingConfig(input: $input, condition: $condition) {
      orgUnitId
      maxDaysInAdvance
      maxBookableDays
      createdAt
      updatedAt
    }
  }
`;
export const createMailConfig = /* GraphQL */ `
  mutation CreateMailConfig(
    $input: CreateMailConfigInput!
    $condition: ModelMailConfigConditionInput
  ) {
    createMailConfig(input: $input, condition: $condition) {
      userId
      userMail
      isReceiveOwnBooking
      isReceiveForeignBooking
      createdAt
      updatedAt
    }
  }
`;
export const updateMailConfig = /* GraphQL */ `
  mutation UpdateMailConfig(
    $input: UpdateMailConfigInput!
    $condition: ModelMailConfigConditionInput
  ) {
    updateMailConfig(input: $input, condition: $condition) {
      userId
      userMail
      isReceiveOwnBooking
      isReceiveForeignBooking
      createdAt
      updatedAt
    }
  }
`;
export const deleteMailConfig = /* GraphQL */ `
  mutation DeleteMailConfig(
    $input: DeleteMailConfigInput!
    $condition: ModelMailConfigConditionInput
  ) {
    deleteMailConfig(input: $input, condition: $condition) {
      userId
      userMail
      isReceiveOwnBooking
      isReceiveForeignBooking
      createdAt
      updatedAt
    }
  }
`;
export const createAreBookingsTimeDataUpdated = /* GraphQL */ `
  mutation CreateAreBookingsTimeDataUpdated(
    $input: CreateAreBookingsTimeDataUpdatedInput!
    $condition: ModelAreBookingsTimeDataUpdatedConditionInput
  ) {
    createAreBookingsTimeDataUpdated(input: $input, condition: $condition) {
      id
      didUpdate
      createdAt
      updatedAt
    }
  }
`;
export const updateAreBookingsTimeDataUpdated = /* GraphQL */ `
  mutation UpdateAreBookingsTimeDataUpdated(
    $input: UpdateAreBookingsTimeDataUpdatedInput!
    $condition: ModelAreBookingsTimeDataUpdatedConditionInput
  ) {
    updateAreBookingsTimeDataUpdated(input: $input, condition: $condition) {
      id
      didUpdate
      createdAt
      updatedAt
    }
  }
`;
export const deleteAreBookingsTimeDataUpdated = /* GraphQL */ `
  mutation DeleteAreBookingsTimeDataUpdated(
    $input: DeleteAreBookingsTimeDataUpdatedInput!
    $condition: ModelAreBookingsTimeDataUpdatedConditionInput
  ) {
    deleteAreBookingsTimeDataUpdated(input: $input, condition: $condition) {
      id
      didUpdate
      createdAt
      updatedAt
    }
  }
`;
